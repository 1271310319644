import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import { LightningBoltIcon } from "@heroicons/react/outline";
import { TagIcon } from "@heroicons/react/solid";
import { InboxContactRead } from "@hilos/types/private-schema";
import { interpolate } from "src/helpers/template";
import { getContactVariables, hasItems } from "src/helpers/utils";
import { useCustomFields } from "src/hooks/useContactCustomField";
import useHilosStore from "src/hooks/useHilosStore";
import useQuickReplies, { QuickReplyData } from "src/hooks/useQuickReplies";
import { classNames } from "src/Helpers";

interface BottomBarMessageQuickRepliesProps {
  search: string;
  inboxContact: InboxContactRead;
  onClose: () => void;
  onSetMessage: (message: string) => void;
}

export interface BottomBarMessageQuickRepliesRef {
  selectNextQuickReply: () => void;
  setSelectedQuickReplyMessage: () => void;
}

const BottomBarMessageQuickReplies = forwardRef<
  BottomBarMessageQuickRepliesRef,
  React.PropsWithChildren<BottomBarMessageQuickRepliesProps>
>(({ search, inboxContact, onClose, onSetMessage }, ref) => {
  const [selectedQuickReply, setSelectedQuickReply] = useState(0);
  const { session } = useHilosStore();
  const { pages, handleChangeSearch } = useQuickReplies(
    inboxContact.channel,
    true
  );
  const { contactCustomFields } = useCustomFields();

  const results = useMemo(() => {
    if (!session || !pages || !pages[0] || !hasItems(pages[0].results)) {
      return [];
    }
    const variables = {
      "user.email": session.email,
      "user.first_name": session.first_name,
      "user.last_name": session.last_name,
      "user.full_name": `${session.first_name || ""} ${
        session.last_name || ""
      }`,
    };
    const contactAvailableVariables = getContactVariables(contactCustomFields);

    if (inboxContact.contact) {
      const contactMeta = inboxContact.contact.meta || {};

      for (const variableKey of contactAvailableVariables) {
        if (variableKey) {
          if (inboxContact.contact[variableKey]) {
            variables[`contact.${variableKey}`] =
              inboxContact.contact[variableKey];
          } else if (contactMeta[variableKey]) {
            variables[`contact.${variableKey}`] = contactMeta[variableKey];
          }
        }
      }
    }

    const nextResults: QuickReplyData[] = [];

    for (const result of pages[0].results) {
      if (nextResults.length >= 3) {
        break;
      }

      const nextText = interpolate(result.text || "", variables).trim();

      if (nextText) {
        nextResults.push({
          ...result,
          text: nextText,
        });
      }
    }

    return nextResults;
  }, [pages, inboxContact.contact, session, contactCustomFields]);

  useImperativeHandle(ref, () => ({
    selectNextQuickReply: () => {
      setSelectedQuickReply((index) =>
        results.length - 1 === index ? 0 : index + 1
      );
    },
    setSelectedQuickReplyMessage: () => {
      const currentSelectedQuickReply = results[selectedQuickReply];
      if (currentSelectedQuickReply) {
        onSetMessage(currentSelectedQuickReply.text || "");
        onClose();
      }
    },
  }));

  useEffect(() => {
    setSelectedQuickReply(0);
  }, [results.length]);

  useEffect(() => {
    handleChangeSearch(search);
  }, [search, handleChangeSearch]);

  return (
    <div className="flex gap-2 w-screen md:w-full overflow-scroll no-scrollbar">
      {results.map((result, index) => (
        <button
          type="button"
          key={result.id}
          className={classNames(
            "flex flex-col items-start w-40 grow shrink-0 justify-evenly p-2 bg-white shadow-sm rounded-md group mb-2",
            "border border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 focus:outline-none focus:ring-1 focus:ring-inset",
            selectedQuickReply === index &&
              "border-indigo-500 ring-indigo-500 outline-none ring-1 ring-inset"
          )}
          onClick={() => {
            onSetMessage(result.text || "");
            onClose();
          }}
        >
          <div className="flex justify-between items-center w-full space-x-1">
            <LightningBoltIcon className="h-4 w-4 flex-shrink-0 text-gray-600 group-focus:text-indigo-700" />
            <p className="w-full text-left whitespace-pre-wrap break-all truncate line-clamp-1 text-gray-600 text-sm font-semibold group-focus:text-gray-700">
              {result.name || result.text}
            </p>
          </div>
          <div className="flex items-center w-full">
            <TagIcon
              className="mr-1 h-4 w-4 flex-shrink-0 text-gray-400"
              aria-hidden="true"
            />
            <p className="leading-none text-xs text-gray-500 truncate break-all">
              {result.tags && result.tags.map(({ name }) => name).join(", ")}
            </p>
          </div>
        </button>
      ))}
    </div>
  );
});

export default BottomBarMessageQuickReplies;
