import { v7 as uuid } from "uuid";
import { FlowStepData } from "@hilos/types/flow";
import { HilosVariableData, HilosVariableTypeData } from "@hilos/types/hilos";
import { ContactCustomField } from "@hilos/types/private-schema";
import { TriggerTypes } from "src/containers/flow/builder/constants/triggers";
import {
  INBOUND_TRIGGER_BASE_VARIABLES,
  META_C2WA_TRIGGER_BASE_VARIABLES,
} from "src/containers/flow/builder/helpers/steps";
import { KEYS_OF_STEP_WITH_VARIABLES } from "src/containers/flow/builder/utils";
import { getValueJoinedBy, hasItems, isValidUUID } from "./utils";

interface VariableItem {
  path?: string | null;
  type: HilosVariableTypeData;
  transform?: string | null;
}

interface VariableTransform {
  key: string;
  type: HilosVariableTypeData;
}

export const textVariableRegex = /\{\{(.*?)\}\}/gi;

export function getVariableFromStep({
  step,
  path = null,
  extra,
  prevStepVariables,
}: {
  step: Partial<FlowStepData>;
  path?: string | null;
  extra: Partial<HilosVariableData> & { data_type: HilosVariableTypeData };
  prevStepVariables: (HilosVariableData | undefined)[];
}): HilosVariableData {
  let name = `step.${step.name}`;

  if (path) {
    name += `.${path}`;
  }

  const { variable } = getVariableWithCurrentId({
    prevStepVariables,
    variable: {
      transform: null,
      ...extra,
      id: uuid(),
      source: "step",
      step_id: step.id,
      name,
      path,
    },
  });

  return variable;
}

export function getVariablesFromItems({
  step,
  items,
  extra = {},
  prevStepVariables,
}: {
  step: Partial<FlowStepData>;
  items: VariableItem[];
  extra?: Partial<HilosVariableData>;
  prevStepVariables: (HilosVariableData | undefined)[];
}): HilosVariableData[] {
  const stepName = `step.${step.name || ""}`;

  return items.map(({ type, path, transform = null }) => {
    let name = stepName;

    if (path) {
      // TODO: Add functionality to handle paths with dots or square brackets in the name.
      name += `.${path}`;
    }
    if (transform) {
      name += `|${transform}`;
    }

    const { variable } = getVariableWithCurrentId({
      prevStepVariables,
      variable: {
        ...extra,
        id: uuid(),
        source: "step",
        step_id: step.id,
        name,
        transform,
        data_type: type,
        path: path || extra.path || null,
      },
    });

    return variable;
  });
}

export function getVariablesFromTransforms({
  path,
  name,
  extra,
  transforms,
  prevStepVariables,
  currentStepVariables,
}: {
  path: string | null;
  name: string;
  extra: Partial<HilosVariableData>;
  transforms: VariableTransform[];
  prevStepVariables: (HilosVariableData | undefined)[];
  currentStepVariables: HilosVariableData[];
}): HilosVariableData[] {
  const variables: HilosVariableData[] = [];

  for (const { key, type } of transforms) {
    const { variable, duplicated } = getVariableWithCurrentId({
      prevStepVariables,
      currentStepVariables,
      variable: {
        ...extra,
        id: uuid(),
        path,
        transform: key,
        name: `${name}|${key}`,
        source: "step",
        data_type: type,
      },
    });

    if (!duplicated) {
      variables.push(variable);
    }
  }

  return variables;
}

export function getVariableItemsFromQuestionStep(
  step: Partial<FlowStepData>
): VariableItem[] {
  switch (step.answer_type) {
    case "ANY":
    case "FREE_TEXT":
    case "URL":
    case "EMAIL":
    case "PHONE":
      return [{ type: "text" }];
    case "NUMBER":
      return [{ type: "number" }];
    case "LOCATION":
      return [
        { type: "text", path: "name" },
        { type: "text", path: "lat" },
        { type: "text", path: "lng" },
      ];
    case "SINGLE_OPTION":
      if (step.has_options_from_variable) {
        return [
          { path: "id", type: "text" },
          { path: "title", type: "text" },
        ];
      }
      return [{ type: "text" }];
    case "FILE":
    case "DOCUMENT":
    case "VIDEO":
    case "IMAGE":
      return [
        { type: "media", path: "url" },
        { type: "text", path: "content_type" },
      ];
    case "DATE":
      return [{ type: "date" }];
    case "TIME":
      return [{ type: "time" }];
    case "DATETIME":
      return [{ type: "datetime" }];
    case "BOOL":
      return [{ type: "bool" }];
    default:
      break;
  }

  return [];
}

export function getVariableTypeFromData(data: any) {
  switch (typeof data) {
    case "string":
      return "text";
    case "number":
      return "number";
    case "boolean":
      return "bool";
    case "object":
      if (Array.isArray(data)) {
        return "list";
      }

      if (data === null) {
        return "null";
      }

      return "object";
    case "undefined":
      return "null";
    default:
      return null;
  }
}

export function getNestedVariablesFromData({
  data,
  name,
  path = null,
  extra = {},
  types = ["text", "number", "bool", "list", "object", "null"],
  ignoreOptionsFromVariable = false,
  prevStepVariables,
  currentStepVariables = [],
}: {
  data: any;
  name?: string;
  path?: string | null;
  extra?: Partial<HilosVariableData>;
  types?: HilosVariableTypeData[];
  ignoreOptionsFromVariable?: boolean;
  prevStepVariables: (HilosVariableData | undefined)[];
  currentStepVariables?: HilosVariableData[];
}) {
  const variables: HilosVariableData[] = [];
  const typeOfData = typeof data;
  const variableType = getVariableTypeFromData(data);
  let currentVariableId = uuid();

  if (name && variableType && types.includes(variableType)) {
    const { variable, duplicated } = getVariableWithCurrentId({
      prevStepVariables,
      currentStepVariables,
      variable: {
        ...extra,
        id: currentVariableId,
        name,
        path,
        source: "step",
        transform: null,
        data_type: variableType,
      },
    });

    if (!duplicated) {
      variables.push(variable);
    }

    currentVariableId = variable.id;
  }

  if (!data || typeOfData !== "object") {
    return variables;
  }

  if (name && variableType === "list") {
    // Available variable transforms
    const transforms: VariableTransform[] = [
      { key: "count", type: "number" },
      { key: "is_empty", type: "bool" },
    ];
    variables.push(
      ...getVariablesFromTransforms({
        path,
        name,
        extra,
        transforms,
        prevStepVariables,
        currentStepVariables,
      })
    );

    const baseKeys = new Set<string>();
    let hasInitialKeys = false;

    for (const index in data) {
      if (data[index]) {
        if (typeof data[index] !== "object") {
          baseKeys.clear();
          break;
        }
        if (hasInitialKeys) {
          for (const key of baseKeys.keys()) {
            // if the key does not exist, it is no longer a consistent for options
            if (!(key in data[index])) {
              baseKeys.delete(key);
            }
          }
        } else {
          for (const key in data[index]) {
            baseKeys.add(key);
          }
        }
        hasInitialKeys = true;
      }
    }

    for (const key of baseKeys.values()) {
      if (!ignoreOptionsFromVariable) {
        variables.push(
          ...getNestedVariablesFromData({
            types,
            extra: {
              ...extra,
              from_variable_source_id: currentVariableId,
            },
            data: data[0][key],
            path: key,
            name: key,
            prevStepVariables,
            currentStepVariables,
            ignoreOptionsFromVariable: true,
          })
        );
      }

      variables.push(
        ...getNestedVariablesFromData({
          types,
          extra,
          data: data[0][key],
          path: getValueJoinedBy([path, 0, key]),
          name: getValueJoinedBy([name, 0, key]),
          prevStepVariables,
          currentStepVariables,
          ignoreOptionsFromVariable: true,
        })
      );
    }
  } else {
    for (const key in data) {
      variables.push(
        ...getNestedVariablesFromData({
          types,
          extra,
          data: data[key],
          path: getValueJoinedBy([path, key]),
          name: getValueJoinedBy([name, key]),
          prevStepVariables,
          currentStepVariables,
          ignoreOptionsFromVariable,
        })
      );
    }
  }

  return variables;
}

const isSameVariable = (
  variable: HilosVariableData,
  currentVariable: HilosVariableData | undefined
) =>
  currentVariable &&
  variable.step_id === currentVariable.step_id &&
  variable.data_type === currentVariable.data_type &&
  variable.transform === currentVariable.transform &&
  variable.path === currentVariable.path;

export const getVariableWithCurrentId = ({
  variable,
  prevStepVariables,
  currentStepVariables = [],
}: {
  variable: HilosVariableData;
  prevStepVariables: (HilosVariableData | undefined)[];
  currentStepVariables?: HilosVariableData[];
}): { variable: HilosVariableData; duplicated?: boolean } => {
  for (const currentVariable of currentStepVariables) {
    if (isSameVariable(variable, currentVariable)) {
      return {
        variable: {
          ...variable,
          id: currentVariable.id,
        },
        duplicated: true,
      };
    }
  }

  for (const currentVariable of prevStepVariables) {
    if (isSameVariable(variable, currentVariable)) {
      return {
        variable: {
          ...variable,
          id: (currentVariable as HilosVariableData).id,
        },
      };
    }
  }

  return { variable };
};

export function getPrevStepsById({
  id,
  prevStepsByStepId,
  ignoreStepIds = [],
}: {
  id: string;
  prevStepsByStepId: { [key: string]: Set<string> };
  ignoreStepIds?: string[];
}) {
  const prevStepIds: string[] = [];

  if (prevStepsByStepId[id]) {
    // Ignore steps already added
    const currentPrevStepIds = [...prevStepsByStepId[id].values()].filter(
      (stepId) => !ignoreStepIds.includes(stepId)
    );
    prevStepIds.push(...currentPrevStepIds);
    ignoreStepIds.push(...currentPrevStepIds);

    for (const prevStepId of currentPrevStepIds) {
      prevStepIds.push(
        ...getPrevStepsById({
          id: prevStepId,
          prevStepsByStepId,
          ignoreStepIds,
        })
      );
    }
  }

  return prevStepIds;
}

export function getPrevStepsMappedByStepId(steps: FlowStepData[]) {
  const prevStepsByStepId: { [key: string]: Set<string> } = {};

  for (const step of steps) {
    const nextStepIds: (string | null)[] = [
      step.next_step_default,
      step.next_step_alternate,
      step.answer_failed_next_step,
    ];

    if (step.next_steps_for_options) {
      nextStepIds.push(...step.next_steps_for_options);
    }

    for (const nextStepId of nextStepIds) {
      if (nextStepId) {
        if (!prevStepsByStepId[nextStepId]) {
          prevStepsByStepId[nextStepId] = new Set();
        }
        prevStepsByStepId[nextStepId].add(step.id);
      }
    }
  }

  return prevStepsByStepId;
}

export function getAllowedPrevSteps(
  steps: FlowStepData[],
  currentStepIndex: number | null
) {
  if (currentStepIndex !== null) {
    const currentStepData = steps[currentStepIndex];

    if (currentStepData) {
      return getPrevStepsById({
        id: currentStepData.id,
        prevStepsByStepId: getPrevStepsMappedByStepId(steps),
      });
    }
  }

  return [];
}

export function getVariablesFromActionResponses({
  name,
  extra,
  responses,
  prevStepVariables,
}: {
  responses: any;
  name: string;
  extra: Partial<HilosVariableData>;
  prevStepVariables: (HilosVariableData | undefined)[];
}) {
  if (!hasItems(responses)) {
    return [];
  }

  const variables: HilosVariableData[] = [];

  for (const response of responses) {
    if (response.data) {
      variables.push(
        ...getNestedVariablesFromData({
          data: response.data,
          name,
          extra,
          prevStepVariables: prevStepVariables,
          currentStepVariables: variables,
        })
      );
    }
  }

  return variables;
}

export function getVariableItemsFromWAFlowStep(
  step: Partial<FlowStepData>
): VariableItem[] {
  return (
    step.wa_flow_selected?.variables.map(
      (variable) =>
        ({
          type: "text",
          path: variable,
        } as VariableItem)
    ) || []
  );
}

export function getCurrentStepVariables(
  step: Partial<FlowStepData>,
  prevStepVariables: (HilosVariableData | undefined)[]
): HilosVariableData[] {
  if (step && step.name && step.step_type) {
    switch (step.step_type) {
      case "MENU":
      case "TEMPLATE":
        if (step.step_type === "TEMPLATE" && !step.save_contact_answer) {
          break;
        }
        return [
          getVariableFromStep({
            step,
            extra: { data_type: "text" },
            prevStepVariables,
          }),
        ];
      case "TEMPLATE_MENU":
        if (step.next_action !== "CONTINUE") {
          return [
            getVariableFromStep({
              step,
              extra: { data_type: "text" },
              prevStepVariables,
            }),
          ];
        }
        break;
      case "ACTION":
      case "HUBSPOT_CONTACT_GET":
        return getVariablesFromActionResponses({
          responses: step.action_responses,
          name: `step.${step.name}`,
          extra: { step_id: step.id },
          prevStepVariables,
        });
      case "WA_FLOW":
        return getVariablesFromItems({
          step,
          items: getVariableItemsFromWAFlowStep(step),
          prevStepVariables,
        });
      case "QUESTION":
        return getVariablesFromItems({
          step,
          items: getVariableItemsFromQuestionStep(step),
          prevStepVariables,
        });
      default:
        if (step.step_type.includes("WRAPPED")) {
          return getVariablesFromActionResponses({
            responses: step.action_responses,
            name: `step.${step.name}`,
            extra: { step_id: step.id },
            prevStepVariables,
          });
        }
        break;
    }
  }

  return [];
}

export function getContactVariables(
  customFields: ContactCustomField[] | null
): HilosVariableData[] {
  const contactBaseFields = [
    "id",
    "phone",
    "first_name",
    "default_conversation_url",
    "last_name",
    "email",
    "external_url",
    "source",
  ];
  let contactCustomFields: HilosVariableData[] = [];
  const baseFields = contactBaseFields.map((value) => ({
    id: `contact.${value}`,
    name: `contact.${value}`,
    data_type: "text",
    source: "contact",
    path: null,
    transform: null,
  }));

  if (customFields) {
    contactCustomFields = customFields.map((value) => ({
      id: `contact.${value.name}`,
      name: `contact.${value.name}`,
      data_type: "text",
      source: "contact",
      path: null,
      transform: null,
    }));
  }
  return [...baseFields, ...contactCustomFields] as HilosVariableData[];
}

export const getVariablesFromTriggerText = (trigger_text: string) =>
  getRequiredVariablesFromTextValue(trigger_text).reduce(
    (nextTriggerVariables, name) => {
      if (Boolean(name)) {
        nextTriggerVariables[`trigger.${name}`] = {
          data_type: "text",
        };
      }
      return nextTriggerVariables;
    },
    {}
  );

export function getTriggerVariables(
  trigger_type: TriggerTypes,
  trigger_config: any
): {
  [key: string]: Partial<HilosVariableData> &
    Pick<HilosVariableData, "data_type">;
} {
  switch (trigger_type) {
    case "INBOUND_ANY_MESSAGE":
      return { ...INBOUND_TRIGGER_BASE_VARIABLES };
    case "META_C2WA":
      return { ...META_C2WA_TRIGGER_BASE_VARIABLES };
    case "INBOUND_SPECIFIC_MESSAGE":
      if (trigger_config.has_variables) {
        return {
          ...INBOUND_TRIGGER_BASE_VARIABLES,
          ...getVariablesFromTriggerText(
            trigger_config.message_content_to_match
          ),
        };
      }
      return { ...INBOUND_TRIGGER_BASE_VARIABLES };
    default:
      return {};
  }
}

export function getValueWithVariable(
  value: string,
  variable: string,
  separator: string,
  position: number | null
) {
  let formattedVariable = `{{${variable}}}`;

  if (!value) {
    return formattedVariable;
  }

  if (position !== null) {
    if (separator) {
      if (value[position - 1] !== separator) {
        formattedVariable = separator + formattedVariable;
      }

      if (value[position] !== separator) {
        formattedVariable += separator;
      }
    }
    return [
      value.slice(0, position),
      formattedVariable,
      value.slice(position),
    ].join("");
  }

  if (separator && value[value.length - 1] !== separator) {
    formattedVariable = separator + formattedVariable;
  }

  return value + formattedVariable;
}

export const getRequiredVariablesFromTextValue = (value: string = "") => {
  const currentVariableTextMatch = (value || "").match(textVariableRegex) || [];
  const nextRequiredVariablesKeys = currentVariableTextMatch.map(
    (variableText) => variableText.replace("{{", "").replace("}}", "")
  );

  return nextRequiredVariablesKeys;
};

export const isValidVariableKey = (
  value: string,
  allowedPrefix: string[] = []
) =>
  value &&
  (isValidUUID(value) ||
    allowedPrefix.some((prefix) => value.startsWith(prefix)));

export const getRequiredVariablesFromValue = (
  value: any,
  allowedPrefix: string[],
  requireCurlyBrackets: boolean = true
) => {
  const nextRequiredVariables: string[] = [];

  if (value) {
    switch (typeof value) {
      case "string":
        if (!requireCurlyBrackets && isValidVariableKey(value, allowedPrefix)) {
          return [value];
        }

        return getRequiredVariablesFromTextValue(value).filter(
          (variable: string) => isValidVariableKey(variable, allowedPrefix)
        );
      case "object":
        for (const key in value) {
          nextRequiredVariables.push(
            ...getRequiredVariablesFromValue(
              value[key],
              allowedPrefix,
              requireCurlyBrackets
            )
          );
        }
        break;
      default:
        break;
    }
  }

  return nextRequiredVariables;
};

export const getValueWithoutVariable = <T extends any>(
  value: T,
  variable: string
): T => {
  if (!value) {
    return value;
  }

  switch (typeof value) {
    case "string":
      if (value === variable) {
        return "" as T;
      }

      return value.replaceAll(`{{${variable}}}`, "") as T;
    case "object":
      const nextItems: any = value;

      for (const key in value) {
        nextItems[key] = getValueWithoutVariable(value[key], variable);
      }

      return nextItems as T;
    default:
      break;
  }

  return value;
};

export const getStepWithoutVariable = (
  step: FlowStepData,
  variable: string
) => {
  let nextStep = { ...step };

  for (const key of KEYS_OF_STEP_WITH_VARIABLES) {
    const value = nextStep[key];

    if (key === "options_from_variable" && value === variable) {
      nextStep.option_from_variable_title = null;
      nextStep.option_from_variable_value = null;
      nextStep.option_from_variable_description = null;
    }

    nextStep[key] = getValueWithoutVariable(value, variable);
  }

  return nextStep;
};

export const getMappedItemsById = <T extends { id: string }>(
  items: T[] | null = null
): Map<string, T> =>
  (items || []).reduce((nextItemsById, item) => {
    nextItemsById.set(item.id, item);
    return nextItemsById;
  }, new Map());

export const getRequiredVariablesFromStep = (
  step: FlowStepData,
  allowedPrefix: string[] = []
) => {
  const currentRequiredVariables: string[] = [];

  for (const key of KEYS_OF_STEP_WITH_VARIABLES) {
    currentRequiredVariables.push(
      ...getRequiredVariablesFromValue(
        step[key],
        allowedPrefix,
        // The following fields do not use curly brackets for variables
        ![
          "paths",
          "conditions",
          "options_from_variable",
          "option_from_variable_value",
          "option_from_variable_title",
          "option_from_variable_description",
        ].includes(key)
      )
    );
  }

  return Array.from(new Set(currentRequiredVariables));
};

export function getFormattedTextWithVariables({
  key,
  name,
  value,
  handleGetVariable,
}: {
  key: string;
  name: string;
  value: any;
  handleGetVariable: (value: string, key?: string) => HilosVariableData | null;
}) {
  let nextValue = "";

  if (["string", "number"].includes(typeof value)) {
    nextValue = String(value);
  }

  const nextRequiredVariables = getRequiredVariablesFromTextValue(nextValue);

  for (const variableKey of nextRequiredVariables) {
    if (variableKey) {
      const variable = handleGetVariable(variableKey, key);
      if (variable && variable[name]) {
        nextValue = nextValue.replaceAll(
          `{{${variableKey}}}`,
          `{{${variable[name]}}}`
        );
      }
    }
  }

  return nextValue;
}

export function getUpdatedVariablesWithExtraSources({
  flowVariableKeys = [],
  triggerVariables = {},
  currentVariables,
}: {
  flowVariableKeys?: string[];
  triggerVariables?: {
    [key: string]: Partial<HilosVariableData> &
      Pick<HilosVariableData, "data_type">;
  };
  currentVariables: HilosVariableData[];
}) {
  const nextVariables: HilosVariableData[] = [];
  const uniqVariableIds = new Set();
  const missingFlowVariableKeys = new Set(flowVariableKeys);
  const missingTriggerVariableNames = new Set(Object.keys(triggerVariables));

  for (const variable of currentVariables) {
    if (!variable.name || uniqVariableIds.has(variable.id)) {
      continue;
    }

    switch (variable.source) {
      case "flow":
        if (missingFlowVariableKeys.size) {
          const flowVariableKey = variable.name.replace(
            /^(flow|flow_execution_variable)\./,
            ""
          );
          if (missingFlowVariableKeys.has(flowVariableKey)) {
            missingFlowVariableKeys.delete(flowVariableKey);
            uniqVariableIds.add(variable.id);
            nextVariables.push(variable);
          }
        }

        break;
      case "trigger":
        if (missingTriggerVariableNames.size) {
          if (missingTriggerVariableNames.has(variable.name)) {
            missingTriggerVariableNames.delete(variable.name);
            uniqVariableIds.add(variable.id);
            nextVariables.push(variable);
          }
        }
        break;
      case "step":
        uniqVariableIds.add(variable.id);
        nextVariables.push(variable);
        break;
      default:
        break;
    }
  }

  for (const flowVariableKey of missingFlowVariableKeys) {
    const id = uuid();

    uniqVariableIds.add(id);
    nextVariables.push({
      id,
      name: `flow.${flowVariableKey}`,
      source: "flow",
      data_type: "text",
      path: null,
      transform: null,
    });
  }

  for (const triggerVariableName of missingTriggerVariableNames) {
    const id = uuid();

    uniqVariableIds.add(id);
    nextVariables.push({
      ...triggerVariables[triggerVariableName],
      id,
      name: triggerVariableName,
      source: "trigger",
      path: null,
      transform: null,
    });
  }

  return nextVariables;
}
