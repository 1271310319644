import { useCallback, useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { PencilAltIcon, ShareIcon } from "@heroicons/react/outline";
import axios from "axios";
import { axiosErr } from "@hilos/types/axios";
import { Flow } from "@hilos/types/flows/Flow";
import { FlowTemplateDetailRead } from "@hilos/types/private-schema";
import OnboardingModuleModal from "src/containers/onboarding_module/OnboardingModuleModal";
import DeleteObjectModal from "src/components/DeleteObjectModal";
import HeaderNavigation from "src/components/HeaderNavigation";
import AccountStatusNotice from "src/components/Notice/AccountStatusNotice";
import PermissionsChecker from "src/components/PermissionsCheck";
import useFlowTemplates from "src/hooks/useFlowTemplates";
import useHilosStore from "src/hooks/useHilosStore";
import { classNames } from "src/Helpers";
import { API_ROUTES, buildAPIRoute, buildRoute } from "src/router/router";
import FlowCreate from "./FlowCreate";
import FlowListTable from "./FlowListTable";
import FlowTemplateCard from "./FlowTemplateCard";
import FlowTemplateFormModal from "./FlowTemplateFormModal";

export default function FlowList() {
  const { t } = useTranslation();
  const { session, updateSession } = useHilosStore();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showManageTemplateModal, setShowManageTemplateModal] = useState(false);
  const [selectedFlow, setSelectedFlow] = useState<Flow | undefined>(undefined);
  const [selectedFlowTemplate, setSelectedFlowTemplate] = useState<
    FlowTemplateDetailRead | undefined
  >(undefined);
  const [triggerReload, setTriggerReload] = useState(false);
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [deleteSubmitted, setDeleteSubmitted] = useState(false);
  const [searchParams] = useSearchParams();
  const [currentTab, setCurrentTab] = useState("flows");
  const [showHelpModal, setShowHelpModal] = useState<boolean>(false);

  const navigate = useNavigate();

  const {
    data: flowTemplatesList,
    searchFlowTemplates,
    refetch,
    fetchFlowTemplate,
  } = useFlowTemplates();

  const onDelete = async (
    formData,
    setSubmitting,
    setBackendValidationErrors,
    setBackendError
  ) => {
    setBackendValidationErrors({});
    setBackendError("");
    try {
      await axios.delete(
        buildAPIRoute(API_ROUTES.FLOW_DETAIL, {
          ":id": formData.obj.id,
        })
      );
      setDeleteSubmitted(false);
      setDeleteSuccess(true);
      setTriggerReload(!triggerReload);
      setShowDeleteModal(false);
    } catch (error) {
      const err = error as axiosErr;
      setDeleteSuccess(false);
      if (err?.response?.status === 400) {
        console.log("error", err);
        setBackendValidationErrors(err.response.data);
      } else {
        setBackendError("An error occurred, please try again.");
      }
      setTimeout(() => {
        setDeleteSubmitted(false);
      }, 1000 * 2);
    } finally {
      setSubmitting(false);
      setDeleteSubmitted(false);
    }
  };

  const duplicateFlow = useCallback(
    async (flowId) => {
      try {
        const { data } = await axios.post(
          buildAPIRoute(API_ROUTES.FLOW_DUPLICATE, {
            ":id": flowId,
          })
        );
        setTriggerReload((t) => !t);

        if (data) {
          navigate(buildRoute("flow-editor", { id: data.id }));
        }
      } catch (err) {
        console.log("error", err);
      }
    },
    [navigate]
  );

  const upgradeFlow = useCallback(
    async (flowId) => {
      try {
        const { status, data } = await axios.post(
          buildAPIRoute(API_ROUTES.FLOW_UPGRADE, {
            ":id": flowId,
          }),
          null,
          { validateStatus: (status) => [201, 400].includes(status) }
        );

        if (status === 201) {
          setTriggerReload((t) => !t);

          if (data) {
            navigate(
              buildRoute("flow-editor", {
                id: data.id,
              })
            );

            return true;
          }
        }
      } catch (error) {
        console.log("error", error);
      }
      return false;
    },
    [navigate]
  );

  const handleDeleteAction = (flow) => {
    setSelectedFlow(flow);
    setShowDeleteModal(true);
  };

  const handleManageTemplateAction = useCallback(
    (flow) => {
      setSelectedFlow(flow);
      searchFlowTemplates(flow.id)
        .then((data) => {
          fetchFlowTemplate(data.id).then((flowTemplate) => {
            setSelectedFlowTemplate(flowTemplate);
            setShowManageTemplateModal(true);
          });
        })
        .catch((err) => {
          console.log(err);
          setSelectedFlowTemplate(undefined);
          setShowManageTemplateModal(true);
        });
    },
    [fetchFlowTemplate, searchFlowTemplates]
  );

  const handleEditTemplate = useCallback(
    async (flowTemplateId) => {
      fetchFlowTemplate(flowTemplateId)
        .then((flowTemplate) => {
          setSelectedFlowTemplate(flowTemplate);
          setShowManageTemplateModal(true);
        })
        .catch((err) => {
          console.log(err);
          setSelectedFlowTemplate(undefined);
          setShowManageTemplateModal(true);
        });
    },
    [handleManageTemplateAction]
  );

  const handleDuplicateFlowTemplate = (flowId) => {
    duplicateFlow(flowId);
  };

  const discardUnpublishedChanges = useCallback(async (flow) => {
    try {
      await axios.patch(
        buildAPIRoute(API_ROUTES.FLOW_AUTOSAVE, {
          ":id": flow.id,
        }),
        { current_draft: null, upgrade_version: null }
      );
      setTriggerReload((t) => !t);
    } catch (err) {
      console.log("error", err);
    }
  }, []);

  const TABS = [
    {
      name: t("flows:tab-my-flows", "My Flows"),
      value: "flows",
      icon: ShareIcon,
      current: currentTab === "flows",
    },
    {
      name: t("flows:tab-templates", "Templates"),
      value: "templates",
      icon: PencilAltIcon,
      current: currentTab === "templates",
    },
  ];

  const handleCloseHelpModal = useCallback(() => {
    if (!session?.visited_modules.includes("flows")) {
      updateSession({
        visited_modules: [...(session?.visited_modules || []), "flows"],
      });
    }
    setShowHelpModal(false);
  }, []);

  useEffect(() => {
    setShowHelpModal(
      session ? !session.visited_modules.includes("flows") : false
    );
  }, []);

  useEffect(() => {
    const tab = searchParams.get("tab");
    if (tab && ["flows", "templates"].includes(tab)) {
      setCurrentTab(tab);
    }
  }, [searchParams]);

  return (
    <div className="relative flex h-full flex-col">
      <div className="h-screen overflow-y-auto bg-gray-50">
        <AccountStatusNotice />
        {/* Header */}
        <div className="py-4 px-4 sm:border-b sm:border-gray-200 sm:px-6 lg:px-8">
          <HeaderNavigation
            navPages={[
              {
                name: t("home"),
                url: buildRoute("dashboard"),
              },
              {
                name: t("flows"),
                url: buildRoute("flow-list"),
              },
            ]}
          />
          {currentTab === "flows" ? (
            <div className="lg:flex lg:items-center lg:justify-between">
              <div className="min-w-0 flex-1">
                <h2 className="mt-2 flex items-center text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl">
                  <ShareIcon
                    className="mr-4 h-7 w-7 rotate-90"
                    aria-hidden="true"
                  />
                  {t("flows", "Flows")}
                </h2>
                <p className="mt-1 text-sm text-gray-600">
                  {t(
                    "flows:description",
                    "Manage your flows and view their results."
                  )}
                  {/* <HelpDocsLink
                    // href="https://hilos.io/docs/user/create-a-flow"
                    className="ml-1 text-blue-400"
                  /> */}
                </p>
              </div>
              <PermissionsChecker
                permission="add_flow"
                action={t("settings:permissions.add_flow", "create new flows")}
              >
                <div className="mt-2 flex md:mt-5 lg:mt-0 lg:ml-4">
                  <FlowCreate />
                </div>
              </PermissionsChecker>
            </div>
          ) : (
            <div className="lg:flex lg:items-center lg:justify-between">
              <div className="min-w-0 flex-1">
                <h2 className="mt-2 flex items-center text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl">
                  <PencilAltIcon className="mr-4 h-7 w-7" aria-hidden="true" />
                  {t("flows-templates", "Flows Templates")}
                </h2>
                <Trans i18nKey="flows:templates-description">
                  <p className="mt-1 text-sm text-gray-600">
                    See some examples of what you can achieve with flows and
                    create your own from these starter templates.
                    {/* <HelpDocsLink
                      //href="https://hilos.io/docs/user/create-a-flow"
                      className="ml-1 text-blue-400"
                    /> */}
                  </p>
                </Trans>
              </div>
            </div>
          )}
        </div>
        <div className="border-b border-gray-200 px-4 sm:px-6 lg:px-8">
          <div className="">
            <nav className="-mb-px flex space-x-8" aria-label="Tabs">
              {TABS.map((tab) => (
                <button
                  key={tab.name}
                  type="button"
                  className={classNames(
                    tab.current
                      ? "border-hilos text-hilos"
                      : "border-transparent text-gray-500 hover:border-gray-200 hover:text-gray-700",
                    "flex whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium"
                  )}
                  aria-current={tab.current ? "page" : undefined}
                  onClick={(_) => setCurrentTab(tab.value)}
                >
                  <tab.icon
                    className={classNames(
                      tab.current
                        ? "text-hilos"
                        : "text-gray-400 group-hover:text-gray-500",
                      "-ml-0.5 mr-2 h-5 w-5"
                    )}
                    aria-hidden="true"
                  />
                  <span>{tab.name}</span>
                </button>
              ))}
            </nav>
          </div>
        </div>
        {currentTab === "flows" && (
          <FlowListTable
            triggerReload={triggerReload}
            discardUnpublishedChanges={discardUnpublishedChanges}
            duplicateFlow={duplicateFlow}
            upgradeFlow={upgradeFlow}
            handleDeleteAction={handleDeleteAction}
            handleManageTemplateAction={handleManageTemplateAction}
            setCurrentTab={setCurrentTab}
            session={session}
          />
        )}
        {currentTab === "templates" && (
          <div className="mx-4 my-6 flex flex-wrap justify-center">
            {flowTemplatesList?.map((flowTemplate) => (
              <FlowTemplateCard
                flowTemplate={flowTemplate}
                handleDuplicateFlowTemplate={handleDuplicateFlowTemplate}
                handleEditTemplate={handleEditTemplate}
              />
            ))}
          </div>
        )}
      </div>

      {selectedFlow && (
        <DeleteObjectModal
          obj={selectedFlow}
          objDescription={selectedFlow.name as string}
          actionDescription={t(
            "flows:delete-object",
            "When deleting this flow, messages already sent will still reach them."
          )}
          onDelete={onDelete}
          deleteSuccess={deleteSuccess}
          deleteSubmitted={deleteSubmitted}
          show={showDeleteModal}
          setShow={setShowDeleteModal}
        />
      )}
      {selectedFlow && (
        <FlowTemplateFormModal
          show={showManageTemplateModal}
          setShow={setShowManageTemplateModal}
          flow={selectedFlow}
          flowTemplate={selectedFlowTemplate as FlowTemplateDetailRead}
          refetch={refetch}
        />
      )}
      <OnboardingModuleModal
        routeName="flows"
        show={showHelpModal}
        handleClose={handleCloseHelpModal}
      />
    </div>
  );
}
