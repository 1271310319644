import { useCallback, useEffect, useRef } from "react";
import axios from "axios";
import { CursorPageData } from "@hilos/types/hilos";
import Loading from "src/components/Loading";
import useConversationContent from "src/hooks/useConversationContent";
import useHilosStore from "src/hooks/useHilosStore";
import useInboxContactSync from "src/hooks/useInboxContactSync";
import { API_ROUTES } from "src/router/router";
import {
  ConversationContent,
  InboxContactRead,
  WhatsAppMessage,
} from "src/types/private-schema";
import { HandleMountConversationContent } from "../inbox/Conversation/Conversation";
import ConversationContentList from "../inbox/Conversation/ConversationContentList";

interface WorkflowBoardTaskContentProps {
  inboxContact: InboxContactRead;
  focusedConversationContent: ConversationContent | null;
  setFocusedConversationContent: (content: ConversationContent | null) => void;
  onSelectContextContent: (content: ConversationContent) => void;
  onSelectForwardMessage: (message: WhatsAppMessage) => void;
}

function WorkflowBoardTaskContent({
  inboxContact,
  focusedConversationContent,
  setFocusedConversationContent,
  onSelectContextContent,
  onSelectForwardMessage,
}: WorkflowBoardTaskContentProps) {
  const lastOutboundMessageIdRef = useRef<string | null>(null);
  const conversationContentByIdRef = useRef(new Map());

  const showConversationEvents = useHilosStore(
    ({ session }) => session?.show_conversation_events ?? true
  );

  const {
    pages,
    isLoading,
    isFetchingNextPage,
    isFetchingPreviousPage,
    isUpdatingCursor,
    hasNextPage,
    hasPreviousPage,
    hasFirstPageLoaded,
    handleNextPage,
    handlePreviousPage,
    handleChangeInitialCursor,
  } = useConversationContent({
    inboxContactId: inboxContact.id,
    showConversationEvents,
  });

  useInboxContactSync({ id: inboxContact.id, showConversationEvents });

  const handleFocusMessage = useCallback(
    async (messageId: string) => {
      const { data } = await axios.get<CursorPageData<ConversationContent>>(
        API_ROUTES.CONVERSATION_CONTENT.replace(":id", inboxContact.id),
        {
          params: {
            search: messageId,
            ignoreEvents: !showConversationEvents,
          },
        }
      );
      if (data && data.results) {
        setFocusedConversationContent(data.results[0]);
      }
    },
    [inboxContact, showConversationEvents, setFocusedConversationContent]
  );

  const handleMountConversationContent =
    useCallback<HandleMountConversationContent>(
      ({ id, messageId, onFocus }) => {
        if (
          lastOutboundMessageIdRef.current &&
          lastOutboundMessageIdRef.current === messageId
        ) {
          onFocus();
          lastOutboundMessageIdRef.current = null;
        }
        conversationContentByIdRef.current.set(id, onFocus);
        return () => {
          conversationContentByIdRef.current.delete(id);
        };
      },
      []
    );

  const handleFocusStartOfContent = () => {};

  useEffect(() => {
    if (
      focusedConversationContent &&
      !conversationContentByIdRef.current.has(focusedConversationContent.id) &&
      focusedConversationContent.timestamp
    ) {
      handleChangeInitialCursor(focusedConversationContent.timestamp);
    }
  }, [focusedConversationContent, handleChangeInitialCursor]);

  if (isLoading || isUpdatingCursor) {
    return <Loading />;
  }

  return (
    <ConversationContentList
      inboxContact={inboxContact}
      pages={pages}
      className="p-3 sm:px-2"
      isUpdatingCursor={isUpdatingCursor}
      isFetchingNextPage={isFetchingNextPage}
      isFetchingPreviousPage={isFetchingPreviousPage}
      hasNextPage={hasNextPage}
      hasPreviousPage={hasPreviousPage}
      hasFirstPageLoaded={hasFirstPageLoaded}
      focusedConversationContent={focusedConversationContent}
      onNextPage={handleNextPage}
      onPreviousPage={handlePreviousPage}
      onFocusMessage={handleFocusMessage}
      onFocusStartOfContent={handleFocusStartOfContent}
      onSelectContextContent={onSelectContextContent}
      onSelectForwardMessage={onSelectForwardMessage}
      onMountConversationContent={handleMountConversationContent}
    />
  );
}

export default WorkflowBoardTaskContent;
