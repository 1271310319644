import { Fragment } from "react";
import { DropzoneInputProps } from "react-dropzone";
import { useTranslation } from "react-i18next";
import { Menu, Transition } from "@headlessui/react";
import {
  ClipboardListIcon,
  LightningBoltIcon,
  PaperClipIcon,
} from "@heroicons/react/outline";
import { PlusIcon } from "@heroicons/react/solid";

interface BottomBarMoreOptionsMenuProps {
  onSelectOption: (option: "file" | "quick-reply" | "template") => void;
  getInputProps: <T extends DropzoneInputProps>(props?: T) => T;
}
const BottomBarMoreOptionsMenu = ({
  onSelectOption,
  getInputProps,
}: BottomBarMoreOptionsMenuProps) => {
  const { t } = useTranslation();
  return (
    <Menu as="div" className="relative">
      <input hidden id="message-file-upload" {...getInputProps()} />
      <Menu.Button className="flex h-10 w-10 items-center justify-center rounded-full bg-gray-100 text-gray-500 hover:bg-gray-200">
        <PlusIcon className="h-6 w-6" />
      </Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute left-0 bottom-12 z-10 mt-2 w-56 origin-bottom-left rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            <Menu.Item>
              {({ active }) => (
                <button
                  onClick={() => onSelectOption("file")}
                  className={`${
                    active ? "bg-gray-100" : ""
                  } flex w-full items-center px-4 py-2 text-sm text-gray-700`}
                >
                  <PaperClipIcon className="mr-3 h-5 w-5" />
                  {t("file", "File")}
                </button>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <button
                  onClick={() => onSelectOption("quick-reply")}
                  className={`${
                    active ? "bg-gray-100" : ""
                  } flex w-full items-center px-4 py-2 text-sm text-gray-700`}
                >
                  <LightningBoltIcon className="mr-3 h-5 w-5" />
                  {t("quick-reply", "Quick reply")}
                </button>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <button
                  onClick={() => onSelectOption("template")}
                  className={`${
                    active ? "bg-gray-100" : ""
                  } flex w-full items-center px-4 py-2 text-sm text-gray-700`}
                >
                  <ClipboardListIcon className="mr-3 h-5 w-5" />
                  {t("template", "Template")}
                </button>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default BottomBarMoreOptionsMenu;
