import { useCallback } from "react";
import { useQuery } from "react-query";
import axios from "axios";
import { MessageBlastRead } from "@hilos/types/private-schema";
import { API_ROUTES, buildAPIRoute } from "../router/router";

function useBroadcast(id: string) {
  const fetchBroadcast = useCallback(async () => {
    const { data } = await axios.get<MessageBlastRead>(
      buildAPIRoute(API_ROUTES.MESSAGE_BLAST_DETAIL, {
        ":id": id,
      }),
      {
        headers: {
          "x-module": "broadcast",
        },
      }
    );
    return data;
  }, [id]);

  const { data } = useQuery(["broadcast_detail", id], fetchBroadcast);

  return data;
}

export default useBroadcast;
