import AccountConfigMenu from "src/containers/account/AccountConfigMenu";
import AccountConversationListSettings from "src/containers/account/AccountConversationListSettings";
import AccountDisableConfirmation from "src/containers/account/AccountDisableConfirmation";
import AccountInvoiceList from "src/containers/account/AccountInvoiceList";
import AccountNotificationSettings from "src/containers/account/AccountNotificationSettings";
import AccountSubscription from "src/containers/account/AccountSubscription";
import AccountSubscriptionChangePlan from "src/containers/account/AccountSubscriptionChangePlan";
import AccountTemplateSettings from "src/containers/account/AccountTemplateSettings";
import AccountUpdateBasic from "src/containers/account/AccountUpdateBasic";
import AccountUpdateHours from "src/containers/account/AccountUpdateHours";
import AccountUpdateInbox from "src/containers/account/AccountUpdateInbox";
import AccountUserList from "src/containers/account/AccountUserList";
import TeamCreate from "src/containers/account/Team/TeamCreate";
import TeamList from "src/containers/account/Team/TeamList";
import TeamUpdate from "src/containers/account/Team/TeamUpdate";
import Wallet from "src/containers/account/Wallet";
import UsageReport from "src/containers/account/billing/UsageReport";
import RoleCreate from "src/containers/account/role/RoleCreate";
import RoleList from "src/containers/account/role/RoleList";
import RoleUpdate from "src/containers/account/role/RoleUpdate";
import AccountMemberUpdate from "src/containers/account/user/UserV1Update";
import UserV2Update from "src/containers/account/user/UserV2Update";
import BroadcastCreate from "src/containers/broadcasts/BroadcastCreate";
import BroadcastDetail from "src/containers/broadcasts/BroadcastDetail";
import BroadcastList from "src/containers/broadcasts/BroadcastList";
import Channel360DialogConnect from "src/containers/config/channel/Channel360DialogConnect";
import Channel360DialogDetail from "src/containers/config/channel/Channel360DialogDetail";
import ChannelCloudAPICreate from "src/containers/config/channel/ChannelCloudAPICreate";
import ChannelCloudAPIDetail from "src/containers/config/channel/ChannelCloudAPIDetail";
import ChannelCloudAPIUpdate from "src/containers/config/channel/ChannelCloudAPIUpdate";
import ChannelHilosCloudAPIDetail from "src/containers/config/channel/ChannelHilosCloudAPIDetail";
import ChannelHilosCloudAPIUpdate from "src/containers/config/channel/ChannelHilosCloudAPIUpdate";
import ChannelList from "src/containers/config/channel/ChannelList";
import TechProviderOnboarding from "src/containers/config/channel/TechProviderOnboarding";
import ContactCreate from "src/containers/contact/ContactCreate";
import ContactDetail from "src/containers/contact/ContactDetail";
import ContactImportDetail from "src/containers/contact/ContactImportDetail";
import ContactMain from "src/containers/contact/ContactMain";
import ContactUpdate from "src/containers/contact/ContactUpdate";
import ContactUploadCSV from "src/containers/contact/ContactUploadCSV";
import FlowDetail from "src/containers/flow/FlowDetail";
import FlowList from "src/containers/flow/FlowList";
import FlowEditor from "src/containers/flow/builder/FlowEditor";
import FlowExecutionCreate from "src/containers/flow/flow_execution/FlowExecutionCreate";
import FlowExecutionDetail from "src/containers/flow/flow_execution/FlowExecutionDetail";
import FlowExecutionContactDetail from "src/containers/flow/flow_execution_contact/FlowExecutionContactDetail";
import FlowTemplateDetail from "src/containers/flow/flow_template/FlowTemplateDetail";
import FlowVersionDetail from "src/containers/flow/flow_version/FlowVersionDetail";
import DashboardAI from "src/containers/home/DashboardAI";
import InboxActionCreate from "src/containers/inbox_action/InboxActionCreate";
import InboxActionList from "src/containers/inbox_action/InboxActionList.tsx";
import AISettings from "src/containers/integrations/AI/AISettings";
import APIKeyList from "src/containers/integrations/APIKeyList";
import ExternalAppsList from "src/containers/integrations/ExternalAppsList";
import HubspotOauth from "src/containers/integrations/Hubspot/Hubspot";
import Monitoring from "src/containers/integrations/Monitoring";
import Vessel from "src/containers/integrations/Vessel/Vessel";
import WebhookSubscriptionCreate from "src/containers/integrations/Webhooks/WebhookSubscriptionCreate";
import WebhookList from "src/containers/integrations/Webhooks/WebhookSubscriptionList";
import WebhookSubscriptionUpdate from "src/containers/integrations/Webhooks/WebhookSubscriptionUpdate";
import WAWidget from "src/containers/integrations/Widget/WidgetContainer";
import WidgetCreate from "src/containers/integrations/Widget/WidgetCreate";
import WidgetUpdate from "src/containers/integrations/Widget/WidgetUpdate";
import AccountSelection from "src/containers/onboarding/AccountSelection";
import ConnectSandbox from "src/containers/onboarding/ConnectSandbox";
import Welcome from "src/containers/onboarding/Welcome";
import ReportsBase from "src/containers/reports/ReportsBase";
import ContactTagCreate from "src/containers/tags/ContactTagCreate";
import ContactTagList from "src/containers/tags/ContactTagList";
import ContactTagUpdate from "src/containers/tags/ContactTagUpdate";
import ConversationTagCreate from "src/containers/tags/ConversationTagCreate";
import ConversationTagList from "src/containers/tags/ConversationTagList";
import ConversationTagUpdate from "src/containers/tags/ConversationTagUpdate";
import Login from "src/containers/user/Login";
import Profile from "src/containers/user/Profile";
import ResetPassword from "src/containers/user/ResetPassword";
import ResetPasswordFromKey from "src/containers/user/ResetPasswordFromKey";
import Signup from "src/containers/user/Signup";
import SignupTeamInvitation from "src/containers/user/SignupTeamInvitation";
import WhatsAppTemplateCreate from "src/containers/wa/WhatsAppTemplateCreate";
import WhatsAppTemplateList from "src/containers/wa/WhatsAppTemplateList";
import WorkflowBoard from "src/containers/workflow/WorkflowBoard";
import WorkflowCreate from "src/containers/workflow/WorkflowCreate";
import WorkflowList from "src/containers/workflow/WorkflowList";
import WorkflowUpdate from "src/containers/workflow/WorkflowUpdate";

// ! If this variable is updated, service worker must be updated with same values.
export const PUBLIC_ROUTE_PATHS = [
  "/login",
  "/logout",
  "/reset-password",
  "/sign-up",
  "/invitation",
];

export const PUBLIC_ROUTES = [
  {
    path: "/login",
    name: "login",
    component: <Login />,
  },
  {
    path: "/logout",
    name: "logout",
    component: <Login />,
  },
  {
    path: "/reset-password",
    name: "reset-password",
    component: <ResetPassword />,
  },
  {
    path: "/reset-password/:key",
    name: "reset-password-from-key",
    component: <ResetPasswordFromKey />,
  },
  {
    path: "/sign-up",
    name: "signup",
    component: <Signup />,
  },
  {
    path: "/invitation/:key",
    name: "invitation",
    component: <SignupTeamInvitation />,
  },
];

export const PRIVATE_ROUTES = [
  {
    path: "/",
    name: "dashboard",
    component: <DashboardAI />,
  },
  {
    path: "/ai",
    name: "dashboard-ai",
    component: <DashboardAI />,
  },
  {
    path: "/welcome",
    name: "welcome",
    hideSidenav: true,
    component: <Welcome />,
  },
  {
    path: "/account-selection",
    name: "account-selection",
    hideSidenav: true,
    component: <AccountSelection />,
  },
  {
    path: "/connect-sandbox",
    name: "connect-sandbox",
    hideSidenav: true,
    component: <ConnectSandbox />,
  },

  // Broadcasts

  {
    path: "/broadcasts",
    name: "broadcast-list",
    component: <BroadcastList />,
  },

  {
    path: "/broadcasts/create",
    name: "broadcast-create",
    component: <BroadcastCreate />,
  },
  {
    path: "/broadcasts/:id",
    name: "broadcast-detail",
    component: <BroadcastDetail />,
  },

  // Contacts
  {
    path: "/contact",
    name: "contact-list",
    component: <ContactMain />,
  },
  {
    path: "/contact/create",
    name: "contact-create",
    component: <ContactCreate />,
  },
  {
    path: "/contact/csv",
    name: "contact-upload-csv",
    component: <ContactUploadCSV />,
  },
  {
    path: "/contact/:id/update",
    name: "contact-update",
    component: <ContactUpdate />,
  },
  {
    path: "/contact/:id",
    name: "contact-detail",
    component: <ContactDetail />,
  },
  {
    path: "/contact-import/:id",
    name: "contact-import-detail",
    component: <ContactImportDetail />,
  },

  //Flows
  {
    path: "/flow",
    name: "flow-list",
    component: <FlowList />,
  },
  {
    path: "/flow-editor/:id",
    name: "flow-editor",
    component: <FlowEditor />,
  },
  {
    path: "/flow/:id",
    name: "flow-detail",
    component: <FlowDetail />,
  },

  //Flow versions
  {
    path: "/flow-version/:id",
    name: "flow-version-detail",
    component: <FlowVersionDetail />,
  },

  // Flow execution
  {
    path: "/flow/:id/run",
    name: "flow-execution-create",
    component: <FlowExecutionCreate />,
  },
  {
    path: "/flow-execution/:id",
    name: "flow-execution-detail",
    component: <FlowExecutionDetail />,
  },

  // Flow execution contact
  {
    path: "/flow-execution-contact/:id",
    name: "flow-execution-contact-detail",
    component: <FlowExecutionContactDetail />,
  },

  // Flow Template
  {
    path: "/flow-template/:id",
    name: "flow-template",
    component: <FlowTemplateDetail />,
  },

  //Config
  {
    path: "/config/account",
    name: "config-account",
    component: <AccountConfigMenu />,
  },
  {
    path: "/config/account/basic",
    name: "config-account-basic",
    component: <AccountUpdateBasic />,
  },
  {
    path: "/config/account/hours",
    name: "config-account-hours",
    component: <AccountUpdateHours />,
  },
  {
    path: "/config/account/inbox",
    name: "config-account-inbox",
    component: <AccountUpdateInbox />,
  },
  {
    path: "/config/invoices",
    name: "config-payment-history",
    component: <AccountInvoiceList />,
  },
  {
    path: "/config/subscription",
    name: "config-account-subscription",
    component: <AccountSubscription />,
  },
  {
    path: "/config/wallet",
    name: "config-account-wallet",
    component: <Wallet />,
  },
  {
    path: "/config/subscription/change-plan",
    name: "config-account-subscription-change-plan",
    component: <AccountSubscriptionChangePlan />,
  },
  {
    path: "/config/usage",
    name: "config-usage-report",
    component: <UsageReport />,
  },
  {
    path: "/config/notifications",
    name: "config-notifications",
    component: <AccountNotificationSettings />,
  },
  {
    path: "/config/templates",
    name: "config-templates",
    component: <AccountTemplateSettings />,
  },
  {
    path: "/config/close-account",
    name: "disable-account",
    component: <AccountDisableConfirmation />,
  },
  // Account members & teams
  {
    path: "/config/account-members",
    name: "account-members",
    component: <AccountUserList />,
  },
  {
    path: "/config/account-members/:id",
    name: "account-member-update",
    component: <AccountMemberUpdate />,
  },
  {
    path: "/config/user/:id",
    name: "user-update",
    component: <UserV2Update />,
  },
  {
    path: "/config/role",
    name: "role-list",
    component: <RoleList />,
  },
  {
    path: "/config/role/create",
    name: "role-create",
    component: <RoleCreate />,
  },
  {
    path: "/config/role/:id",
    name: "role-update",
    component: <RoleUpdate />,
  },
  {
    path: "/config/team-list",
    name: "config-team-list",
    component: <TeamList />,
  },
  {
    path: "/config/team/create",
    name: "config-team-create",
    component: <TeamCreate />,
  },
  {
    path: "/config/team/:id/update",
    name: "config-team-update",
    component: <TeamUpdate />,
  },

  // Channel
  {
    path: "/config/channel",
    name: "config-channel",
    component: <ChannelList />,
  },
  {
    path: "/config/channel/cloud-api/create",
    name: "config-channel-cloud-api-create",
    component: <ChannelCloudAPICreate />,
  },
  {
    path: "/config/channel/cloud-api/onboarding",
    name: "config-channel-cloud-api-onboarding",
    component: <TechProviderOnboarding />,
  },
  {
    path: "/config/channel/360-dialog/create",
    name: "config-channel-360dialog-create",
    component: <Channel360DialogConnect />,
  },
  {
    path: "/config/channel/360-dialog/:id",
    name: "config-channel-360dialog-details",
    component: <Channel360DialogDetail />,
  },
  {
    path: "/config/channel/cloud-api/:id",
    name: "config-channel-cloud-api-details",
    component: <ChannelCloudAPIDetail />,
  },
  {
    path: "/config/channel/cloud-api/:id/update",
    name: "config-channel-cloud-api-update",
    component: <ChannelCloudAPIUpdate />,
  },
  {
    path: "/config/channel/hilos-cloud-api/:id",
    name: "config-channel-hilos-cloud-api-details",
    component: <ChannelHilosCloudAPIDetail />,
  },
  {
    path: "/config/channel/hilos-cloud-api/:id/update",
    name: "config-channel-hilos-cloud-api-update",
    component: <ChannelHilosCloudAPIUpdate />,
  },

  // Inbox action
  {
    path: "/config/inbox-action",
    name: "config-inbox-action-list",
    component: <InboxActionList />,
  },
  {
    path: "/config/inbox-action/create",
    name: "config-inbox-action-create",
    component: <InboxActionCreate />,
  },
  {
    path: "/config/inbox-action/:id/update",
    name: "config-inbox-action-update",
    component: <InboxActionList />,
  },
  {
    path: "/config/inbox/contact-tag",
    name: "config-inbox-contact-tag-list",
    component: <ContactTagList />,
  },
  {
    path: "/config/inbox/contact-tag/create",
    name: "config-inbox-contact-tag-create",
    component: <ContactTagCreate />,
  },
  {
    path: "/config/inbox/contact-tag/:id/update",
    name: "config-inbox-contact-tag-update",
    component: <ContactTagUpdate />,
  },
  {
    path: "/config/inbox/conversation-tag",
    name: "config-inbox-conversation-tag-list",
    component: <ConversationTagList />,
  },
  {
    path: "/config/inbox/conversation-tag/create",
    name: "config-inbox-conversation-tag-create",
    component: <ConversationTagCreate />,
  },
  {
    path: "/config/inbox/conversation-tag/:id/update",
    name: "config-inbox-conversation-tag-update",
    component: <ConversationTagUpdate />,
  },
  // Conversation List
  {
    path: "/config/inbox-conversation-list",
    name: "config-inbox-conversation-list",
    component: <AccountConversationListSettings />,
  },

  // WhatsApp Templates
  {
    path: "/template",
    name: "whatsapp-template-list",
    component: <WhatsAppTemplateList />,
  },
  {
    path: "/template/:id",
    name: "whatsapp-template",
    component: <WhatsAppTemplateCreate />,
  },

  // Account
  {
    path: "/account/profile",
    name: "account-profile",
    component: <Profile />,
  },

  // Developers
  {
    path: "/dev/api-keys",
    name: "api-keys",
    component: <APIKeyList />,
  },
  {
    exact: true,
    path: "/dev/external-apps",
    name: "external-apps",
    component: <ExternalAppsList />,
  },
  {
    exact: true,
    path: "/integrations/hubspot",
    name: "integrations-hubspot",
    component: <HubspotOauth />,
  },
  {
    path: "/integrations/wa-widget",
    exact: true,
    name: "wa-widget",
    component: <WAWidget />,
  },
  {
    path: "/integrations/wa-widget/create",
    exact: true,
    name: "wa-widget-new",
    component: <WidgetCreate />,
  },
  {
    path: "/integrations/wa-widget/:id/update",
    name: "wa-widget-edit",
    component: <WidgetUpdate />,
  },
  {
    exact: true,
    path: "/integrations/chatgpt",
    name: "integrations-chatgpt",
    component: <AISettings />,
  },
  {
    path: "/integrations/:integrationId",
    name: "integrations-vessel",
    component: <Vessel />,
  },
  {
    path: "/integrations/:integrationId/:externalAppId",
    name: "integrations-vessel-connected",
    component: <Vessel />,
  },
  {
    exact: true,
    path: "/dev/monitoring",
    name: "dev-monitoring",
    component: <Monitoring />,
  },
  {
    path: "/dev/webhooks",
    name: "webhook-list",
    component: <WebhookList />,
  },
  {
    path: "/dev/webhook/create",
    name: "webhook-create",
    component: <WebhookSubscriptionCreate />,
  },
  {
    path: "/dev/webhook/:id/update",
    name: "webhook-update",
    component: <WebhookSubscriptionUpdate />,
  },

  // Reports
  {
    path: "/reports/:type",
    name: "reports",
    component: <ReportsBase />,
  },

  // Workflows
  {
    path: "/workflow",
    name: "workflow-list",
    component: <WorkflowList />,
  },
  {
    path: "/workflow/create",
    name: "workflow-create",
    component: <WorkflowCreate />,
  },
  {
    path: "/workflow/:id/update",
    name: "workflow-update",
    component: <WorkflowUpdate />,
  },
];

export const WORKFLOW_PRIVATE_ROUTES = [
  {
    path: "/workflow/:id/board",
    name: "workflow-board",
  },
  {
    path: "/workflow/:workflowId/board/:stateId/task/:taskId",
    name: "workflow-task",
  },
  {
    path: "/workflow/:workflowId/board/:stateId/task/:taskId/details",
    name: "workflow-task-details",
  },
];

export function getRoute(name) {
  for (const routeType of [
    PRIVATE_ROUTES,
    PUBLIC_ROUTES,
    WORKFLOW_PRIVATE_ROUTES,
  ]) {
    const found = routeType.find((el) => el.name === name);
    if (found) {
      return found;
    }
  }
  return null;
}

export function buildRoute(name, params) {
  try {
    let url = getRoute(name).path;
    for (let key in params) {
      if (params.hasOwnProperty(key)) {
        url = url.replace(`:${key}`, params[key]);
      }
    }
    return url;
  } catch (e) {
    console.warn(
      `Route: ${name} was not found, make sure it is available at 'src/router/router.js' `
    );
    throw e;
  }
}

export const BASE_PUBLIC_API_URL =
  process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_PROD_PUBLIC_API_LOCATION
    : process.env.REACT_APP_DEV_PUBLIC_API_LOCATION;

export const BASE_API_URL =
  process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_PROD_API_LOCATION
    : process.env.REACT_APP_DEV_API_LOCATION;

export const BASE_GRAPHQL_HTTP =
  process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_PROD_GRAPHQL_HTTP
    : process.env.REACT_APP_DEV_GRAPHQL_HTTP;

export const BASE_GRAPHQL_WS =
  process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_PROD_GRAPHQL_WS
    : process.env.REACT_APP_DEV_GRAPHQL_WS;

export const WIDGET_BASE_URL = process.env.REACT_APP_WIDGET_BASE_URL;

export const API_ROUTES = {
  // Account
  ME: "/me",
  TOKEN: "/token",
  SIGNUP: "/signup",
  LOGOUT: "/logout",
  CHANGE_PASSWORD: "password-change",
  RESET_PASSWORD: "password-reset",
  RESET_PASSWORD_KEY: "password-reset/:key/",

  ROLE_LIST: "/role",
  ROLE_DETAIL: "/role/:id",

  USER_LIST: "/user",
  USER_DETAIL: "/user/:id",
  USER_NOTIFICATIONS_LIST: "/user-notification",
  USER_NOTIFICATIONS_CONFIG: "/user-notification/config",
  USER_NOTIFICATIONS_SEEN: "/user-notification/seen",
  USER_NOTIFICATIONS_UNSEEN_COUNT: "/user-notification/unseen-count",
  USER_NOTIFICATIONS_UPDATE: "/user-notification/:id",
  USER_INVITATION_LIST_CREATE_LEGACY: "/user-invitation-legacy",
  USER_INVITATION_LIST_CREATE: "/user-invitation",
  USER_INVITATION_DETAIL: "/user-invitation/:id",
  PUBLIC_USER_INVITATION_DETAIL: "/p/user-invitation/:id",
  PUBLIC_USER_INVITATION_BY_USER: "/p/user-invitation-by-user",
  PUBLIC_USER_REQUEST_INVITATION_CODE: "/p/user-request-code/:id",
  PUBLIC_USER_ACCEPT_INVITATION: "/p/user-accept-invitation",

  // Team
  TEAM_LIST_CREATE: "/team",
  TEAM_DETAIL: "/team/:id",

  // 360Dialog
  DIALOG360_CHANNELS: "/channels/whatsapp/config/360dialog/get-channels",
  DIALOG360_CHANNEL_SET: "/channels/whatsapp/config/360dialog/set-channel",
  DIALOG360_WEBHOOK_CHECK: "/channels/whatsapp/config/360dialog-webhook-check",
  DIALOG360_WEBHOOK_SET: "/channels/whatsapp/config/360dialog-webhook-set",

  // Cloud API Tech Provider
  CLOUD_API_EXCHANGE_CODE:
    "/channels/whatsapp/tech-provider-cloud-api/exchange",

  PUBLIC_FILE_UPLOAD: "/file-upload",
  PUBLIC_FILE_DETAIL: "/file/p/:id/detail",

  // Billing
  PLAN_LIST_DETAIL: "/plan",
  INVOICE_LIST: "/invoice",

  // App
  ACCOUNT_CREATE_DETAIL: "/account",
  ACCOUNT_DISABLE: "/account/disable",
  ACCOUNT_DELETE: "/account/delete",

  MESSAGE_BLAST_CREATE_LIST: "/message-blast-file",
  MESSAGE_BLAST_PUBLIC_CREATE_LIST: "/broadcast",
  MESSAGE_BLAST_DETAIL: "/message-blast/:id",
  MESSAGE_BLAST_RECIPIENT_LIST: "/message-blast-recipient",
  MESSAGE_BLAST_STATUS: "/message-blast/:id/status",
  MESSAGE_BLAST_RECIPIENT_STATUS: "/message-blast-recipient/:id/status",
  MESSAGE_BLAST_DOWNLOAD: "/message-blast/:id/download",

  CONTACT_CREATE_LIST: "/contact",
  CONTACT_DETAIL: "/contact/:id",
  CONTACT_CUSTOM_FIELD_CREATE_LIST: "/contact-field",
  CONTACT_CUSTOM_FIELD_DETAIL: "/contact-field/:id",
  CONTACT_UPLOAD_CSV: "/contact/csv",
  CONTACT_FILTERS: "/contact/filters",
  CONTACT_NOTE_CREATE: "/contact/:id/note",
  CONTACT_NOTE_DETAIL: "/contact/:contact_id/note/:id",
  CONTACT_TAG_CREATE_LIST: "/contact-tag",
  CONTACT_TAG_LIST_DETAIL: "/contact-tag-detail",
  CONTACT_TAG_DETAIL: "/contact-tag/:id",
  CONVERSATION_TAG_CREATE_LIST: "/inbox/conversation-tag",
  CONVERSATION_TAG_LIST_DETAIL: "/inbox/conversation-tag-detail",
  CONVERSATION_TAG_DETAIL: "/inbox/conversation-tag/:id",

  CONTACT_IMPORT_LIST: "/contact-import",
  CONTACT_IMPORT_DETAIL: "/contact-import/:id",
  CONTACT_IMPORT_RESULT_LIST: "/contact-import-result",

  FLOW_CREATE_LIST: "/flow",
  FLOW_SAMPLE_CREATE: "/flow-sample",
  FLOW_DETAIL: "/flow/:id",
  FLOW_DETAIL_SIMPLE: "/flow/:id/simple",
  FLOW_DUPLICATE: "/flow/:id/duplicate",
  FLOW_UPGRADE: "/flow/:id/upgrade",
  FLOW_AUTOSAVE: "/flow/:id/autosave",
  FLOW_STEP_PREVIEW: "/flow/step/preview",
  FLOW_V2_SAMPLE_CREATE: "/flow-v2-sample",

  FLOW_VERSION_LIST: "/flow-version",
  FLOW_VERSION_DETAIL: "/flow-version/:id",
  FLOW_VERSION_SET_CURRENT: "/flow-version/:id/set-current",
  FLOW_VERSION_CREATE_DRAFT_FROM_VERSION: "/flow-version/:id/create",
  FLOW_VERSION_RESULT_DOWNLOAD: "/flow-version/:id/download",
  FLOW_VERSION_ANALYTICS: "/flow-version/:id/analytics",
  FLOW_STEP_ANALYTICS: "/flow-step/:id/analytics",

  FLOW_CREATE_TEMPLATE_LIST: "/flow-template",
  FLOW_TEMPLATE_DETAIL: "/flow-template/:id",

  FLOW_STEP_ACTION_TEST: "/flow/step/action-test",
  FLOW_V2_STEP_ACTION_TEST: "/flow-v2/step/action-test",
  FLOW_V2_STEP_WRAPPED_ACTION_TEST: "/flow-v2/step/wrapped-action-test",

  FLOW_WRAPPED_STEPS: "/flow/wrapped-steps",

  FLOW_TEST_CREATE: "/flow-test",
  FLOW_TEST_DETAIL: "/flow-test/:id",

  FLOW_EXECUTION_CREATE: "/flow/:id/flow-execution",
  FLOW_EXECUTION_RUN: "/flow/:id/run",
  FLOW_EXECUTION_LIST: "/flow-execution",
  FLOW_EXECUTION_DETAIL: "/flow-execution/:id",
  FLOW_EXECUTION_STATUS: "flow-execution/:id/status",

  FLOW_EXECUTION_CONTACT_LIST: "/flow-execution-contact",
  FLOW_EXECUTION_CONTACT_DETAIL: "/flow-execution-contact/:id",
  FLOW_EXECUTION_CONTACT_STATUS: "/flow-execution-contact/:id/status",
  FLOW_EXECUTION_CONTACT_CANCEL_CURRENT: "/flow-execution-contact/cancel/:id",

  FLOW_EXECUTION_STEP_DETAIL: "/flow-execution-step/:id",

  TEXT_MESSAGE_LIST: "/text-message",

  // Chatbot
  CHATBOT_SAMPLE_CREATE: "/chatbot-sample",
  CHATBOT_DETAIL: "/chatbot/:id",

  // Stripe
  STRIPE_CHECKOUT_SESSION_CREATE: "/stripe/checkout-session/create",
  STRIPE_CHECKOUT_SESSION_CREATE_V2: "/stripe/checkout-session-v2/create",
  STRIPE_CUSTOMER_PORTAL: "/stripe/customer-portal",
  STRIPE_PRODUCT_LIST: "/billing/product",
  STRIPE_PRICE_LIST: "/billing/price",
  STRIPE_INVOICE_LIST: "/billing/invoice",
  STRIPE_INVOICE_UPCOMING: "/stripe/upcoming-invoice",
  STRIPE_PAYMENT_METHOD_LIST: "/billing/payment-method",
  STRIPE_SUBSCRIPTION_DETAIL: "/billing/subscription",
  STRIPE_SUBSCRIPTION_PLAN_UPDATE: "/billing/subscription/update",
  STRIPE_SUBSCRIPTION_CANCEL: "/billing/subscription/cancel",
  STRIPE_SUBSCRIPTION_RESUME: "/billing/subscription/resume",

  // Wallet
  WALLET_PAYMENT_LIST: "/billing/wallet-payment",
  WALLET_CHECKOUT_SESSION: "/billing/wallet-checkout",

  // Developers
  API_KEY_LIST_CREATE: "/api-key",
  API_KEY_DETAIL: "/api-key/:id",

  // Integrations
  EXTERNAL_APP_LIST: "/external-app",
  EXTERNAL_APP_VESSEL_DETAIL: "/vessel/:id",
  EXTERNAL_APPS_INFO_LIST: "/external-apps/info",
  HUBSPOT_APP: "/hubspot",
  EXTERNAL_APP_OAUTH: "/external_app/oauth",
  VESSEL_SESSION_TOKEN: "/external_app/vessel/auth/session-token",
  VESSEL_ACCESS_TOKEN: "/external_app/vessel/auth/access-token",
  VESSEL_CONFIG: "/vessel/config/:id",
  VESSEL_CONTACT_SYNC: "/vessel/:id/contact/sync",
  HUBSPOT_CONTACT_SYNC: "/hubspot/contact/sync",
  HUBSPOT_CONFIG: "/hubspot/config",
  HUBSPOT_CONTACT_PROPERTIES: "/hubspot/contact/custom-properties",
  HUBSPOT_CONTACT_GET_STEP_TEST: "/hubspot/contact/get-step-test",
  WEBHOOK_SUBSCRIPTION_LIST_CREATE: "/webhook_subscription",
  WEBHOOK_SUBSCRIPTION_DETAIL: "/webhook_subscription/:id",
  WEBHOOK_SUBSCRIPTION_LOG_LIST: "/webhook_subscription_log",
  WEBHOOK_SUBSCRIPTION_LOG_DETAIL: "/webhook_subscription_log/:id",
  INTEGRATION_EVENT_LOG: "/integration_event_log",
  AI_SETTINGS: "/ai/settings",
  USER_TASK_LIST: "/user-task",
  USER_TASK_DETAIL: "/user-task/:id",

  // Workflow
  WORKFLOW_LIST_CREATE: "/workflow",
  WORKFLOW_DETAIL: "/workflow/:id",
  WORKFLOW_STATE_LIST: "/workflow/:id/state",
  WORKFLOW_STATE_DETAIL: "/workflow-state/:id",
  WORKFLOW_TASK_LIST_CREATE: "/workflow/:workflow_id/task",
  WORKFLOW_TASK_DETAIL: "/workflow/:workflow_id/task/:id",

  // Inbox
  INBOX_CONTACT_LIST_ACTION: "/inbox/action",
  INBOX_CONTACT_LIST_CREATE: "/inbox/contact",
  INBOX_CONTACT_DETAIL: "/inbox/contact/:id",

  INBOX_CONTACT_VIEW_LIST_CREATE: "/inbox/contact-view",
  INBOX_CONTACT_VIEW_DETAIL: "/inbox/contact-view/:id",

  CONVERSATION_LIST: "/inbox/conversation",
  CONVERSATION_UPDATE: "/inbox/conversation/:id/update",
  CONVERSATION_STATUS: "/inbox/conversation/:id/status",
  CONVERSATION_TAG_LIST: "/inbox/conversation-tag",

  CONVERSATION_CONTENT: "/inbox/contact/:id/content",
  CONVERSATION_MESSAGE_CREATE: "/inbox/contact/:id/message",
  CONVERSATION_MESSAGE_FORWARD: "/inbox/message/:id/forward",
  CONVERSATION_MESSAGE_RESEND: "/inbox/message/:id/resend",
  CONVERSATION_MESSAGE_DELETE: "/inbox/message/:id/delete",
  INBOX_WHATSAPP_TEMPLATE_SEND: "/inbox/message-template/:id/send",

  QUICK_REPLY_LIST_CREATE: "/inbox/quick-reply",
  QUICK_REPLY_DETAIL: "/inbox/quick-reply/:id",
  QUICK_REPLY_TAG_LIST: "/inbox/quick-reply-tag",

  SCHEDULED_MESSAGE_LIST_CREATE: "/inbox/scheduled-message",
  SCHEDULED_MESSAGE_DETAIL: "/inbox/scheduled-message/:id",
  SCHEDULED_MESSAGE_SEND: "/inbox/scheduled-message/:id/send",

  // Inbox Actions:
  INBOX_ACTION_LIST_CREATE: "/inbox-action",
  INBOX_ACTION_DETAIL: "/inbox-action/:id",
  INBOX_ACTION_RUN: "/inbox-action/:id/run/:contact",

  // WhatsApp Flow
  WHATSAPP_FLOW_LIST: "/channels/whatsapp/:id/wa-flow",
  WHATSAPP_FLOW_DETAIL: "/channels/whatsapp/wa-flow/:id",
  WHATSAPP_FLOW_TEST: "/channels/whatsapp/wa-flow/:id/test",
  // WhatsApp Template
  WHATSAPP_TEMPLATE_LIST: "/channels/whatsapp/template",
  WHATSAPP_TEMPLATE_SYNC: "/channels/whatsapp/template/sync",
  WHATSAPP_TEMPLATE_SUBMIT: "/channels/whatsapp/template/:id/submit",
  WHATSAPP_TEMPLATE_CREATE: "/channels/whatsapp/template/create",
  WHATSAPP_TEMPLATE_DUPLICATE: "/channels/whatsapp/template/:id/duplicate",
  WHATSAPP_TEMPLATE_RELATED_OBJECTS:
    "/channels/whatsapp/template/:id/related-objects",
  WHATSAPP_TEMPLATE: "/channels/whatsapp/template/:id",
  WHATSAPP_TEMPLATE_AUTOSAVE: "/channels/whatsapp/template/:id/autosave",

  WHATSAPP_TEMPLATE_SEND: "/channels/whatsapp/template/:id/send",

  // Channels
  CHANNEL_LIST_CREATE: "channels",
  CHANNEL_AVAILABILITY: "channels/availability",
  CHANNEL_DETAIL: "channels/:id",

  // Tutorials
  TUTORIAL_DETAILS: "/tutorial/:tutorial_type",
  TUTORIAL_LIST: "/tutorial",

  WS_USER: "/ws/user",
  WS_ACCOUNT: "/ws/account",
  WS_WORKFLOW: "/ws/workflow/:id",
  WS_INBOX_CONTACT: "/ws/inbox-contact/:id",

  // Analytics
  ANALYTICS_USAGE_SUMMARY: "/usage/summary",
  ANALYTICS_USAGE_DETAIL: "/usage/detail",
  ANALYTICS_INBOX_USAGE_SUMMARY: "/inbox-usage/summary",
  ANALYTICS_INBOX_USAGE_SUMMARY_CARDS: "/inbox-usage/cards",
  ANALYTICS_INBOX_USAGE_SUMMARY_CONVOS_PER_SOURCE:
    "/inbox-usage/conversations-per-source",
  ANALYTICS_INBOX_USAGE_AGENT_SUMMARY: "/inbox-usage/agent/summary",
  ANALYTICS_INBOX_USAGE_AGENT_SUMMARY_MESSAGES_PER_AGENT:
    "/inbox-usage/agent/messages-per-agent",
  ANALYTICS_INBOX_USAGE_AGENT_SUMMARY_CONVOS_PER_AGENT:
    "/inbox-usage/agent/conversations-per-agent",
  ANALYTICS_INBOX_USAGE_TAG_SUMMARY: "/inbox-usage/tag/summary",
  ANALYTICS_INBOX_USAGE_TAG_SUMMARY_CONVOS_PER_TAG:
    "/inbox-usage/tag/conversations-per-tag",

  ANALYTICS_INBOX_USAGE_DETAIL: "/inbox-usage/detail",

  ANALYTICS_WIDGET_SUMMARY_CARDS: "/widget-report",

  // Announcements
  ANNOUNCEMENT_LIST: "/announcements",

  // WhatsApp Button
  WHATSAPP_BUTTON_CREATE: "/whatsapp-button",
  WHATSAPP_BUTTON_DETAIL: "/whatsapp-button/:id",
};

export const PUBLIC_API_ROUTES = {
  // WhatsApp Button
  WHATSAPP_BUTTON_CREATE: "/button/",
  WHATSAPP_BUTTON_DETAIL: "/button/:id/",
};

export function buildAPIRoute(url, params) {
  for (let p in params) {
    if (params.hasOwnProperty(p)) {
      url = url.replace(p, params[p]);
    }
  }
  return url;
}
