import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import * as Tooltip from "@radix-ui/react-tooltip";
import { Formik, FormikProps } from "formik";
import * as Yup from "yup";
import ChannelSelectField from "src/components/Form/ChannelSelectField";
import ColorInputField from "src/components/Form/ColorInputField";
import TextInputField from "src/components/Form/TextInputField";
import StateButton from "src/components/StateButton";
import APIErrors from "src/components/base/APIErrors";
import { copyTextToClipboard } from "src/Helpers";
import { WIDGET_BASE_URL } from "src/router/router";
import WidgetPreview from "./WidgetPreview";

interface FormValues {
  phone?: string;
  id?: string;
  name: string;
  channel: number;
  default_text: string;
  button_1_text: string;
  button_2_text: string;
  button_3_text: string;
  bg_color: string;
  icon_color: string;
  padding: number;
  position: "BOTTOM_RIGHT" | "BOTTOM_LEFT";
}

interface WidgetFormProps {
  initialValues: FormValues;
  formSubmit: (
    formData: FormValues,
    setSubmitting: (isSubmitting: boolean) => void,
    setBackendValidationErrors: (errors: any) => void,
    setBackendError: (error: string) => void
  ) => void;
  success: boolean;
  submitted: boolean;
  action: "create" | "update";
}

const WidgetForm = ({
  initialValues,
  formSubmit,
  success,
  submitted,
  action,
}: WidgetFormProps) => {
  const { t } = useTranslation();
  const [backendError, setBackendError] = useState("");
  const [backendValidationErrors, setBackendValidationErrors] = useState({});
  const [copied, setCopied] = useState(false);
  const formikInstanceRef = useRef<FormikProps<FormValues> | null>(null);

  const validationSchema = Yup.object({
    name: Yup.string().required(t("widget:name-required", "Name is required")),
    channel: Yup.number()
      .required(t("widget:channel-required", "Channel is required"))
      .min(1, t("widget:channel-required", "Channel is required")),
    default_text: Yup.string().required(
      t("widget:default-text-required", "Default text is required")
    ),
    bg_color: Yup.string().matches(
      /^#[0-9A-F]{6}$/i,
      t("widget:invalid-color", "Invalid color")
    ),
    icon_color: Yup.string().matches(
      /^#[0-9A-F]{6}$/i,
      t("widget:invalid-color", "Invalid color")
    ),
    padding: Yup.number().min(0).max(100),
  });

  const handleCopyCode = (widgetId?: string) => {
    if (!widgetId) return;

    const codeToCopy = `<script src="${WIDGET_BASE_URL}/widget.cjs.production.min.js" data-widget-id="${widgetId}"></script>`;
    copyTextToClipboard(codeToCopy);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  return (
    <div className="mx-auto mt-6 max-w-4xl px-8 pb-10">
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) =>
          formSubmit(
            values,
            setSubmitting,
            setBackendValidationErrors,
            setBackendError
          )
        }
      >
        {(formik) => {
          return (
            <form noValidate onSubmit={formik.handleSubmit}>
              <div className="my-4">
                <APIErrors
                  APIError={backendError}
                  APIValidationErrors={backendValidationErrors}
                  fieldTranslations={{
                    phone: "Phone",
                    default_text: "Default Text",
                    button_1_text: "Quick Response 1",
                    button_2_text: "Quick Response 2",
                    button_3_text: "Quick Response 3",
                    bg_color: "Background Color",
                    icon_color: "Icon Color",
                    padding: "Padding",
                    position: "Position",
                  }}
                />
              </div>

              <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
                <div className="col-span-1 space-y-6">
                  <TextInputField
                    label={t("widget:name", "Widget name")}
                    name="name"
                    type="text"
                    required
                  />

                  <ChannelSelectField
                    name="channel"
                    label={t("widget:channel", "Channel")}
                    help={t(
                      "widget:channel-help",
                      "Select the WhatsApp number for this widget"
                    )}
                    onChange={(channel) =>
                      formik.setFieldValue("phone", channel.channel_id)
                    }
                  />

                  <Tooltip.Provider>
                    <Tooltip.Root>
                      <Tooltip.Trigger asChild>
                        <div>
                          <TextInputField
                            label={t("widget:default_text", "Default text")}
                            name="default_text"
                            type="text"
                          />
                        </div>
                      </Tooltip.Trigger>
                      <Tooltip.Portal>
                        <Tooltip.Content
                          className="TooltipContent w-80 text-sm bg-slate-100 text-slate-600 px-3 py-2 rounded-md -mb-4"
                          sideOffset={5}
                        >
                          {t(
                            "widget:trigger-tooltip",
                            "If you wish to trigger a flow, be sure to check that the text exactly matches the trigger."
                          )}
                        </Tooltip.Content>
                      </Tooltip.Portal>
                    </Tooltip.Root>
                  </Tooltip.Provider>

                  <Tooltip.Provider>
                    <Tooltip.Root>
                      <Tooltip.Trigger asChild>
                        <div>
                          <TextInputField
                            label={t("widget:button-1-text", "Quick reply 1")}
                            name="button_1_text"
                            type="text"
                          />
                        </div>
                      </Tooltip.Trigger>
                      <Tooltip.Portal>
                        <Tooltip.Content
                          className="TooltipContent w-80 text-sm bg-slate-100 text-slate-600 px-3 py-2 rounded-md -mb-4"
                          sideOffset={5}
                        >
                          {t(
                            "widget:trigger-tooltip",
                            "If you wish to trigger a flow, be sure to check that the text exactly matches the trigger."
                          )}
                        </Tooltip.Content>
                      </Tooltip.Portal>
                    </Tooltip.Root>
                  </Tooltip.Provider>
                  <Tooltip.Provider>
                    <Tooltip.Root>
                      <Tooltip.Trigger asChild>
                        <div>
                          <TextInputField
                            label={t("widget:button-2-text", "Quick reply 2")}
                            name="button_2_text"
                            type="text"
                          />
                        </div>
                      </Tooltip.Trigger>
                      <Tooltip.Portal>
                        <Tooltip.Content
                          className="TooltipContent w-80 text-sm bg-slate-100 text-slate-600 px-3 py-2 rounded-md -mb-4"
                          sideOffset={5}
                        >
                          {t(
                            "widget:trigger-tooltip",
                            "If you wish to trigger a flow, be sure to check that the text exactly matches the trigger."
                          )}
                        </Tooltip.Content>
                      </Tooltip.Portal>
                    </Tooltip.Root>
                  </Tooltip.Provider>
                  <Tooltip.Provider>
                    <Tooltip.Root>
                      <Tooltip.Trigger asChild>
                        <div>
                          <TextInputField
                            label={t("widget:button-3-text", "Quick reply 3")}
                            name="button_3_text"
                            type="text"
                          />
                        </div>
                      </Tooltip.Trigger>
                      <Tooltip.Portal>
                        <Tooltip.Content
                          className="TooltipContent w-80 text-sm bg-slate-100 text-slate-600 px-3 py-2 rounded-md -mb-4"
                          sideOffset={5}
                        >
                          {t(
                            "widget:trigger-tooltip",
                            "If you wish to trigger a flow, be sure to check that the text exactly matches the trigger."
                          )}
                        </Tooltip.Content>
                      </Tooltip.Portal>
                    </Tooltip.Root>
                  </Tooltip.Provider>
                  <div className="grid grid-cols-3 gap-6">
                    <ColorInputField
                      label={t("widget:bg-color", "Background color")}
                      name="bg_color"
                    />
                    <ColorInputField
                      label={t("widget:icon-color", "Icon color")}
                      name="icon_color"
                    />
                    <TextInputField
                      label={t("widget:padding", "Padding around (px)")}
                      name="padding"
                      type="number"
                    />
                  </div>
                  <div className="mt-4">
                    <label className="block text-sm font-medium text-gray-700">
                      {t("widget:position", "Widget position")}
                    </label>
                    <div className="mt-2 flex items-center space-x-4">
                      <img
                        src="https://cdn.prod.website-files.com/62e06c89457f36d21c5b726f/66db83c8dd607b08df3bf449_left.png"
                        alt="Left Position"
                        onClick={() =>
                          formik.setFieldValue("position", "BOTTOM_LEFT")
                        }
                        className={`cursor-pointer w-2/5 ${
                          formik.values.position === "BOTTOM_LEFT"
                            ? "border-2 rounded-md border-blue-500"
                            : ""
                        }`}
                      />
                      <img
                        src="https://cdn.prod.website-files.com/62e06c89457f36d21c5b726f/66db83c864d18200cd929242_right.png"
                        alt="Right Position"
                        onClick={() =>
                          formik.setFieldValue("position", "BOTTOM_RIGHT")
                        }
                        className={`cursor-pointer w-2/5 ${
                          formik.values.position === "BOTTOM_RIGHT"
                            ? "border-2 rounded-md border-blue-500"
                            : ""
                        }`}
                      />
                    </div>
                  </div>
                  <div className="mt-4 flex justify-between">
                    <Tooltip.Provider>
                      <Tooltip.Root>
                        <Tooltip.Trigger asChild>
                          <button
                            type="button"
                            disabled={formik.isSubmitting || !formik.values.id}
                            onClick={() => handleCopyCode(formik.values.id)}
                            className="p-2 text-sm bg-gray-300 rounded disabled:cursor-not-allowed disabled:bg-gray-100 transition-colors transition-duration-200 transition-ease-in-out"
                          >
                            {copied
                              ? t("widget:copied", "Code copied")
                              : t("widget:copy", "Copy code")}
                          </button>
                        </Tooltip.Trigger>
                        {(formik.isSubmitting ||
                          formik.values.id === undefined) && (
                          <Tooltip.Portal>
                            <Tooltip.Content
                              className="bg-slate-100 rounded-md p-2 shadow-md"
                              sideOffset={5}
                            >
                              {t(
                                "widget:save-first",
                                "Please save changes first"
                              )}
                              <Tooltip.Arrow className="fill-slate-100" />
                            </Tooltip.Content>
                          </Tooltip.Portal>
                        )}
                      </Tooltip.Root>
                    </Tooltip.Provider>
                    <StateButton
                      isSubmitting={formik.isSubmitting}
                      submitted={submitted}
                      success={success}
                      disabled={
                        formik.errors &&
                        Object.getOwnPropertyNames(formik.errors).length > 0
                      }
                      submittingText={t("widget:submitting", "Saving...")}
                      successText={t("widget:success", "Changes saved!")}
                      initialText={
                        <span>
                          {action === "create"
                            ? t("widget:create", "Create Widget")
                            : t("widget:save", "Save changes")}
                        </span>
                      }
                    />
                  </div>
                </div>
                <div className="relative col-span-1 bg-white border border-gray-400 aspect-square w-full rounded-md">
                  <WidgetPreview formValues={formik.values} />
                </div>
              </div>
            </form>
          );
        }}
      </Formik>
    </div>
  );
};

export default WidgetForm;
