import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import {
  ArrowSmRightIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/outline";
import WhatsAppIcon from "src/components/icons/WhatsAppIcon";
import useHilosStore from "src/hooks/useHilosStore";
import { formatPrice } from "src/Helpers";
import { buildRoute } from "src/router/router";

export default function WalletCard() {
  const { session } = useHilosStore();
  const { t } = useTranslation();

  return (
    <div className="flex flex-col divide-y divide-gray-200 rounded-lg bg-white text-center shadow">
      <div>
        <h3 className="text-tiny font-medium uppercase tracking-wider text-gray-500 flex items-center px-4 pt-5 sm:px-6">
          <WhatsAppIcon className="mr-1" />
          {t("dashboard:wallet-title", "Your WhatsApp credit balance")}
        </h3>
        <div className="px-6 py-3 flex justify-between items-center">
          <h3 className="text-3xl font-medium text-gray-900 flex-1">
            {formatPrice(session?.account.wallet_amount)}{" "}
            <span className="text-gray-500 text-sm">USD</span>
          </h3>
        </div>
        {!session?.account.autorecharge_wallet && (
          <div className="bg-yellow-50 p-4">
            <div className="flex">
              <div className="shrink-0">
                <ExclamationCircleIcon
                  aria-hidden="true"
                  className="size-5 text-yellow-400"
                />
              </div>
              <div className="ml-3">
                <h3 className="text-sm font-medium text-yellow-800">
                  {t(
                    "dashboard:wallet-autorecharge-disabled",
                    "Autorecharge disabled!"
                  )}
                </h3>
              </div>
            </div>
          </div>
        )}
      </div>

      <div>
        <div className="-mt-px flex">
          <div className="-ml-px flex w-0 flex-1">
            <Link
              to={buildRoute("config-account-wallet")}
              className="relative inline-flex w-0 flex-1 items-center justify-center rounded-br-lg border border-transparent py-4 text-sm font-medium text-gray-900 hover:text-gray-500"
            >
              <span className="mr-1">
                {t("dashboard:wallet-button", "Go to wallet settings")}
              </span>
              <ArrowSmRightIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
