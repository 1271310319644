import { useCallback, useEffect, useMemo, useRef } from "react";
import { useMediaPredicate } from "react-media-hook";
import { InboxContactReadV2 } from "@hilos/types/conversation";
import Loading from "src/components/Loading";
import useHilosStore from "src/hooks/useHilosStore";
import useOnScreen from "src/hooks/useOnScreen";
import ConversationItem from "./ConversationItem";

interface ConversationListDataProps {
  data: InboxContactReadV2[];
  fetching: boolean;
  hasMoreData: boolean;
  isSelectedAll: boolean;
  selectedContacts: string[];
  currentInboxContactId?: string;
  orderByField: string;
  onFetchMore: (cursor: string) => void;
  onSelectContact: (id: string) => void;
  onInboxContactCursor: (id: string | null) => void;
}

function ConversationListData({
  data,
  fetching,
  hasMoreData,
  isSelectedAll,
  selectedContacts,
  currentInboxContactId,
  orderByField,
  onFetchMore,
  onSelectContact,
  onInboxContactCursor,
}: ConversationListDataProps) {
  const isLargeScreen = useMediaPredicate("(min-width: 1024px)");
  const fetchingNextPageRef = useRef(false);
  const [endOfList, endOfListRef] = useOnScreen();
  const containerRef = useRef<HTMLUListElement>(null);

  const { session } = useHilosStore();

  const [inboxSettings, hasUsedMultipleChannels] = useMemo(() => {
    if (!session) {
      return [{}, false];
    }
    return [
      session.account.inbox_settings,
      session.account.has_used_multiple_channels,
    ];
  }, [session]);

  const handleNextPage = useCallback(() => {
    const lastItem = data[data.length - 1];
    if (lastItem) {
      onFetchMore(lastItem[orderByField] as string);
    }
  }, [data, orderByField, onFetchMore]);

  useEffect(() => {
    const handleScrollContainer = () => {
      if (containerRef.current) {
        const container = containerRef.current;
        const containerRect = container.getBoundingClientRect();
        const inboxContacts = container.querySelectorAll("[data-cursor]");

        for (const inboxContact of inboxContacts) {
          const inboxContactRect = inboxContact.getBoundingClientRect();

          if (
            inboxContactRect.top + 300 >= containerRect.top &&
            inboxContactRect.bottom <= containerRect.bottom
          ) {
            const isFirstNode = inboxContact === inboxContacts[0];

            if (isFirstNode) {
              onInboxContactCursor(null);
            } else {
              const currentInboxContactCursor =
                inboxContact.getAttribute("data-cursor");
              onInboxContactCursor(currentInboxContactCursor || null);
            }
            break;
          }
        }
      }
    };

    if (containerRef.current) {
      containerRef.current.addEventListener("scroll", handleScrollContainer);
      handleScrollContainer();
    }

    return () => {
      if (containerRef.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        containerRef.current.removeEventListener(
          "scroll",
          handleScrollContainer
        );
      }
    };
  }, []);

  useEffect(() => {
    if (endOfList) {
      if (!fetchingNextPageRef.current && hasMoreData) {
        fetchingNextPageRef.current = true;
        handleNextPage();
      }
    }
  }, [endOfList, hasMoreData, handleNextPage]);

  useEffect(() => {
    fetchingNextPageRef.current = fetching;
  }, [fetching]);

  return (
    <ul
      ref={containerRef}
      id="conversations"
      data-tour="conversation-list"
      className="h-full w-full bg-white divide-y divide-gray-200 overflow-y-auto overflow-x-hidden"
    >
      {data.map((inboxContact) => (
        <ConversationItem
          key={inboxContact.id}
          inboxContact={inboxContact}
          isLargeScreen={isLargeScreen}
          currentInboxContactId={currentInboxContactId}
          isSelectedAll={isSelectedAll}
          isSelected={selectedContacts.includes(inboxContact.id)}
          inboxSettings={inboxSettings}
          hasUsedMultipleChannels={hasUsedMultipleChannels}
          showSelection={Boolean(isSelectedAll || selectedContacts.length)}
          onSelectContact={onSelectContact}
        />
      ))}
      {fetching && (
        <div className="w-full h-20">
          <Loading />
        </div>
      )}
      <div ref={endOfListRef} />
    </ul>
  );
}

export default ConversationListData;
