import InfiniteScroll from "react-infinite-scroll-component";
import { FetchNextPageOptions } from "react-query";
import { ChatAltIcon } from "@heroicons/react/outline";
import { ConversationContentSearchItemData } from "@hilos/hooks/useConversationContentSearch";
import { CursorPageData } from "@hilos/types/hilos";
import { ConversationContent } from "@hilos/types/private-schema";
import Loading from "src/components/Loading";
import { hasItems } from "src/helpers/utils";
import ConversationContentSearchItem from "./ConversationContentSearchItem";

interface ConversationContentSearchResultsProps {
  pages?: CursorPageData<ConversationContentSearchItemData>[];
  isUpdating: boolean;
  hasNextPage?: boolean;
  focusedConversationContent: ConversationContent | null;
  setFocusedConversationContent: (data: ConversationContent) => void;
  handleNextPage: (params?: FetchNextPageOptions) => void;
}

function ConversationContentSearchResults({
  pages,
  isUpdating,
  hasNextPage,
  focusedConversationContent,
  setFocusedConversationContent,
  handleNextPage,
}: ConversationContentSearchResultsProps) {
  if (isUpdating) {
    return <Loading />;
  }

  if (!pages) {
    return null;
  }

  if (!hasItems(pages) || !hasItems(pages[0].results)) {
    return (
      <div className="flex flex-1 flex-col justify-center py-5 text-center">
        <ChatAltIcon className="mx-auto h-12 w-12 text-gray-400" />
        <h3 className="mt-2 text-sm font-medium text-gray-900">
          No messages found.
        </h3>
      </div>
    );
  }

  return (
    <div
      id="search_messages"
      className="flex-1 grow shrink-0 divide-y divide-gray-200 overflow-x-hidden overflow-y-auto"
    >
      <InfiniteScroll
        dataLength={pages.length}
        next={handleNextPage}
        hasMore={hasNextPage ?? false}
        scrollableTarget="search_messages"
        loader={<Loading />}
      >
        <>
          {pages.map(
            (page, pageIndex) =>
              page &&
              hasItems(page.results) &&
              page.results.map(({ content, startIndex, endIndex }, index) => (
                <ConversationContentSearchItem
                  key={`${content.id}_${pageIndex}_${index}`}
                  startIndex={startIndex}
                  endIndex={endIndex}
                  conversationContent={content}
                  focusedConversationContent={focusedConversationContent}
                  setFocusedConversationContent={setFocusedConversationContent}
                />
              ))
          )}
        </>
      </InfiniteScroll>
    </div>
  );
}

export default ConversationContentSearchResults;
