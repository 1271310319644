import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import { ConversationStatusEnum } from "@hilos/types/private-schema";
import { CONVERSATION_STATUS } from "../InboxMeta";

interface ConversationStatusBadgeProps {
  status?: ConversationStatusEnum | null;
}

export const getStatusIcon = (status: ConversationStatusEnum) => {
  switch (status) {
    case "NEW":
      return {
        icon: "icon-[ri--loader-3-line]",
        color: "text-blue-400",
      };
    case "IN_PROGRESS":
      return {
        icon: "icon-[ri--progress-5-line]",
        color: "text-blue-600",
      };
    case "FLOW":
      return {
        icon: "icon-[tabler--progress-bolt]",
        color: "text-orange-600",
      };
    case "RESOLVED":
      return {
        icon: "icon-[tabler--progress-check]",
        color: "text-green-600",
      };
    case "CLOSED":
      return {
        icon: "icon-[ri--progress-8-line]",
        color: "text-green-600",
      };
    default:
      return;
  }
};

const badgeVariants = {
  compact: {
    opacity: 0,
    width: 0,
    transition: { duration: 0.2 },
  },
  expanded: {
    opacity: 1,
    width: "auto",
    transition: {
      duration: 0.2,
      delay: 0.5,
    },
  },
};

export default function ConversationStatusBadge({
  status,
}: ConversationStatusBadgeProps) {
  const { t } = useTranslation();

  if (!status || !CONVERSATION_STATUS[status]) {
    return null;
  }

  return (
    <motion.div className="relative flex items-center">
      <motion.div
        initial="compact"
        whileHover="expanded"
        animate="compact"
        className="flex items-center"
      >
        <span
          className={`${getStatusIcon(status)?.icon} ${
            getStatusIcon(status)?.color
          } text-lg`}
        />
        <motion.div
          variants={badgeVariants}
          className="overflow-hidden flex items-center"
        >
          <span
            className={`ml-1 text-xs font-medium whitespace-nowrap ${
              getStatusIcon(status)?.color
            }`}
          >
            {t(CONVERSATION_STATUS[status].label)}
          </span>
        </motion.div>
      </motion.div>
    </motion.div>
  );
}
