import { useState } from "react";
import { Outlet, useMatch, useParams } from "react-router-dom";
import { motion } from "framer-motion";
import { UpdateInboxContactFn } from "@hilos/hooks/useInboxContactDetails";
import {
  ConversationContent,
  WhatsAppMessage,
} from "@hilos/types/private-schema";
import Loading from "src/components/Loading";
import useWorkflowTaskDetails from "src/hooks/useWorkflowTaskDetails";
import { classNames } from "src/Helpers";
import ForwardMessageModal from "../inbox/Conversation/ForwardMessageModal";
import WorkflowBoardTaskContent from "./WorkflowBoardTaskContent";
import WorkflowBoardTaskFooter from "./WorkflowBoardTaskFooter";
import WorkflowBoardTaskHeader from "./WorkflowBoardTaskHeader";

export default function WorkflowBoardTask() {
  const isConversationVisible = useMatch(
    "/workflow/:workflowId/board/:stateId/task/:taskId"
  );
  const { taskId, workflowId } = useParams() as {
    taskId: string;
    workflowId: string;
  };

  const { task, isLoading: isLoadingTask } = useWorkflowTaskDetails({
    taskId,
    workflowId,
  });
  const [selectedContextContent, setSelectedContextContent] =
    useState<ConversationContent | null>(null);
  const [selectedForwardMessage, setSelectedForwardMessage] =
    useState<WhatsAppMessage | null>(null);
  const [focusedConversationContent, setFocusedConversationContent] =
    useState<ConversationContent | null>(null);

  // @ts-ignore
  const handleUpdateInboxContact: UpdateInboxContactFn = () => {};

  if (isLoadingTask) {
    return <Loading />;
  }

  if (!task) {
    return null;
  }

  return (
    <div className="sticky w-full h-full">
      <div className="flex w-full h-full bg-gray-100 overflow-hidden">
        <motion.div
          initial={{ opacity: 0, width: 0 }}
          animate={{ opacity: 1, width: "auto" }}
          exit={{
            opacity: 0,
            width: 0,
          }}
          transition={{
            opacity: { duration: 0.2 },
            width: { duration: 0.4 },
            layout: { duration: 0.3 },
          }}
          layout
          className={classNames(
            "flex-1 overflow-hidden",
            isConversationVisible ? "flex" : "hidden lg:flex"
          )}
        >
          <div className="flex flex-col w-full h-full">
            <WorkflowBoardTaskHeader taskId={taskId} workflowId={workflowId} />
            <WorkflowBoardTaskContent
              inboxContact={task.inbox_contact}
              focusedConversationContent={focusedConversationContent}
              setFocusedConversationContent={setFocusedConversationContent}
              onSelectContextContent={setSelectedContextContent}
              onSelectForwardMessage={setSelectedForwardMessage}
            />
            <WorkflowBoardTaskFooter
              inboxContact={task.inbox_contact}
              context={selectedContextContent}
              onUpdateInboxContact={handleUpdateInboxContact}
              onSelectContextContent={setSelectedContextContent}
              onFocusConversationContent={setFocusedConversationContent}
              onDisableMessageMediaUpload={() => {}}
            />
            {selectedForwardMessage && (
              <ForwardMessageModal
                message={selectedForwardMessage}
                onSelectForwardMessage={setSelectedForwardMessage}
              />
            )}
          </div>
        </motion.div>
        <Outlet
          context={{
            focusedConversationContent,
            setFocusedConversationContent,
          }}
        />
      </div>
    </div>
  );
}
