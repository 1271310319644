import { useCallback, useMemo, useRef, useState } from "react";
import { QueryFunctionContext, useInfiniteQuery } from "react-query";
import axios from "axios";
import debounce from "lodash/debounce";
import { CursorPageData } from "@hilos/types/hilos";
import { ConversationContent } from "@hilos/types/private-schema";
import { API_ROUTES } from "src/router/router";

type FetchConversationContentSearchParams = QueryFunctionContext<
  [string, null | string, string]
>;

export interface ConversationContentSearchItemData {
  content: ConversationContent;
  startIndex: number | null;
  endIndex: number | null;
}

async function fetchConversationContentSearch({
  signal,
  pageParam,
  queryKey,
}: FetchConversationContentSearchParams): Promise<CursorPageData<ConversationContent> | null> {
  if (!queryKey || !queryKey[1] || !queryKey[2]) {
    return null;
  }

  const { data } = await axios.get<CursorPageData<ConversationContent>>(
    API_ROUTES.CONVERSATION_CONTENT.replace(":id", queryKey[1]),
    {
      signal,
      params: {
        cursor: pageParam,
        search: queryKey[2],
      },
    }
  );

  return data;
}

function useConversationContentSearch(inboxContactId: string | null = null) {
  const searchRef = useRef("");
  const [search, setSearch] = useState("");
  const [isTyping, setIsTyping] = useState(false);

  const {
    data,
    isError,
    isLoading,
    isFetching,
    hasNextPage,
    fetchNextPage: handleNextPage,
  } = useInfiniteQuery(
    ["conversation_content_search", inboxContactId, search],
    fetchConversationContentSearch,
    {
      retry: false,
      enabled: Boolean(search),
      getNextPageParam: (lastPage, pages) =>
        (lastPage && lastPage.next) || undefined,
      getPreviousPageParam: (lastPage, pages) =>
        (lastPage && lastPage.previous) || undefined,
    }
  );

  const handleSearch = useCallback(
    debounce((nextSearch) => {
      searchRef.current = nextSearch;
      setSearch(nextSearch);
      setIsTyping(false);
    }, 500),
    []
  );

  const handleChangeSearch = useCallback(
    (event) => {
      const nextSearch = event.target.value;
      setIsTyping(true);
      handleSearch(nextSearch);
    },
    [handleSearch]
  );

  const pages = useMemo(() => {
    if (!data || !data.pages) {
      return;
    }

    return data.pages.reduce((nextPages, page) => {
      if (page && page.results) {
        const nextResults = page.results.map((content) => {
          let startIndex: number | null = null;
          let endIndex: number | null = null;
          if (searchRef.current && content.message && content.message.body) {
            startIndex = content.message.body
              .toLowerCase()
              .indexOf(searchRef.current.toLowerCase());
            endIndex = startIndex + searchRef.current.length;
          }

          return { content, startIndex, endIndex };
        });
        nextPages.push({
          ...page,
          results: nextResults,
        });
      }
      return nextPages;
    }, [] as CursorPageData<ConversationContentSearchItemData>[]);
  }, [data]);

  return {
    pages,
    isError,
    isLoading,
    isFetching,
    isUpdating: isTyping || isLoading,
    hasNextPage,
    handleNextPage,
    handleChangeSearch,
  };
}

export default useConversationContentSearch;
