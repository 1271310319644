import { useCallback, useMemo, useState } from "react";
import {
  QueryFunctionContext,
  useInfiniteQuery,
  useMutation,
  useQuery,
} from "react-query";
import axios from "axios";
import { debounce } from "lodash";
import { UUIDTypes } from "uuid";
import { CursorPageData } from "@hilos/types/hilos";
import {
  ScheduledMessage,
  ScheduledMessageRead,
} from "@hilos/types/private-schema";
import { hasItems } from "src/helpers/utils";
import { API_ROUTES, buildAPIRoute } from "../router/router";

interface UseContactScheduledMessagesParams {
  inboxContactId?: string;
}

const createScheduleMessageMutation = async (params: ScheduledMessage) => {
  const { data } = await axios.post(
    buildAPIRoute(API_ROUTES.SCHEDULED_MESSAGE_LIST_CREATE),
    params
  );

  return data;
};

const deleteScheduledMessageMutation = async (id: UUIDTypes) => {
  const { data } = await axios.delete(
    buildAPIRoute(API_ROUTES.SCHEDULED_MESSAGE_DETAIL, { ":id": id })
  );

  return data;
};

const sendScheduledMessageMutation = async (id: UUIDTypes) => {
  const { data } = await axios.post(
    buildAPIRoute(API_ROUTES.SCHEDULED_MESSAGE_SEND, { ":id": id })
  );

  return data;
};

type FetchScheduledMessagesParams = QueryFunctionContext<[string, string]>;

async function fetchScheduledMessages({
  signal,
  pageParam,
  queryKey,
}: FetchScheduledMessagesParams): Promise<CursorPageData<ScheduledMessageRead> | null> {
  const { data } = await axios.get<CursorPageData<ScheduledMessageRead>>(
    API_ROUTES.SCHEDULED_MESSAGE_LIST_CREATE,
    {
      signal,
      params: {
        cursor: pageParam,
        search: queryKey[1] || undefined,
      },
    }
  );
  return data;
}

export default function useScheduledMessages() {
  const [search, setSearch] = useState("");

  const { mutateAsync: createScheduleMessage } = useMutation(
    createScheduleMessageMutation,
    {
      onSuccess: (data) => {
        if (data) {
          refetch();
        }
      },
    }
  );

  const { mutateAsync: deleteScheduledMessage } = useMutation(
    deleteScheduledMessageMutation,
    {
      onSuccess: () => {
        refetch();
      },
    }
  );

  const { mutateAsync: sendScheduledMessage } = useMutation(
    sendScheduledMessageMutation,
    {
      onSuccess: (data) => {
        if (data) {
          refetch();
        }
      },
    }
  );

  const {
    data,
    refetch,
    isLoading,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteQuery(["scheduled_messages", search], fetchScheduledMessages, {
    getNextPageParam: (lastPage, pages) =>
      (lastPage && lastPage.next) || undefined,
    getPreviousPageParam: (lastPage, pages) =>
      (lastPage && lastPage.previous) || undefined,
    retry: 3,
  });

  const handleChangeSearch = useCallback(
    debounce((event) => {
      const nextSearch = event.target.value;
      setSearch(nextSearch);
    }, 500),
    []
  );

  const [pages, count] = useMemo(() => {
    if (data && hasItems(data.pages)) {
      const lastPage = data.pages[data.pages.length - 1];
      return [data.pages, (lastPage && lastPage.count) || 0];
    }
    return [[], 0];
  }, [data]);

  return {
    createScheduleMessage,
    pages,
    count,
    refetch,
    isLoading,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
    handleChangeSearch,
    deleteScheduledMessage,
    sendScheduledMessage,
  };
}

export function useInboxContactScheduledMessages({
  inboxContactId,
}: UseContactScheduledMessagesParams) {
  const fetchInboxContactScheduledMessages = useCallback(
    async ({ signal }: FetchScheduledMessagesParams) => {
      if (inboxContactId) {
        const { data } = await axios.get(
          buildAPIRoute(API_ROUTES.SCHEDULED_MESSAGE_LIST_CREATE),
          {
            signal,
            params: {
              search: inboxContactId,
            },
          }
        );
        if (data.results) {
          return data.results;
        }
      }
      return [];
    },
    [inboxContactId]
  );

  const { data, isError, isLoading, isFetching, refetch } = useQuery(
    ["scheduled_messages", inboxContactId || ""],
    fetchInboxContactScheduledMessages,
    {
      retry: false,
    }
  );

  return {
    data,
    isError,
    isLoading,
    isFetching,
    refetch,
  };
}
