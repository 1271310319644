import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import { ChatAlt2Icon, PhoneIcon } from "@heroicons/react/outline";
import axios from "axios";
import Button from "src/components/Button";
import HeaderNavigation from "src/components/HeaderNavigation";
import Loading from "src/components/Loading";
import { useChannelAvailability } from "src/hooks/useChannel";
import {
  API_ROUTES,
  BASE_API_URL,
  buildAPIRoute,
  buildRoute,
} from "src/router/router";
import { WidgetList } from "./WidgetList";

const WidgetContainer = () => {
  const { t } = useTranslation();
  const { data: channelData, isLoading: isLoadingChannels } =
    useChannelAvailability();
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [deleteSubmitted, setDeleteSubmitted] = useState(false);

  const {
    data: widgetData,
    isLoading: isLoadingWidgets,
    isSuccess,
    refetch,
  } = useQuery(["widgets"], async () => {
    const { data } = await axios.get(
      buildAPIRoute(API_ROUTES.WHATSAPP_BUTTON_CREATE),
      {
        baseURL: BASE_API_URL,
        headers: {
          "x-module": "widget",
        },
      }
    );
    return {
      results: data,
      count: data.length,
    };
  });

  const handleDelete = async (
    formData,
    setSubmitting,
    setBackendValidationErrors,
    setBackendError
  ) => {
    setBackendValidationErrors({});
    setBackendError("");
    try {
      await axios.delete(
        buildAPIRoute(API_ROUTES.WHATSAPP_BUTTON_DETAIL, {
          ":id": formData.obj.id,
        }),
        {
          baseURL: BASE_API_URL,
          headers: {
            "x-module": "widget",
          },
        }
      );
      setDeleteSubmitted(false);
      setDeleteSuccess(true);
      refetch();
    } catch (err) {
      setDeleteSuccess(false);
      if (axios.isAxiosError(err) && err.response?.status === 400) {
        setBackendValidationErrors(err.response.data);
      } else {
        setBackendError(t("widget:error-delete", "Error deleting widget"));
      }
    } finally {
      setSubmitting(false);
      setDeleteSubmitted(false);
    }
  };

  if (isLoadingChannels || isLoadingWidgets) {
    return <Loading />;
  }

  if (channelData.length === 0) {
    return (
      <div>
        <div className="h-screen w-full flex justify-center items-center">
          <div className="flex md:w-2/4 flex-col space-y-3 self-center text-center">
            <PhoneIcon className="h-10 w-10 mx-auto" />
            <h1 className="text-gray-800">
              {t(
                "widget:no-channels.title",
                "Seems like you don't have channels yet"
              )}
            </h1>
            <p className="text-gray-500">
              {t(
                "widget:no-channels.subtitle",
                "What are you waiting for? Connect your WhatsApp number to create your own WhatsApp widget!"
              )}
            </p>
            <div className="gap-x-3">
              <Link to="/config/channel">
                <Button className="my-3">
                  {t("widget:connect-channel", "Connect a channel")}
                </Button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="py-4 px-4 sm:border-b sm:border-gray-200 sm:px-6 lg:px-8">
        <HeaderNavigation
          navPages={[
            {
              name: t("home"),
              url: buildRoute("dashboard"),
            },
            {
              name: t("widget:list-title", "WhatsApp Widgets"),
              url: buildRoute("wa-widget"),
            },
          ]}
        />
        <div className="lg:flex lg:items-center lg:justify-between">
          <div className="min-w-0 flex-1">
            <h2 className="mt-2 flex items-center text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl">
              <ChatAlt2Icon className="mr-4 h-7 w-7" aria-hidden="true" />
              {t("widget:list-title", "WhatsApp Widgets")}
            </h2>
            <p className="mt-1 text-sm text-gray-600">
              {t(
                "widget:list-description",
                "Manage your WhatsApp widgets and customize their appearance."
              )}
            </p>
          </div>
        </div>
      </div>

      <div className="px-4 sm:px-6 lg:px-8 py-8">
        <WidgetList
          data={{
            results: widgetData?.results || [],
            count: widgetData?.count || 0,
          }}
          isLoading={isLoadingWidgets}
          isSuccess={isSuccess}
          onDelete={handleDelete}
          deleteSuccess={deleteSuccess}
          deleteSubmitted={deleteSubmitted}
        />
      </div>
    </>
  );
};

export default WidgetContainer;
