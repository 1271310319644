import { useTranslation } from "react-i18next";
import { Link, useSearchParams } from "react-router-dom";
import logo from "src/assets/img/logo512.png";
import i18n from "src/i18n";
import { getTranslationPayload as t } from "src/i18n";
import { buildRoute } from "./router";

const modules = {
  template: {
    name: t("404:module.template.name", "template"),
    return: "whatsapp-template-list",
    back: t("404:module.template.back", "template list"),
  },
  contact: {
    name: t("404:module.contact.name", "contact"),
    return: "contact-list",
    back: t("404:module.contact.back", "contact list"),
  },
  flow: {
    name: t("404:module.flow.name", "flow"),
    return: "flow-list",
    back: t("404:module.flow.back", "flow list"),
  },
  broadcast: {
    name: t("404:module.broadcast.name", "broadcast"),
    return: "broadcast-list",
    back: t("404:module.broadcast.back", "broadcast list"),
  },
  webhook: {
    name: t("404:module.webhook.name", "webhook"),
    return: "webhook-list",
    back: t("404:module.webhook.back", "webhook list"),
  },
  widget: {
    name: t("404:module.widget.name", "widget"),
    return: "wa-widget",
    back: t("404:module.widget.back", "widget list"),
  },
  channel: {
    name: t("404:module.channel.name", "channel"),
    return: "config-channel",
    back: t("404:module.channel.back", "channel list"),
  },
  team: {
    name: t("404:module.team.name", "team"),
    return: "config-team-list",
    back: t("404:module.team.back", "team list"),
  },
  user: {
    name: t("404:module.user.name", "user"),
    return: "account-members",
    back: t("404:module.user.back", "user list"),
  },
  role: {
    name: t("404:module.role.name", "role"),
    return: "role-list",
    back: t("404:module.role.back", "role list"),
  },
  "contact-tag": {
    name: t("404:module.contact-tag.name", "contact tag"),
    return: "config-inbox-contact-tag-list",
    back: t("404:module.contact-tag.back", "contact tag list"),
  },
  "conversation-tag": {
    name: t("404:module.conversation-tag.name", "conversation tag"),
    return: "config-inbox-conversation-tag-list",
    back: t("404:module.conversation-tag.back", "conversation tag list"),
  },
};

const isValidModule = (
  module: string | null
): module is keyof typeof modules => {
  return module !== null && module in modules;
};

export default function NotFound() {
  const { t } = useTranslation();
  const { language } = i18n;
  const [searchParams] = useSearchParams();

  const module = searchParams.get("module");
  const hasValidModule = isValidModule(module);

  return (
    <>
      <div className="grid min-h-full grid-cols-1 md:grid-cols-2 bg-white">
        <div className="h-screen flex flex-col justify-between">
          <header className="mx-auto w-full max-w-7xl px-6 pt-6 sm:pt-10 lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:px-8">
            <Link
              to={
                hasValidModule
                  ? buildRoute(modules[module].return)
                  : buildRoute("dashboard")
              }
            >
              <span className="sr-only">Hilos</span>
              <img src={logo} className="h-16 w-16" alt="Hilos logo" />
            </Link>
          </header>
          <main className="mx-auto w-full max-w-7xl px-6 py-24 sm:py-32 lg:col-span-2 lg:col-start-1 lg:row-start-2 lg:px-8">
            <div className="max-w-lg">
              <p className="text-base/8 font-semibold text-hilos">404</p>
              <h1 className="mt-4 text-pretty text-5xl font-semibold tracking-tight text-gray-900 sm:text-6xl">
                {hasValidModule
                  ? t(
                      "404:module-title",
                      "There was an error loading your {{module}}",
                      { module: t(modules[module].name) }
                    )
                  : t("404:title", "Page not found")}
              </h1>
              <p className="mt-6 text-pretty text-lg font-medium text-gray-500 sm:text-xl/8">
                {hasValidModule
                  ? t(
                      "404:module-description",
                      "Sorry, we couldn’t find the {{module}} you’re looking for.",
                      { module: t(modules[module].name) }
                    )
                  : t(
                      "404:description",
                      "Sorry, we couldn’t find the page you’re looking for."
                    )}
              </p>
              <div className="mt-10">
                <Link
                  to={
                    hasValidModule
                      ? buildRoute(modules[module].return)
                      : buildRoute("dashboard")
                  }
                >
                  <span aria-hidden="true">&larr;</span>{" "}
                  {hasValidModule
                    ? t("404:module-back", "Back to {{next}}", {
                        next: t(modules[module].back),
                      })
                    : t("404:back", "Back to home")}
                </Link>
              </div>
            </div>
          </main>
          <footer className="lg:col-span-2 lg:col-start-1 lg:row-start-3">
            <div className="border-t border-gray-100 bg-gray-50 py-10">
              <nav className="mx-auto flex w-full max-w-7xl items-center gap-x-4 px-6 text-sm/7 text-gray-600 lg:px-8">
                <a
                  href={
                    language && language.toLowerCase().indexOf("es") === 0
                      ? "https://api.whatsapp.com/send?phone=525592257050&text=Hola!%20Necesito%20ayuda%20con%20Hilos."
                      : "https://api.whatsapp.com/send?phone=+525592257050&text=Hi!%20I%20need%20help%20with%20Hilos."
                  }
                >
                  {t("404:contact", "Contact support")}
                </a>
                <svg
                  viewBox="0 0 2 2"
                  aria-hidden="true"
                  className="size-0.5 fill-gray-300"
                >
                  <circle r={1} cx={1} cy={1} />
                </svg>
              </nav>
            </div>
          </footer>
        </div>
        <div className="">
          <img
            alt=""
            src="https://images.unsplash.com/photo-1631146875322-309e415f4b04?q=80&w=1740&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
            className="inset-0 size-full object-cover"
          />
        </div>
      </div>
    </>
  );
}
