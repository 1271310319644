import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import useHilosStore from "src/hooks/useHilosStore";

const AgentAvailabilityToggle = () => {
  const { t } = useTranslation();
  const { session, updateSession } = useHilosStore();

  const handleSetAvailibilityForInbox = useCallback(
    (nextVal) => {
      updateSession({
        is_available_for_inbox: nextVal,
      });
    },
    [updateSession]
  );

  const isAvailable = session?.is_available_for_inbox;

  return (
    <div className="group flex items-center space-x-2">
      <button
        className="flex items-center space-x-2 rounded-md p-1 hover:bg-gray-100"
        title={t(
          isAvailable
            ? t("inbox:available", "Available")
            : t("inbox:away", "Away")
        )}
        onClick={() =>
          handleSetAvailibilityForInbox(!session?.is_available_for_inbox)
        }
      >
        <span className="text-xs text-gray-500 group-hover:text-gray-700">
          {t(
            isAvailable
              ? t("inbox:available", "Available")
              : t("inbox:away", "Away")
          )}
        </span>
        <div
          className={`h-3 w-3 rounded-full transition-colors duration-200 ${
            isAvailable ? "bg-green-500" : "border-2 border-orange-400"
          }`}
        />
      </button>
    </div>
  );
};

export default AgentAvailabilityToggle;
