import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import ReactFlow, {
  Background,
  Controls,
  Edge,
  MiniMap,
  Node,
} from "reactflow";
import "reactflow/dist/style.css";
import { FieldArrayRenderProps, setIn, useFormikContext } from "formik";
import { FlowData, FlowNodeData } from "@hilos/types/flow";
import edgeTypes from "./EdgeTypes/editor";
import FlowStepErrors from "./FlowStepErrors";
import nodeTypes from "./NodeTypes/editor";
import FlowBuilderAddStep from "./components/FlowBuilderAddStep";
import FlowBuilderStepDetail from "./components/FlowBuilderStepDetail";
import useFlowBuilder from "./hooks/useFlowBuilder";
import useFlowLayout from "./hooks/useFlowLayout";

interface FlowBuilderProps {
  showStepErrors: boolean;
  helpers: FieldArrayRenderProps;
  initialNodes: Node<FlowNodeData>[];
  initialEdges: Edge[];
}

const getNodeColor = (node: Node<FlowNodeData>) => {
  if (node.selected) {
    return "#93c5fd";
  }
  return "#e2e2e2";
};

function FlowBuilder({
  showStepErrors,
  helpers,
  initialNodes,
  initialEdges,
}: FlowBuilderProps) {
  const { t } = useTranslation();
  const { values, errors, touched, setTouched } = useFormikContext<FlowData>();

  const onSetStepTouched = useCallback(
    (nextStepIndex: number) => {
      setTouched({
        ...setIn(touched, `steps.${nextStepIndex}.name`, true),
      });
    },
    [setTouched, touched]
  );

  const {
    nodes,
    edges,
    selectedItem,
    onSelectItem,
    onClearSelectItem,
    onNodesChange,
    onEdgesChange,
    onUpdateTransitions,
  } = useFlowLayout({
    initialEdges,
    initialNodes,
    onSetStepTouched,
  });
  const { onDrop, onAddStep, onNodeClick, onDragStart, onDragEnd } =
    useFlowBuilder({
      nodes,
      edges,
      helpers,
      selectedItem,
      onSelectItem,
      onUpdateTransitions,
    });

  return (
    <ReactFlow
      fitView
      fitViewOptions={{
        padding: 2,
      }}
      proOptions={{
        account: "paid-pro",
        hideAttribution: true,
      }}
      minZoom={0.2}
      autoPanOnNodeDrag={false}
      multiSelectionKeyCode={null}
      selectionKeyCode={null}
      nodesDraggable={false}
      nodesConnectable={false}
      zoomOnDoubleClick={false}
      onlyRenderVisibleElements
      nodeTypes={nodeTypes}
      edgeTypes={edgeTypes}
      nodes={nodes}
      edges={edges}
      onDrop={onDrop}
      onDragStart={onDragStart}
      onDragEnd={onDragEnd}
      onNodeClick={onNodeClick}
      onNodesChange={onNodesChange}
      onEdgesChange={onEdgesChange}
    >
      <Controls
        showInteractive={false}
        position="bottom-left"
        className="ml-56 bg-white opacity-40 hover:opacity-100"
      />
      <MiniMap
        nodeColor={getNodeColor}
        nodeStrokeWidth={3}
        zoomable
        pannable
        position="bottom-left"
        style={{ boxShadow: "0 0 2px 1px rgba(0, 0, 0, 0.08)" }}
      />
      <FlowBuilderAddStep
        selectedItem={selectedItem}
        onAddStep={onAddStep}
        onClearSelectItem={onClearSelectItem}
      />
      {showStepErrors && (
        <FlowStepErrors
          values={values}
          errors={errors}
          onClearSelectItem={onClearSelectItem}
        />
      )}
      <FlowBuilderStepDetail
        selectedItem={selectedItem}
        onClearSelectItem={onClearSelectItem}
      />
      <div className="absolute right-4 bottom-4 z-10 px-2">
        <a
          href="https://airtable.com/appCMr2XVSH0eRuOq/shrt5x5VmYWvthKrT"
          className="text-sm font-medium text-gray-400 hover:text-gray-700"
        >
          {t("helps-us-to-improve", "Help us to improve!")}
        </a>
      </div>
      <Background color="#4b5563" className="bg-gray-100" gap={16} />
    </ReactFlow>
  );
}

export default FlowBuilder;
