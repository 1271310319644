import { useCallback } from "react";
import { useQuery } from "react-query";
import axios from "axios";
import { WorkflowStateRead } from "@hilos/types/private-schema";
import { API_ROUTES, buildAPIRoute } from "../router/router";

function useWorkflowStates(id: string | undefined) {
  const fetchWorkflowStates = useCallback(async () => {
    if (!id) {
      return null;
    }
    const { data } = await axios.get<WorkflowStateRead[]>(
      buildAPIRoute(API_ROUTES.WORKFLOW_STATE_LIST, {
        ":id": id,
      })
    );
    console.log("data", data);
    return data;
  }, [id]);

  const { data } = useQuery(["workflow_states", id], fetchWorkflowStates);

  return data || [];
}

export default useWorkflowStates;
