import { faCheck, faCheckDouble } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ConversationContent } from "@hilos/types/private-schema";
import { classNames } from "src/Helpers";
import { getDateTimeFromTimestamp } from "../../../../helpers/date";

interface ConversationContentSearchItemProps {
  startIndex: number | null;
  endIndex: number | null;
  conversationContent: ConversationContent;
  focusedConversationContent: ConversationContent | null;
  setFocusedConversationContent: (data: ConversationContent) => void;
}

const MAX_TRUNCATED_LENGTH = 25;

function getMessageSearchResult(
  data: ConversationContent,
  startIndex: number | null,
  endIndex: number | null
) {
  if (!data.message || !data.message.body) {
    return "";
  }

  if (startIndex === null || endIndex === null) {
    return <span>{data.message.body || ""}</span>;
  }

  const endText = data.message.body.slice(endIndex) || "";
  const markedText = data.message.body.slice(startIndex, endIndex);

  const maxSideLength = (MAX_TRUNCATED_LENGTH - markedText.length) / 2;
  const rightSideOffsetLength = Math.max(maxSideLength - endText.length, 0);
  const offsetIndex = Math.max(
    startIndex - (maxSideLength + rightSideOffsetLength),
    0
  );

  return (
    <>
      <span>{data.message.body.slice(offsetIndex, startIndex) || ""}</span>
      <span className="text-indigo-400">{markedText}</span>
      <span>{endText}</span>
    </>
  );
}

function ConversationContentSearchItem({
  startIndex,
  endIndex,
  conversationContent,
  focusedConversationContent,
  setFocusedConversationContent,
}: ConversationContentSearchItemProps) {
  if (!conversationContent.message || conversationContent.message.is_deleted) {
    return null;
  }

  return (
    <div
      className={classNames(
        "flex-1 w-full group block hover:bg-gray-300 overflow-x-hidden px-4 py-3",
        focusedConversationContent &&
          conversationContent.id === focusedConversationContent.id &&
          "bg-gray-200"
      )}
      onClick={() => setFocusedConversationContent(conversationContent)}
    >
      <div className="mt-0.5 flex items-center text-xs text-gray-400 sm:mt-0">
        {conversationContent.timestamp &&
          getDateTimeFromTimestamp(conversationContent.timestamp)}
      </div>
      <div className="mt-0.5 flex w-full flex-row">
        {conversationContent.message &&
          conversationContent.message.direction === "OUTBOUND" && (
            <div className="pr-1.5 text-xs">
              {conversationContent.message.status === "sent" && (
                <FontAwesomeIcon
                  // @ts-ignore
                  icon={faCheck}
                  className=""
                />
              )}
              {conversationContent.message.status === "delivered" && (
                <FontAwesomeIcon
                  // @ts-ignore
                  icon={faCheckDouble}
                  className=""
                />
              )}
              {conversationContent.message.status === "read" && (
                <FontAwesomeIcon
                  // @ts-ignore
                  icon={faCheckDouble}
                  className="text-blue-600"
                />
              )}
            </div>
          )}
        <p className="truncate text-xs font-semibold text-gray-600">
          {getMessageSearchResult(conversationContent, startIndex, endIndex)}
        </p>
      </div>
    </div>
  );
}

export default ConversationContentSearchItem;
