import React, {
  Suspense,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useAudioRecorder } from "react-audio-voice-recorder";
import { useTranslation } from "react-i18next";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TrashIcon } from "@heroicons/react/outline";
import axios from "axios";
import { Mic, Pause } from "lucide-react";
import { SendMessageFn } from "@hilos/hooks/useConversationContent";
import { BROWSER_IS_SAFARI } from "src/helpers/utils";
import { API_ROUTES } from "src/router/router";

interface BottomBarMessageAudioProps {
  handleSendMessage: SendMessageFn;
  onClose: () => void;
}

const LiveAudioVisualizer = React.lazy(async () => {
  const { LiveAudioVisualizer } = await import("react-audio-visualize");
  return { default: LiveAudioVisualizer };
});

const AUDIO_TYPE_EXT = BROWSER_IS_SAFARI ? "mp4" : "webm";

async function uploadAudioFile(blob: Blob) {
  try {
    const formData = new FormData();
    const outputName = `audio_${+new Date()}.${AUDIO_TYPE_EXT}`;
    const outputFile = new File([blob], outputName);

    formData.append("content_type", `audio/${AUDIO_TYPE_EXT}`);
    formData.append("uploaded_file", outputFile);

    const { data } = await axios.post(API_ROUTES.PUBLIC_FILE_UPLOAD, formData);
    return data;
  } catch {}
  return null;
}

function BottomBarMessageAudio({
  handleSendMessage,
  onClose,
}: BottomBarMessageAudioProps) {
  const { t } = useTranslation();
  const statusRef = useRef<"recording" | "uploading" | null>(null);
  const [error, setError] = useState(false);
  const [shouldSave, setShouldSave] = useState(false);

  const {
    startRecording,
    stopRecording,
    togglePauseResume,
    recordingBlob,
    isPaused,
    isRecording,
    recordingTime,
    mediaRecorder,
  } = useAudioRecorder(
    {
      noiseSuppression: true,
      echoCancellation: true,
    },
    () => setError(true),
    { mimeType: `audio/${AUDIO_TYPE_EXT}` }
  );

  const handleCloseRecorder = useCallback(() => {
    setShouldSave(false);
    stopRecording();
    onClose();
  }, [stopRecording, onClose]);

  const handleSendAudio = useCallback(() => {
    setShouldSave(true);
    stopRecording();
  }, [stopRecording]);

  useEffect(() => {
    if (shouldSave) {
      async function uploadRecordingBlob() {
        if (recordingBlob != null && statusRef.current !== "uploading") {
          statusRef.current = "uploading";
          const data = await uploadAudioFile(recordingBlob);

          if (data) {
            handleSendMessage({
              content_url: data.url,
              content_media: data.id,
              content_type: data.content_type,
            });
            onClose();
          }
        }
      }
      uploadRecordingBlob();
    }
  }, [shouldSave, recordingBlob, handleSendMessage, onClose]);

  useEffect(() => {
    if (!statusRef.current) {
      statusRef.current = "recording";
      startRecording();
    }
  }, [startRecording]);

  if (error) {
    return (
      <div className="flex w-full items-center justify-between py-2 space-x-2 px-4 bg-red-50 text-red-700 rounded-md">
        <span className="text-sm">
          {t(
            "inbox:audio-recording.missing-permissions",
            "Make sure you have permissions to use the microphone before sending your voice message."
          )}
        </span>
        <button
          type="button"
          onClick={handleCloseRecorder}
          className="p-2 hover:bg-red-100 rounded-full"
        >
          <TrashIcon className="w-5 h-5" />
        </button>
      </div>
    );
  }

  return (
    <div className="flex justify-between items-center space-x-2 pt-1 pb-2 px-2">
      <button
        type="button"
        onClick={handleCloseRecorder}
        className="p-2 text-gray-400 hover:text-gray-500 hover:bg-gray-100 rounded-full"
      >
        <TrashIcon className="w-5 h-5" />
      </button>
      <div className="flex flex-row gap-2">
        <div className="flex items-center space-x-5">
          {mediaRecorder && (
            <Suspense
              fallback={
                <div className="h-8 bg-gray-100 animate-pulse rounded-full flex-1" />
              }
            >
              <LiveAudioVisualizer
                mediaRecorder={mediaRecorder}
                width={`150%`}
                height={32}
                barWidth={2}
                gap={1}
                barColor="#f76565"
                backgroundColor="transparent"
                fftSize={512}
                minDecibels={-80}
              />
            </Suspense>
          )}
          <span className="text-sm font-medium text-gray-900 min-w-[48px]">
            {Math.floor(recordingTime / 60)}:
            {String(recordingTime % 60).padStart(2, "0")}
          </span>
        </div>

        <div className="flex items-center space-x-2">
          <button
            type="button"
            onClick={togglePauseResume}
            className="p-2 text-gray-600 hover:text-gray-700 hover:bg-gray-100 rounded-full"
          >
            {isPaused ? (
              <Mic className="w-5 h-5" />
            ) : (
              <Pause className="w-5 h-5" />
            )}
          </button>

          <button
            type="button"
            onClick={handleSendAudio}
            className="flex h-10 w-10 items-center justify-center align-middle rounded-full bg-indigo-600 text-white hover:bg-indigo-700"
          >
            <FontAwesomeIcon
              // @ts-ignore
              icon={faPaperPlane}
              className="-ml-0.5"
            />
          </button>
        </div>
      </div>
    </div>
  );
}

export default BottomBarMessageAudio;
