import { useCallback } from "react";
import { RadioGroup } from "@headlessui/react";
import { useField, useFormikContext } from "formik";
import { classNames } from "../../Helpers";

export type RadioButtonsFieldValue = string | boolean | number;

export interface RadioButtonsFieldProps<T extends RadioButtonsFieldValue> {
  name: string;
  label?: string;
  options: {
    label: string;
    value: T;
    disabled?: boolean;
    icon?: any;
  }[];
  className?: string;
}

function RadioButtonsField<T extends RadioButtonsFieldValue>({
  label,
  name,
  options,
  ...props
}: RadioButtonsFieldProps<T>) {
  const [field] = useField(name);
  const { setFieldValue, setFieldTouched } = useFormikContext();

  const handleChange = useCallback(
    (value) => {
      setFieldValue(field.name, value);
      setFieldTouched(field.name);
    },
    [field.name, setFieldValue, setFieldTouched]
  );

  return (
    <RadioGroup {...props} value={field.value} onChange={handleChange}>
      {label && (
        <RadioGroup.Label className="mb-1 block text-sm font-medium text-gray-700">
          {label}
        </RadioGroup.Label>
      )}
      <div className="grid grid-cols-2 gap-3">
        {options.map((option) => (
          <RadioGroup.Option
            key={option.label}
            value={option.value}
            className={({ active, checked }) =>
              classNames(
                option.disabled
                  ? "cursor-not-allowed opacity-25"
                  : "cursor-pointer focus:outline-none",
                active && "ring-2 ring-hilos ring-offset-1",
                checked
                  ? "border-transparent bg-gradient-to-r from-hilos via-red-400 to-hilos bg-size-200 bg-left-top text-white transition-all duration-300 ease-in hover:bg-right-bottom active:via-hilos active:to-red-800"
                  : "border-gray-200 bg-white text-gray-900 hover:bg-gray-50",
                "flex items-center justify-center rounded-md border py-2 px-2 font-medium sm:flex-1"
              )
            }
            disabled={option.disabled}
          >
            <RadioGroup.Label as="div">
              <div className="flex items-center justify-start text-sm font-medium">
                {option.icon && (
                  <div>
                    <option.icon className="mr-2 h-4 w-4" />
                  </div>
                )}
                <div className="">{option.label}</div>
              </div>
            </RadioGroup.Label>
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  );
}

export default RadioButtonsField;
