import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMediaPredicate } from "react-media-hook";
import { useNavigate } from "react-router-dom";
import {
  BanIcon,
  ChatIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ClockIcon,
  PaperAirplaneIcon,
  SearchIcon,
  UserIcon,
} from "@heroicons/react/outline";
import * as Sentry from "@sentry/browser";
import { format } from "date-fns";
import { UUIDTypes } from "uuid";
import { ScheduledMessageRead } from "@hilos/types/private-schema";
import InfiniteScroll from "src/containers/inbox/Conversation/InfiniteScroll";
import DeleteObjectModal from "src/components/DeleteObjectModal";
import DropdownMenu from "src/components/DropdownMenu";
import Loading from "src/components/Loading";
import StateButton from "src/components/StateButton";
import { hasItems } from "src/helpers/utils";
import useScheduledMessages from "src/hooks/useScheduledMessages";
import { classNames, contactToString, userToString } from "src/Helpers";

function ScheduledMessageList() {
  const { t } = useTranslation();

  const {
    pages,
    isLoading,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
    handleChangeSearch,
    deleteScheduledMessage,
    sendScheduledMessage,
  } = useScheduledMessages();
  const navigate = useNavigate();
  const isLargeScreen = useMediaPredicate("(min-width: 1024px)");
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [deleteSuccess, setDeleteSuccess] = useState<boolean>(false);
  const [deleteSubmitted, setDeleteSubmitted] = useState<boolean>(false);
  const [isSending, setIsSending] = useState<boolean>(false);
  const [submittedSend, setSubmittedSend] = useState<boolean>(false);
  const [successSend, setSuccessSend] = useState<boolean>(false);
  const [selectedScheduledMsg, setSelectedScheduledMsg] = useState<
    ScheduledMessageRead | undefined
  >(undefined);

  const handleDeleteScheduledMessage = useCallback(
    async (id: UUIDTypes) => {
      try {
        deleteScheduledMessage(id);
      } catch (error) {
        Sentry.captureException(error);
      }
      setDeleteSubmitted(true);
      setDeleteSuccess(true);
      setShowDeleteModal(false);
    },
    [deleteScheduledMessage]
  );

  const handleNavigateToConversation = useCallback(
    (inboxContactId) => {
      navigate(
        `/inbox/conversation/${inboxContactId}${
          isLargeScreen ? "/details" : ""
        }`
      );
    },
    [navigate, isLargeScreen]
  );

  const handleSendScheduledMessage = useCallback(
    async (scheduledMessage: ScheduledMessageRead) => {
      cleanStates();
      setIsSending(true);
      try {
        sendScheduledMessage(scheduledMessage.id as UUIDTypes);

        setSuccessSend(true);
        setTimeout(() => {
          handleNavigateToConversation(scheduledMessage.inbox_contact.id);
        }, 1000 * 2);
      } catch (error) {
        Sentry.captureException(error);
      } finally {
        setSubmittedSend(true);
        setIsSending(true);
      }
    },
    [sendScheduledMessage, handleNavigateToConversation]
  );

  useEffect(() => {
    setTimeout(() => {
      cleanStates();
    }, 1000 * 2);
  }, [showDeleteModal]);

  const cleanStates = () => {
    setDeleteSubmitted(false);
    setDeleteSuccess(false);
    setIsSending(false);
    setSubmittedSend(false);
    setSuccessSend(false);
  };

  return (
    <section className="block h-full grow overflow-y-auto bg-gray-50">
      <div className=" h-full overflow-y-auto rounded-lg p-4">
        <div className="flex  items-center">
          <button
            type="button"
            className="mr-4 rounded-md border border-gray-200 bg-white p-2"
            onClick={(_) => {
              navigate(-1);
            }}
          >
            <ChevronLeftIcon className="h-4 w-4 text-gray-400" />
          </button>
          <p className="text-lg font-medium text-gray-900">
            {t("inbox:scheduled-message.title", "Scheduled Messages")}
          </p>
        </div>

        <div className="relative mt-4 w-full shrink-0">
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-4 sm:pl-6">
            <SearchIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </div>
          <input
            className="block w-full rounded-lg border border-gray-200  py-3 pl-12 pr-4 text-gray-700 placeholder:text-gray-400 focus:ring-0 sm:pl-14 sm:text-sm"
            type="search"
            placeholder={t("search", "Search...")}
            onChange={handleChangeSearch}
          />
        </div>
        {isLoading ? (
          <Loading />
        ) : (
          <>
            {pages &&
            pages.length > 0 &&
            pages[0] &&
            pages[0].count &&
            pages[0].count > 0 ? (
              <div className="h-full overflow-y-auto">
                <InfiniteScroll
                  className="h-auto grow"
                  isFetchingNextPage={isFetchingNextPage}
                  hasNextPage={hasNextPage}
                  onNextPage={fetchNextPage}
                >
                  <>
                    {pages.map(
                      (page, pageIndex) =>
                        page &&
                        hasItems(page.results) &&
                        page.results.map((scheduledMsg, index) => (
                          <div key={`${scheduledMsg.id}_${pageIndex}_${index}`}>
                            <div className="my-2 flex w-full flex-col rounded-md border  border-gray-100 bg-white px-4 py-4 text-sm shadow-sm">
                              <div className="flex align-bottom">
                                <div className="mr-2 self-end font-semibold text-gray-900">
                                  {contactToString(
                                    scheduledMsg.inbox_contact.contact
                                  )}
                                </div>
                                {!isSending && (
                                  <div className="ml-auto">
                                    <DropdownMenu
                                      menuItems={[
                                        (active) => (
                                          <StateButton
                                            isSubmitting={isSending}
                                            submitted={submittedSend}
                                            success={successSend}
                                            onClick={(_) => {
                                              setSelectedScheduledMsg(
                                                scheduledMsg
                                              );
                                              handleSendScheduledMessage(
                                                scheduledMsg
                                              );
                                            }}
                                            className={classNames(
                                              active
                                                ? "bg-blue-50 text-blue-700"
                                                : "text-gray-700",
                                              "group flex w-full items-center px-4 py-2 text-left text-sm"
                                            )}
                                            submittingText={t(
                                              "inbox:scheduled-message.sending",
                                              "Sending message ..."
                                            )}
                                            successText={t(
                                              "inbox:scheduled-message.sent",
                                              "Message sent"
                                            )}
                                            initialText={
                                              <>
                                                <PaperAirplaneIcon
                                                  className="-ml-0.5 mr-2 h-4 w-4 rotate-90"
                                                  aria-hidden="true"
                                                />
                                                {t(
                                                  "inbox:scheduled-message.send",
                                                  "Send now"
                                                )}
                                              </>
                                            }
                                          />
                                        ),
                                        (active) => (
                                          <button
                                            className={classNames(
                                              active
                                                ? "bg-red-100 text-red-800"
                                                : "text-gray-700",
                                              "group flex w-full items-center px-4 py-2 text-sm"
                                            )}
                                            type="button"
                                            onClick={(_) => {
                                              setSelectedScheduledMsg(
                                                scheduledMsg
                                              );
                                              setShowDeleteModal(true);
                                            }}
                                          >
                                            <BanIcon
                                              className="-ml-0.5 mr-2 h-4 w-4"
                                              aria-hidden="true"
                                            />
                                            {t(
                                              "inbox:scheduled-message.cancel",
                                              "Cancel send"
                                            )}
                                          </button>
                                        ),
                                      ]}
                                    />
                                  </div>
                                )}
                              </div>
                              <div className="mt-2 mr-4 flex text-sm text-gray-500">
                                <span className="mr-2 rounded-lg bg-green-50 p-1 text-xs font-medium text-green-700">
                                  <ChatIcon
                                    className="h-3 w-3"
                                    aria-hidden="true"
                                  />
                                </span>
                                <span className="truncate">
                                  {scheduledMsg.text}
                                </span>
                              </div>
                              {isSending &&
                              selectedScheduledMsg === scheduledMsg ? (
                                <div className="mt-4 border-t border-gray-100 pt-4 text-center font-medium text-green-600">
                                  {t(
                                    "inbox:scheduled-message.sending",
                                    "Sending message ..."
                                  )}
                                </div>
                              ) : (
                                <div className="mt-4 flex flex-wrap border-t border-gray-100 pt-2">
                                  <div className="mt-2 flex flex-shrink-0 items-center self-center text-xs text-gray-500">
                                    <UserIcon className="mr-1 h-3 w-3" />
                                    {t(
                                      "inbox:scheduled-message.scheduled_by",
                                      "Scheduled by"
                                    )}{" "}
                                    <span className="ml-1  font-medium text-gray-800">
                                      {userToString(scheduledMsg.created_by)}
                                    </span>
                                  </div>
                                  <div className="mt-2 ml-auto mr-4 flex flex-shrink-0 items-center text-xs font-semibold text-gray-900">
                                    <span className="mr-1 rounded-lg p-1 text-xs font-medium ">
                                      <ClockIcon
                                        className=" h-3 w-3"
                                        aria-hidden="true"
                                      />
                                    </span>
                                    <span className="mr-1 text-xs font-normal text-gray-600">
                                      {t(
                                        "inbox:scheduled-message.send-on",
                                        "Send on"
                                      )}{" "}
                                    </span>
                                    <time dateTime={scheduledMsg.send_on}>
                                      {format(
                                        new Date(scheduledMsg.send_on),
                                        " HH:mm yy-MM-dd"
                                      )}
                                    </time>
                                  </div>
                                  <div className="mt-2 ml-auto mr-4 flex flex-shrink-0 items-center text-xs font-semibold text-gray-900">
                                    <button
                                      onClick={(_) =>
                                        handleNavigateToConversation(
                                          scheduledMsg.inbox_contact.id
                                        )
                                      }
                                      className="flex self-center text-left font-semibold text-blue-700"
                                    >
                                      {t(
                                        "inbox:scheduled-message.go-to-conversation",
                                        "Go to conversation"
                                      )}
                                      <ChevronRightIcon className="ml-2 h-4 w-4" />
                                    </button>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        ))
                    )}
                  </>
                </InfiniteScroll>
              </div>
            ) : (
              <div className="flex h-full w-full flex-col items-center justify-center text-gray-500">
                <ClockIcon className="mx-auto mb-2 h-12 w-12 text-gray-400" />
                <p className="text-center">
                  {t(
                    "inbox:scheduled-messages.empty-state",
                    "There's no scheduled messages to send."
                  )}
                </p>
              </div>
            )}
          </>
        )}
      </div>
      {selectedScheduledMsg && (
        <DeleteObjectModal
          obj={selectedScheduledMsg}
          objDescription={t(
            "inbox:scheduled-message.delete.title",
            "the scheduled message for {{contactToString}}",
            {
              contactToString: contactToString(
                selectedScheduledMsg.inbox_contact.contact
              ),
            }
          )}
          actionDescription={t(
            "inbox:scheduled-message.cancel-warning",
            "When canceling this scheduled message this message cannot be recovered"
          )}
          onDelete={() =>
            handleDeleteScheduledMessage(selectedScheduledMsg.id as UUIDTypes)
          }
          deleteSuccess={deleteSuccess}
          deleteSubmitted={deleteSubmitted}
          show={showDeleteModal}
          setShow={setShowDeleteModal}
        >
          <></>
        </DeleteObjectModal>
      )}
    </section>
  );
}

export default ScheduledMessageList;
