import { useCallback } from "react";
import { useQuery } from "react-query";
import axios from "axios";
import { Role } from "@hilos/types/private-schema";
import { API_ROUTES, buildAPIRoute } from "src/router/router";

export function useRoles() {
  const fetchRoles = useCallback(async () => {
    const { data } = await axios.get<[Role]>(
      buildAPIRoute(API_ROUTES.ROLE_LIST),
      {
        headers: {
          "x-module": "role",
        },
      }
    );
    return data;
  }, []);

  const { data } = useQuery(["roles"], fetchRoles);

  return data || [];
}

export function useRole(id: string | undefined) {
  const fetchRole = useCallback(async () => {
    if (!id) {
      return null;
    }
    const { data } = await axios.get<Role>(
      buildAPIRoute(API_ROUTES.ROLE_DETAIL, {
        ":id": id,
      }),
      {
        headers: {
          "x-module": "role",
        },
      }
    );
    return data;
  }, [id]);

  const { data } = useQuery(["role", id], fetchRole, {
    refetchOnWindowFocus: true,
  });

  return data;
}
