import { useTranslation } from "react-i18next";
import { PlusSmIcon } from "@heroicons/react/outline";
import { FieldArray } from "formik";
import HelpDocsLink from "src/components/HelpDocsLink";
import useAccount from "src/hooks/useAccount";
import { useContactTags, useConversationTags } from "src/hooks/useTags";
import FilterField from "./FilterField";
import FilterFieldContainer from "./FilterFieldContainer";
import { InboxParamsFormikProps } from "./InboxParamsForm";

interface InboxParamsFiltersFormProps {
  formik: InboxParamsFormikProps;
}

const DEFAULT_FILTER = {
  field: "",
  operator: "",
  value: "",
};

function InboxParamsFiltersForm({ formik }: InboxParamsFiltersFormProps) {
  const { t } = useTranslation();
  const { values, setFieldValue } = formik;
  const { members } = useAccount();
  const { tags: contactTags } = useContactTags();
  const { tags: conversationTags } = useConversationTags();

  return (
    <FieldArray
      name="filters"
      render={(arrayHelpers) => (
        <>
          <div className="flex flex-row justify-between">
            <div className="flex items-center">
              <div className="flex-1 text-sm font-semibold uppercase tracking-wide text-gray-500">
                {t("filters", "Filters")}
              </div>
              <button
                type="button"
                className="inline-flex h-8 w-8 items-center justify-center rounded-full text-gray-500 transition duration-150 ease-in-out hover:bg-gray-300 focus:outline-none"
                onClick={(_) => arrayHelpers.push(DEFAULT_FILTER)}
              >
                <PlusSmIcon className="h-5 w-5" />
              </button>
            </div>
            <HelpDocsLink
              href="https://hilos.io/docs/user/using-hilos/inbox/finding-a-conversation"
              className="text-xs"
            />
          </div>

          <ul className="divide-y divide-gray-200">
            {values.filters &&
              values.filters.map((filter, index) => {
                if (filter.field === "channel") {
                  return null;
                }

                return (
                  <FilterFieldContainer
                    key={index}
                    index={index}
                    name={`filters.${index}`}
                    filter={filter}
                    setFieldValue={setFieldValue}
                    arrayHelpers={arrayHelpers}
                  >
                    <FilterField
                      name={`filters.${index}`}
                      filter={filter}
                      members={members}
                      contactTags={contactTags}
                      conversationTags={conversationTags}
                    />
                  </FilterFieldContainer>
                );
              })}
          </ul>
        </>
      )}
    />
  );
}

export default InboxParamsFiltersForm;
