import { useMemo } from "react";
import { differenceInMinutes } from "date-fns";

interface ConversationStatusIndicatorProps {
  color?: string;
  lastInboundMessageOn?: string | null;
}

function ConversationStatusIndicator({
  color = "red",
  lastInboundMessageOn,
}: ConversationStatusIndicatorProps) {
  const { spanClassName } = useMemo(() => {
    if (!lastInboundMessageOn) return { spanClassName: "ml-1 text-red-500" };

    const lastMessage = new Date(lastInboundMessageOn);
    const closeDate = new Date(lastMessage.getTime() + 24 * 60 * 60 * 1000);
    const now = new Date();

    if (closeDate <= now) return { spanClassName: "ml-1 text-red-500" };

    const totalMinutes = 24 * 60;
    const remainingMinutes = differenceInMinutes(closeDate, now);
    const percentage = (remainingMinutes / totalMinutes) * 100;

    if (percentage >= 50) {
      return { spanClassName: "ml-1 text-green-500" };
    } else if (percentage >= 1) {
      return { spanClassName: "ml-1 text-yellow-500" };
    } else {
      return { spanClassName: "ml-1 text-red-500" };
    }
  }, [lastInboundMessageOn]);

  return (
    <span className={`flex ${spanClassName} self-center justify-self-center`}>
      <svg width="8" height="8">
        <circle cx="4" cy="4" r="4" fill="currentColor"></circle>
      </svg>
    </span>
  );
}

export default ConversationStatusIndicator;
