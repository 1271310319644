import { useTranslation } from "react-i18next";
import { BanIcon } from "@heroicons/react/outline";
import {
  MessageTypeEnum,
  WhatsAppMessage,
  WhatsAppMessageSimple,
} from "@hilos/types/private-schema";
import {
  getWhatsAppMessageTypeIcon,
  getWhatsAppMessageTypeLabel,
} from "src/helpers/inbox";
import { userToString } from "src/Helpers";

interface WhatsAppMessageOneLinePreviewProps {
  message: WhatsAppMessage | WhatsAppMessageSimple;
  body?: string | null;
  type?: MessageTypeEnum;
  deleted?: boolean;
}

function WhatsAppMessageOneLinePreview({
  message,
  body,
  type,
  deleted,
}: WhatsAppMessageOneLinePreviewProps) {
  const { t } = useTranslation();

  if (deleted) {
    return (
      <div className="inline-flex flex-row align-middle text-gray-400">
        <BanIcon className="mx-1 h-4 w-4 self-center" />
        {t("deleted-message", "Deleted message")}
      </div>
    );
  }

  if (body) {
    return (
      <>
        {message.direction === "OUTBOUND" && message.sent_by && (
          <span className="text-bold">{userToString(message.sent_by)}: </span>
        )}
        {body}
      </>
    );
  }

  const RenderMessageIcon = getWhatsAppMessageTypeIcon(type);
  const label = getWhatsAppMessageTypeLabel(type);

  if (RenderMessageIcon && label) {
    return (
      <div className="inline-flex span text-gray-400">
        <RenderMessageIcon className="w-4 h-4 mr-1" />
        {t(label)}
      </div>
    );
  }

  return null;
}

export default WhatsAppMessageOneLinePreview;
