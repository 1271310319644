import { TFunction } from "i18next";
import * as yup from "yup1";
import { FlowStepData } from "@hilos/types/flow";
import { ERROR_MESSAGES } from "src/constants/errors";
import { FlowStepAnswerSchema } from "../flow";

export const FlowStepWorkflowTaskSchema = (t: TFunction) =>
  FlowStepAnswerSchema(t).shape({
    workflow: yup.string().required(t(ERROR_MESSAGES.REQUIRED)),
    state_to_move_to: yup.string().required(t(ERROR_MESSAGES.REQUIRED)),
  });

export const FlowStepWorkflowTaskFormat = (step: FlowStepData) => {
  const nextStep = { ...step };

  if (!nextStep.workflow) {
    delete nextStep.state_to_move_to;
  }

  return nextStep;
};
