import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router";
import { Edge, Node, ReactFlowProvider } from "reactflow";
import axios from "axios";
import { FieldArray, Form, Formik, FormikProps, FormikTouched } from "formik";
import { debounce, get } from "lodash";
import { v7 as uuid } from "uuid";
import { FlowData, FlowNodeData } from "@hilos/types/flow";
import { FlowDetailRead } from "@hilos/types/private-schema";
import * as meta from "src/containers/flow/FlowMeta";
import Loading from "src/components/Loading";
import PermissionsChecker from "src/components/PermissionsCheck";
import APIErrors from "src/components/base/APIErrors";
import { getTriggerVariables } from "src/helpers/variables";
import useHilosStore from "src/hooks/useHilosStore";
import { API_ROUTES, buildAPIRoute, buildRoute } from "src/router/router";
import FlowBuilder from "./FlowBuilder";
import FlowBuilderDetails from "./FlowBuilderDetails";
import FlowBuilderTrigger from "./FlowBuilderTrigger";
import FlowHeader from "./FlowHeader";
import { getFlowData, getFlowValuesWithUpdatedData } from "./helpers/flow";
import { getFlowSchema } from "./helpers/steps";
import FlowTestModal from "./test/FlowTestModal";
import {
  getFlowErrorFromResponse,
  getInitialValues,
  getNodesFromData,
} from "./utils";

interface FlowError {
  message: string;
  data: object;
}

interface InitialFlowConfig {
  values: FlowData;
  touched: FormikTouched<FlowData>;
  nodes: Node<FlowNodeData>[];
  edges: Edge[];
}

async function fetchFlowData({ queryKey }) {
  const { data } = await axios.get(
    buildAPIRoute(API_ROUTES.FLOW_DETAIL, {
      ":id": queryKey[1],
    }),
    {
      headers: {
        "x-module": "flow",
      },
    }
  );

  return data;
}

function FlowEditor() {
  const { i18n, t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const [updating, setUpdating] = useState(false);
  const [showTestModal, setShowTestModal] = useState(false);
  const [testSubmitting, setTestSubmitting] = useState(false);
  const [hasMissingDraftVersion, setHasMissingDraftVersion] = useState(false);
  const [currentTab, setCurrentTab] = useState("details");
  const [error, setError] = useState<FlowError | null>(null);
  const [lastUpdatedAt, setLastUpdatedAt] = useState<number | null>(null);
  const [initialFlowConfig, setInitialFlowConfig] =
    useState<InitialFlowConfig | null>(null);
  const [flowSchema, setFlowSchema] = useState(getFlowSchema(t));
  const { session } = useHilosStore();
  const isPublishingRef = useRef(false);
  const isUpdatingRef = useRef(false);
  const lastDraftVersionRef = useRef(null);
  const flowFormikRef = useRef<FormikProps<FlowData>>(null);
  const updatedTimeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);
  const [testFlow, setTestFlow] = useState<FlowDetailRead | null>(null);
  const [showStepErrors, setShowStepErrors] = useState(false);

  const { data, isLoading } = useQuery(["flow_editor", id], fetchFlowData, {
    refetchOnWindowFocus: false,
    cacheTime: 0,
  });

  const handleTestModalClose = useCallback(() => {
    setShowTestModal(false);
    setTestFlow(null);
  }, []);

  const handlePublish = useCallback(
    (values, { setSubmitting, setTouched }) => {
      async function initPublishFlowData() {
        if (id) {
          try {
            const nextData = getFlowData(values);
            await axios.put(
              buildAPIRoute(API_ROUTES.FLOW_DETAIL, { ":id": id }),
              nextData,
              {
                headers: {
                  "x-module": "flow",
                },
              }
            );

            setTouched({});
            setSubmitting(false);
            navigate(buildRoute("flow-detail", { id }));
          } catch (publishError) {
            isPublishingRef.current = false;
            setSubmitting(false);
            setError(getFlowErrorFromResponse(publishError));
          }
        }
      }

      if (lastUpdatedAt) {
        setSubmitting(true);
        setLastUpdatedAt(null);

        isPublishingRef.current = true;
        setTimeout(initPublishFlowData, 400);
      }
    },
    [id, lastUpdatedAt, navigate]
  );

  const handleAutosave = useMemo(
    () =>
      debounce(
        async (values: FlowData) => {
          if (isPublishingRef.current || isUpdatingRef.current) {
            return;
          }

          if (id) {
            setUpdating(true);
            isUpdatingRef.current = true;
            const updateStartAt = +new Date();
            let updateEndAt = +new Date();

            try {
              const {
                name,
                channel,
                should_trigger_webhook,
                contact_can_run_multiple_times,
                contact_execution_multiple_type,
                contact_execution_max_frequency_quantity,
                contact_execution_max_frequency_unit,
                flow_execution_variables: flowExecutionVariables,
                ...currentDraft
              } = values;
              const formikTouchedFields = flowFormikRef.current?.touched || {};

              const nextValues = {
                name,
                channel,
                should_trigger_webhook,
                contact_can_run_multiple_times,
                contact_execution_multiple_type,
                contact_execution_max_frequency_quantity,
                contact_execution_max_frequency_unit,
                flow_execution_variables: flowExecutionVariables,
                current_draft: {
                  ...currentDraft,
                  formikTouchedFields,
                  version: lastDraftVersionRef.current || uuid(),
                },
              };

              const response = await axios.patch(
                buildAPIRoute(API_ROUTES.FLOW_AUTOSAVE, {
                  ":id": id,
                }),
                nextValues,
                {
                  headers: {
                    "x-module": "flow",
                  },
                }
              );

              lastDraftVersionRef.current = get(
                response,
                "data.current_draft.version"
              );
              updateEndAt = +new Date();

              setLastUpdatedAt(updateEndAt);
            } catch (error) {
              updateEndAt = +new Date();

              if (get(error, "response.status") === 412) {
                setHasMissingDraftVersion(true);
              }
            }

            if (updateStartAt + 1000 > updateEndAt) {
              isUpdatingRef.current = false;
              setUpdating(false);
            } else {
              if (updatedTimeoutRef.current) {
                clearTimeout(updatedTimeoutRef.current);
              }

              updatedTimeoutRef.current = setTimeout(() => {
                setUpdating(false);
                isUpdatingRef.current = false;
                updatedTimeoutRef.current = null;
              }, 1000);
            }
          }
        },
        1000,
        { leading: false, trailing: true }
      ),
    [id]
  );

  const handleTest = useCallback(
    async (formik) => {
      const nextValues = await getFlowValuesWithUpdatedData(formik.values);
      formik.setValues(nextValues, false);
      const nextErrors = await formik.validateForm(nextValues);

      if (!Object.keys(nextErrors).length) {
        isPublishingRef.current = true;
        setTestSubmitting(true);
        try {
          const nextData = getFlowData(formik.values);
          const response = await axios.put(
            buildAPIRoute(API_ROUTES.FLOW_DETAIL, { ":id": id }),
            { ...nextData, is_test: true },
            {
              headers: {
                "x-module": "flow",
              },
            }
          );

          if (response && response.data) {
            setTestFlow(response.data);
            setShowTestModal(true);
          }
        } catch (publishError) {
          setError(getFlowErrorFromResponse(publishError));
        }

        setTestSubmitting(false);
        isPublishingRef.current = false;
      } else {
        setShowStepErrors(true);
      }
    },
    [id]
  );

  const handleFlowValidate = useCallback(
    (values: FlowData) => {
      handleAutosave(values);
    },
    [handleAutosave]
  );

  useEffect(() => {
    function onLanguageChanged() {
      setFlowSchema(getFlowSchema(t));

      if (flowFormikRef.current) {
        flowFormikRef.current.validateForm();
      }
    }

    i18n.on("languageChanged", onLanguageChanged);
    return () => i18n.off("languageChanged", onLanguageChanged);
  }, [i18n, t]);

  useEffect(() => {
    if (data) {
      const loadInitialValues = async () => {
        if (data.last_updated_on) {
          setLastUpdatedAt(+new Date(data.last_updated_on));
        }
        const draft = data.current_draft;
        const isDraft = Boolean(draft);
        const allFieldsWereTouched = Boolean(
          data.needs_update || (data.upgrade_version && !data.legacy_version)
        );
        const flow = draft || data.upgrade_version || data.current_version;
        const firstStepId = flow.first_step || null;
        const triggerType =
          (draft ? draft.trigger_type : data.trigger_type) ||
          "INBOUND_SPECIFIC_MESSAGE";
        const triggerConfig =
          (draft ? draft.trigger_config : data.trigger_config) || {};

        if (isDraft) {
          lastDraftVersionRef.current = flow.version;
        }

        const flowVariableKeys: string[] = data.flow_execution_variables || [];
        const triggerVariables = getTriggerVariables(
          triggerType,
          triggerConfig
        );

        const { formikTouchedFields, initialValues } = await getInitialValues({
          data: flow,
          flowVariableKeys,
          triggerVariables,
          isDraft,
          allFieldsWereTouched,
        });

        const flowConfig = getNodesFromData({
          steps: initialValues.steps,
          triggerType: triggerType,
          firstStepId,
        });

        setInitialFlowConfig({
          ...flowConfig,
          touched: formikTouchedFields,
          values: {
            ...initialValues,
            name: data.name || "",
            channel: data.channel.id,
            trigger_type: triggerType,
            trigger_config: triggerConfig,
            flow_execution_variables: data.flow_execution_variables,
            first_step: firstStepId,
            should_trigger_webhook: data.should_trigger_webhook,
            contact_can_run_multiple_times: data.contact_can_run_multiple_times,
            contact_execution_multiple_type:
              data.contact_execution_multiple_type,
            contact_execution_max_frequency_quantity:
              data.contact_execution_max_frequency_quantity,
            contact_execution_max_frequency_unit:
              data.contact_execution_max_frequency_unit,
          },
        });
      };

      loadInitialValues();
    }
  }, [data, session]);

  if (isLoading || !initialFlowConfig) {
    return <Loading />;
  }

  if (!id) {
    return null;
  }

  if (hasMissingDraftVersion) {
    return (
      <div className="flex flex-1 h-full flex-col justify-center text-center p-4">
        <h4>
          {t(
            "flows:has-missing-draft-version.title",
            "Recent changes have been made to this flow."
          )}
        </h4>
        <p className="text-sm text-gray-500">
          {t(
            "flows:has-missing-draft-version.description",
            "Refresh this page for the most current version."
          )}
        </p>
      </div>
    );
  }

  return (
    <>
      <div className="flex flex-1 h-full flex-col justify-center text-center sm:hidden p-4">
        <h4>
          {t(
            "flows:mobile-not-available.title",
            "This page is not available on mobile devices."
          )}
        </h4>
        <p className="text-sm text-gray-500">
          {t(
            "flows:mobile-not-available.description",
            "Switch to a desktop or laptop to continue."
          )}
        </p>
      </div>
      <PermissionsChecker
        permission="change_flow"
        action={t("settings:permissions.change_flow", "update flows")}
      >
        <Formik
          innerRef={flowFormikRef}
          initialValues={initialFlowConfig.values}
          initialTouched={initialFlowConfig.touched}
          onSubmit={handlePublish}
          validate={handleFlowValidate}
          validationSchema={flowSchema}
          validateOnBlur
          validateOnMount
          validateOnChange
        >
          {(formik) => (
            <Form
              onKeyDown={(event) => {
                if (
                  event.key === "Enter" &&
                  event.target &&
                  //@ts-ignore
                  event.target.localName !== "textarea"
                ) {
                  event.preventDefault();
                }
                return true;
              }}
              onSubmit={async (event) => {
                const nextValues = await getFlowValuesWithUpdatedData(
                  formik.values
                );
                formik.setValues(nextValues, false);
                const nextErrors = await formik.validateForm(nextValues);

                if (!Object.keys(nextErrors).length) {
                  formik.handleSubmit(event);
                } else {
                  setShowStepErrors(true);
                }
                event.preventDefault();
                event.stopPropagation();
                return false;
              }}
            >
              <div className="hidden h-screen sm:flex flex-col">
                <FlowHeader
                  flowId={id}
                  flowName={formik.values.name || ""}
                  touched={formik.touched}
                  isSubmitting={formik.isSubmitting}
                  updating={updating}
                  lastUpdatedAt={lastUpdatedAt}
                  currentTab={currentTab}
                  setCurrentTab={setCurrentTab}
                  isTestSubmitting={testSubmitting}
                  handleTest={() => handleTest(formik)}
                />
                {error && (
                  <APIErrors
                    APIError={error.message}
                    APIValidationErrors={error.data}
                    fieldTranslations={meta.FIELDS}
                  />
                )}
                {currentTab === "details" && <FlowBuilderDetails />}
                {currentTab === "trigger" && <FlowBuilderTrigger />}
                {currentTab === "steps" && (
                  <FieldArray name="steps">
                    {(helpers) => (
                      <ReactFlowProvider>
                        <FlowBuilder
                          showStepErrors={showStepErrors}
                          helpers={helpers}
                          initialNodes={initialFlowConfig.nodes}
                          initialEdges={initialFlowConfig.edges}
                        />
                      </ReactFlowProvider>
                    )}
                  </FieldArray>
                )}
                {testFlow && session?.account && (
                  <FlowTestModal
                    show={showTestModal}
                    onClose={handleTestModalClose}
                    flow={testFlow}
                  />
                )}
              </div>
            </Form>
          )}
        </Formik>
      </PermissionsChecker>
    </>
  );
}

export default FlowEditor;
