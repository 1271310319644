import { useState } from "react";
import { useTranslation } from "react-i18next";
import { CheckCircleIcon, DownloadIcon } from "@heroicons/react/outline";
import axios from "axios";
import { usePostHog } from "posthog-js/react";
import { toast } from "sonner";
import { FlowVersionSimpleRead } from "@hilos/types/private-schema";
import StateButton from "src/components/StateButton";
import useCleanTimeout from "src/hooks/useCleanTimeout";
import { API_ROUTES, buildAPIRoute } from "../../../router/router";

interface FlowVersionResultDownloadProps {
  flowVersion: Omit<
    FlowVersionSimpleRead,
    "first_step" | "steps" | "published_by" | "num_contacts" | "completed"
  >;
  flowExecutionId?: string;
}

export default function FlowVersionResultDownload({
  flowVersion,
  flowExecutionId,
}: FlowVersionResultDownloadProps) {
  const { t } = useTranslation();
  const posthog = usePostHog();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const cleanTimeout = useCleanTimeout();

  const downloadResults = async () => {
    setLoading(true);
    posthog?.capture("flow_execution results download", {
      flow_execution_id: flowExecutionId,
      flow_version_id: flowVersion.id,
      flow_id: flowVersion.flow.id,
      flow_name: flowVersion.flow.name,
    });
    try {
      await axios.get(
        buildAPIRoute(API_ROUTES.FLOW_VERSION_RESULT_DOWNLOAD, {
          ":id": flowVersion.id,
        }),
        {
          params: { flow_execution: flowExecutionId },
        }
      );
      setSuccess(true);
      toast(
        <button className="flex items-center">
          <div>
            <CheckCircleIcon className="w-4 h-4 text-green-600 mr-2" />
          </div>
          <div className="text-left">
            <p className="text-sm font-bold text-gray-900">
              {t(
                "download-request-notification-title",
                "Results are being prepped for download."
              )}
            </p>
            <p className="text-xs text-gray-400">
              {t(
                "download-request-notification-description",
                "Once the file is ready you'll receive a notification to download the results."
              )}
            </p>
          </div>
        </button>,
        {
          duration: 1000000,
        }
      );
    } catch (err) {
      console.log(err);
      setSuccess(false);
    } finally {
      setLoading(false);
      setSubmitted(true);
      cleanTimeout.current = setTimeout(() => {
        setSubmitted(false);
      }, 1000 * 2);
    }
  };

  return (
    <StateButton
      btnClasses="inline-flex items-center rounded-md border border-transparent bg-blue-100 px-3 py-2 text-sm font-medium leading-4 text-blue-700 hover:bg-blue-200 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
      isSubmitting={loading}
      submitted={submitted}
      success={success}
      disabled={loading}
      submittingText={t("download-request-loading", "Requesting download...")}
      successText={t(
        "download-request-success",
        "Download request successful!"
      )}
      initialText={
        <>
          <DownloadIcon className="mr-1 h-5 w-5" aria-hidden="true" />
          {t(
            "flows:flow_version.results-download.default-button-text",
            "Download results"
          )}
        </>
      }
      buttonType="button"
      onClick={downloadResults}
    />
  );
}
