import { useCallback } from "react";
import { useQuery } from "react-query";
import axios from "axios";
import { ContactTag } from "@hilos/types/private-schema";
import { API_ROUTES, buildAPIRoute } from "../router/router";

function useContactTag(id: string) {
  const fetchContactTag = useCallback(async () => {
    const { data } = await axios.get<ContactTag>(
      buildAPIRoute(API_ROUTES.CONTACT_TAG_DETAIL, {
        ":id": id,
      }),
      {
        headers: {
          "x-module": "contact-tag",
        },
      }
    );
    return data;
  }, [id]);

  const { data } = useQuery(["contact_tag_detail", id], fetchContactTag);

  return data;
}

export default useContactTag;
