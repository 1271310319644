import { ExclamationCircleIcon } from "@heroicons/react/outline";
import { useField } from "formik";
import useTouchField from "src/containers/flow/builder/hooks/useTouchField";
import { classNames } from "../../Helpers";
import FieldContainer from "./FieldContainer";

export interface ColorInputFieldProps
  extends Omit<React.HTMLProps<HTMLInputElement>, "type"> {
  name: string;
  label?: string;
  help?: string | React.ReactElement;
  optional?: boolean;
  className?: string;
  containerClassName?: string;
  innerRef?: React.Ref<HTMLInputElement>;
}

function ColorInputField({
  label,
  name,
  help,
  optional,
  className,
  containerClassName,
  disabled,
  innerRef,
  ...props
}: ColorInputFieldProps) {
  const [field, meta, helpers] = useField({ name });
  const error = meta.touched && meta.error;

  useTouchField(helpers);

  return (
    <FieldContainer
      name={name}
      label={label}
      help={help}
      optional={optional}
      error={error}
    >
      <div className={classNames(label && "mt-1", "flex")}>
        <div
          className={classNames(
            "relative flex flex-grow items-stretch border border-gray-300 focus-within:z-10",
            "focus-within:border-indigo-500 focus-within:outline-none focus-within:ring-1 focus-within:ring-indigo-500",
            "rounded-md",
            containerClassName,
            !disabled &&
              error &&
              "border-red-500 focus-within:border-red-500 focus-within:ring-red-500",
            disabled && "border-gray-200 shadow-none"
          )}
        >
          <input
            id={name}
            type="color"
            className={classNames(
              "w-full h-10 border-none placeholder-gray-400 sm:text-sm",
              "focus:border-0 focus:outline-none focus:ring-0",
              "disabled:bg-gray-50 disabled:text-gray-300",
              "rounded-md",
              error && "pr-10",
              className
            )}
            ref={innerRef}
            disabled={disabled}
            {...field}
            {...props}
          />
          {error && (
            <div className="pointer-events-none absolute inset-y-0 right-0 z-10 flex items-center pr-3">
              <ExclamationCircleIcon
                className="h-5 w-5 text-red-500"
                aria-hidden="true"
              />
            </div>
          )}
        </div>
      </div>
    </FieldContainer>
  );
}

export default ColorInputField;
