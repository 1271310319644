import { useTranslation } from "react-i18next";
import SelectorField from "src/components/Form/SelectorField";
import useWorkflowStates from "src/hooks/useWorkflowStates";
import useWorkflows from "src/hooks/useWorkflows";
import { useStepField } from "../../../hooks/useStepField";

interface FlowBuilderStepWorkflowTaskProps {
  index: number;
}

function FlowBuilderStepWorkflowTask({
  index,
}: FlowBuilderStepWorkflowTaskProps) {
  const { t } = useTranslation();
  const [workflow, _] = useStepField({
    index,
    name: "workflow",
  });
  const [_2, setWorkflowState] = useStepField({
    index,
    name: "state_to_move_to",
  });
  const workflows = useWorkflows();
  const states = useWorkflowStates(workflow);

  return (
    <div className="space-y-4">
      <SelectorField
        name={`steps.${index}.workflow`}
        options={workflows.results.map((workflow) => ({
          label: workflow.name,
          value: workflow.id,
        }))}
        label={t("flows:steps.workflow-task.workflow.label", "Workflow")}
        help={t(
          "flows:steps.workflow-task.workflow.help",
          "Select the workflow you'd like to create or update a task in."
        )}
        placeholder={t("type-to-search")}
        onSelect={() => setWorkflowState("")}
      />

      {workflow && (
        <SelectorField
          name={`steps.${index}.state_to_move_to`}
          options={states.map((state) => ({
            label: state.name,
            value: state.id,
          }))}
          label={t(
            "flows:steps.workflow-task.state-to-move-to.label",
            "State to set this task to"
          )}
          help={t(
            "flows:steps.workflow-task.state-to-move-to.help",
            "If no tasks exist for this contact in this workflow, we'll create one, otherwise we'll move the existing task to this state."
          )}
          placeholder={t(
            "flows:steps.workflow-task.state-to-move-to.placeholder",
            "Type to search states"
          )}
        />
      )}
    </div>
  );
}
export default FlowBuilderStepWorkflowTask;
