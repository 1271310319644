import { Trans, useTranslation } from "react-i18next";
import { format } from "date-fns";
import { WalletPayment } from "@hilos/types/private-schema";
import Table from "src/components/Table/Table";
import { formatPrice } from "src/Helpers";
import { API_ROUTES, buildAPIRoute } from "src/router/router";
import { PaymentMethodDescription } from "./PaymentMethodDescription";

export default function WalletPaymentHistory() {
  const { t } = useTranslation();

  return (
    <div className="min-h-screen bg-gray-50">
      <div className="h-full sm:px-6 sm:pt-8 lg:px-8">
        <Table
          baseURL={buildAPIRoute(API_ROUTES.WALLET_PAYMENT_LIST)}
          paginated={true}
          tableHead={
            <thead className="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  className="pl-4 py-3 text-left text-tiny font-medium uppercase tracking-wider text-gray-500"
                >
                  {t("reference", "Reference")}
                </th>
                <th
                  scope="col"
                  className="px-4 py-3 text-left text-tiny font-medium uppercase tracking-wider text-gray-500"
                >
                  {t("date")}
                </th>
                <th
                  scope="col"
                  className="px-4 py-3 text-left text-tiny font-medium uppercase tracking-wider text-gray-500"
                >
                  {t("payment-amount", "Payment amount")}
                </th>
                {/* <th
                  scope="col"
                  className="px-4 py-3 text-left text-tiny font-medium uppercase tracking-wider text-gray-500"
                >
                  {t("hilos-fees", "Hilos fees")}
                </th> */}
                <th
                  scope="col"
                  className="px-4 py-3 text-left text-tiny font-medium uppercase tracking-wider text-gray-500"
                >
                  {t("credited-amount", "Credited amount")}
                </th>
                <th
                  scope="col"
                  className="pr-4 py-3 text-left text-tiny font-medium uppercase tracking-wider text-gray-500"
                >
                  {t("payment-method", "Payment method")}
                </th>
              </tr>
            </thead>
          }
          tableRow={(wallet_payment: WalletPayment) => (
            <>
              <td className="whitespace-nowrap pl-4 py-3 text-sm font-medium text-gray-900">
                {wallet_payment.id}
              </td>
              <td className="whitespace-nowrap px-4 py-3 text-sm text-gray-600">
                {format(
                  new Date(wallet_payment.created_on),
                  "d/MMM/yy HH:mm:ss"
                )}
              </td>
              <td className="whitespace-nowrap px-4 py-3 text-sm text-gray-600">
                <span className="font-semibold">
                  {formatPrice(wallet_payment.payment_amount)}
                </span>{" "}
                <span className="text-xs">USD</span>
              </td>
              {/* <td className="whitespace-nowrap px-4 py-3 text-sm text-gray-600">
                <span className="font-semibold">
                  {formatPrice(wallet_payment.hilos_fees)}
                </span>{" "}
                <span className="text-xs">USD</span>
              </td> */}
              <td className="whitespace-nowrap px-4 py-3 text-sm text-gray-600">
                <span className="font-semibold">
                  {formatPrice(wallet_payment.credited_amount)}
                </span>{" "}
                <span className="text-xs">USD</span>
              </td>
              <td className="whitespace-nowrap pr-4 py-3 text-sm text-blue-500">
                {wallet_payment.payment_intent ? (
                  <PaymentMethodDescription
                    paymentMethod={wallet_payment.payment_intent.payment_method}
                  />
                ) : (
                  "-"
                )}
              </td>
            </>
          )}
          dataName={t("settings:wallet-payments", "Credit recharge")}
          noResults={
            <>
              <Trans i18nKey="settings:wallet-payment-history.no-invoices">
                <h4>No payments found.</h4>
              </Trans>
            </>
          }
          createObject={
            <>
              <Trans i18nKey="settings:wallet-payment-history.no-invoices">
                <h4>No payments found.</h4>
              </Trans>
            </>
          }
        />
      </div>
    </div>
  );
}
