import React from "react";
import {
  ConversationContent,
  WhatsAppMessage,
} from "@hilos/types/private-schema";
import MessageRender from "../../../components/MessageRender";
import { HandleMountConversationContent } from "./Conversation";
import ConversationEventFn from "./ConversationEvent";
import ConversationEventContainer from "./ConversationEventContainer";
import ConversationMessageContainer from "./ConversationMessageContainer";

interface ConversationContentItemProps {
  content: ConversationContent & { submitting?: boolean };
  inboxContactId: string;
  isFocused: boolean;
  showDetails: boolean;
  showConversationEvents: boolean;
  allowReplyTo: boolean;
  onSelectMedia: (msg: WhatsAppMessage) => void;
  onFocusMessage: (messageId: string) => Promise<void>;
  onSelectContextContent: (content: ConversationContent) => void;
  onSelectForwardMessage: (message: WhatsAppMessage) => void;
  onMountConversationContent: HandleMountConversationContent;
}
const ELEMENT_TYPES = {
  EVENT: "EVENT",
  MESSAGE: "MESSAGE",
};

function ConversationContentItem({
  content,
  inboxContactId,
  isFocused,
  showDetails,
  showConversationEvents,
  allowReplyTo,
  onSelectMedia,
  onFocusMessage,
  onSelectContextContent,
  onSelectForwardMessage,
  onMountConversationContent,
}: ConversationContentItemProps) {
  if (content.content_type === ELEMENT_TYPES.MESSAGE && content.message) {
    return (
      <ConversationMessageContainer
        content={content}
        message={content.message}
        inboxContactId={inboxContactId}
        allowReplyTo={allowReplyTo}
        showDetails={showDetails}
        showConversationEvents={showConversationEvents}
        isFocused={isFocused}
        isSubmitting={content.submitting}
        onFocusMessage={onFocusMessage}
        onSelectContextContent={onSelectContextContent}
        onSelectForwardMessage={onSelectForwardMessage}
        onMountConversationContent={onMountConversationContent}
      >
        <MessageRender
          message={content.message}
          onSelectMedia={onSelectMedia}
        />
      </ConversationMessageContainer>
    );
  }

  if (content.content_type === ELEMENT_TYPES.EVENT && content.event) {
    return (
      <ConversationEventContainer>
        <ConversationEventFn
          event={content.event}
          timestamp={content.event.timestamp}
        />
      </ConversationEventContainer>
    );
  }

  return null;
}

export default React.memo(
  ConversationContentItem,
  (prevProps, nextProps) =>
    prevProps.content === nextProps.content &&
    prevProps.isFocused === nextProps.isFocused &&
    prevProps.showDetails === nextProps.showDetails &&
    prevProps.showConversationEvents === nextProps.showConversationEvents &&
    prevProps.onSelectMedia === nextProps.onSelectMedia &&
    prevProps.onMountConversationContent ===
      nextProps.onMountConversationContent
);
