import { Fragment, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dialog, Transition } from "@headlessui/react";
import {
  BookOpenIcon,
  QuestionMarkCircleIcon,
  XIcon,
} from "@heroicons/react/outline";
import { MODULE_HELP } from "./OnboardingModuleMeta";

interface OnboardingModuleModalProps {
  routeName: string;
  show: boolean;
  handleClose: () => void;
}

interface ModuleInfo {
  title: string[];
  modal_title: string[];
  modal_icon: JSX.Element;
  content: JSX.Element;
  image: string;
  href: string;
}

export default function OnboardingModuleModal({
  routeName,
  show,
  handleClose,
}: OnboardingModuleModalProps) {
  const { t } = useTranslation();

  const moduleInfo: ModuleInfo = useMemo(() => {
    return MODULE_HELP[routeName];
  }, [routeName]);

  return (
    <>
      <Transition.Root show={show} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto"
          onClose={handleClose}
        >
          <div className="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:h-screen sm:align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="relative inline-block transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-3xl sm:p-6 sm:align-middle">
                <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    onClick={handleClose}
                  >
                    <span className="sr-only">Close</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className=" flex flex-col gap-2 justify-center text-gray-800 items-center font-bold">
                  <div className="h-10 w-10 rounded-full flex items-center justify-center text-blue-400 mr-4 bg-blue-50">
                    {moduleInfo.modal_icon}
                  </div>
                  <div className="text-2xl">{t(moduleInfo.modal_title)}</div>
                  <hr className="border-t border-gray-200 mt-4 w-full" />
                </div>
                <div className="">
                  <div className="mx-auto mt-6 items-center grid grid-cols-3">
                    <div>
                      {
                        <img
                          src={moduleInfo.image}
                          alt="module"
                          className="w-80"
                        />
                      }
                    </div>
                    <div className="col-span-2 px-4">
                      <p className="text-lg font-bold text-gray-800 mb-4">
                        {t(moduleInfo.title)}
                      </p>
                      <p>{moduleInfo.content}</p>
                    </div>
                  </div>
                </div>
                <div className="w-full mt-10 flex">
                  <div className="bg-blue-gray-light flex items-center rounded-md px-4 py-4">
                    <div className="flex justify-center items-center p-2 bg-white rounded-full mr-4">
                      <QuestionMarkCircleIcon className="h-4 w-4 text-gray-500" />
                    </div>
                    <div>
                      <p className="font-semibold mb-1 text-gray-800">
                        {t("onboarding:module.help.title", "Any questions?")}
                      </p>
                      <p className="text-xs text-gray-500">
                        {t(
                          "onboarding:module.help.subtitle",
                          "For more information about this module, visit the documentation. If you still have questions, you can contact us via WhatsApp."
                        )}
                      </p>
                    </div>
                    <div className="ml-2">
                      <a
                        href={moduleInfo.href}
                        target="_blank"
                        rel="noreferrer"
                        className="mb-2"
                      >
                        <div className="rounded-sm mb-2 text-gray-500 bg-white px-3 py-1 flex items-center  justify-between ">
                          <h3 className="text-xs mr-2 text-left flex items-center">
                            {t(
                              "onboarding:module.documentation",
                              "Documentation"
                            )}
                          </h3>
                          <BookOpenIcon className="h-4 w-4 " />
                        </div>
                      </a>
                      <a
                        href={moduleInfo.href}
                        key={moduleInfo.href}
                        target="_blank"
                        rel="noreferrer"
                        className=" mt-2"
                      >
                        <div className="rounded-sm text-green-700 bg-white px-3 py-1 flex items-center  justify-between ">
                          <h3 className="text-xs mr-2 text-left flex items-center">
                            {t("onboarding:module.contact-us", "Contact us")}
                          </h3>
                          <FontAwesomeIcon
                            icon={faWhatsapp}
                            className="w-3 h-3"
                          />
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
