import { useCallback } from "react";
import { useQuery } from "react-query";
import axios from "axios";
import { WebhookSubscription } from "@hilos/types/private-schema";
import { API_ROUTES, buildAPIRoute } from "../router/router";

function useWebhookSubscription(id: string) {
  const fetchWebhookSubscription = useCallback(async () => {
    const { data } = await axios.get<WebhookSubscription>(
      buildAPIRoute(API_ROUTES.WEBHOOK_SUBSCRIPTION_DETAIL, {
        ":id": id,
      }),
      {
        headers: {
          "x-module": "webhook",
        },
      }
    );
    return data;
  }, [id]);

  const { data } = useQuery(
    ["webhook_subscription_detail", id],
    fetchWebhookSubscription
  );

  return data;
}

export default useWebhookSubscription;
