import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import amexLogo from "../../../assets/img/payment-methods/amex.svg";
import mastercardLogo from "../../../assets/img/payment-methods/mastercard.svg";
import visaLogo from "../../../assets/img/payment-methods/visa.svg";

export const PaymentMethodDescription = ({ paymentMethod }) => {
  const { t } = useTranslation();

  return (
    <>
      {paymentMethod.payment_method_type === "card" ? (
        <>
          <span className="flex items-center text-sm text-gray-500">
            {paymentMethod.card_brand === "visa" && (
              <img
                src={visaLogo}
                className="mr-2 h-5 w-5 self-center"
                alt={`${paymentMethod.card_brand} logo`}
              />
            )}
            {paymentMethod.card_brand === "mastercard" && (
              <img
                src={mastercardLogo}
                className="mr-2 h-5 w-5 self-center"
                alt={`${paymentMethod.card_brand} logo`}
              />
            )}
            {paymentMethod.card_brand === "amex" && (
              <img
                src={amexLogo}
                className="mr-2 h-5 w-5 self-center"
                alt={`${paymentMethod.card_brand} logo`}
              />
            )}
            <span className="text-sm font-medium text-gray-900">
              {paymentMethod.card_last4}
            </span>
          </span>
          <span className="text-sm text-gray-500 mr-4">
            {t("expires-on", "Expires on")} {paymentMethod.card_exp_month}/
            {format(
              new Date(parseInt(paymentMethod.card_exp_year), 1, 1),
              "yy"
            )}
          </span>
        </>
      ) : (
        <span className="text-sm text-gray-500">
          {paymentMethod.payment_method_type}
        </span>
      )}
    </>
  );
};
