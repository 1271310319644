import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { PencilIcon, TrashIcon } from "@heroicons/react/outline";
import { ColumnDef } from "@tanstack/react-table";
import { DateTime } from "luxon";
import { ChannelBadgeById } from "src/containers/config/channel/ChannelBadge";
import Button from "src/components/Button";
import { DataTable } from "src/components/DataTable/DataTable";
import DeleteObject, { onDeleteFn } from "src/components/DeleteObject";
import { buildRoute } from "src/router/router";

interface Widget {
  id: string;
  name: string;
  channel: number;
  created_on: string;
}

interface WidgetListProps {
  data: {
    results: Widget[];
    count: number;
  };
  isLoading: boolean;
  isSuccess: boolean;
  onDelete: onDeleteFn<Widget>;
  deleteSuccess: boolean;
  deleteSubmitted: boolean;
}

export function WidgetList({
  data,
  isLoading,
  isSuccess,
  onDelete,
  deleteSuccess,
  deleteSubmitted,
}: WidgetListProps) {
  const { t } = useTranslation();

  const columns: ColumnDef<Widget>[] = [
    {
      accessorKey: "name",
      header: t("widget:name", "Name"),
      cell: ({ row }) => (
        <Link
          to={`/integrations/wa-widget/${row.original.id}/update`}
          className="text-blue-600 hover:text-blue-800"
        >
          {row.original.name}
        </Link>
      ),
    },
    {
      accessorKey: "channel",
      header: t("widget:channel", "Channel"),
      cell: ({ row }) => (
        <ChannelBadgeById id={row.original.channel.toString()} />
      ),
    },
    {
      accessorKey: "created_on",
      header: t("widget:created-on", "Created on"),
      cell: ({ row }) => (
        <time dateTime={row.original.created_on}>
          {DateTime.fromISO(row.original.created_on).toLocaleString(
            DateTime.DATETIME_SHORT
          )}
        </time>
      ),
    },
    {
      id: "actions",
      header: t("widget:actions", "Actions"),
      cell: ({ row }) => (
        <div className="flex space-x-2">
          <Link
            to={buildRoute("wa-widget-edit", { id: row.original.id })}
            className="focus:ring-hilos-500 mr-2 inline-flex items-center rounded-md border border-gray-300 bg-white px-3 py-2 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100"
          >
            <PencilIcon className="h-5 w-5" />
            {t("update")}
          </Link>
          <DeleteObject
            obj={row.original}
            objDescription={row.original.name}
            actionDescription={t(
              "widget:delete-description",
              "This will remove the widget from your website. This action cannot be undone."
            )}
            onDelete={onDelete}
            deleteSuccess={deleteSuccess}
            deleteSubmitted={deleteSubmitted}
          />
        </div>
      ),
    },
  ];

  return (
    <div className="space-y-4">
      <div className="flex justify-between items-center">
        <h2 className="text-lg font-medium">
          {t("widget:list-title", "WhatsApp Widgets")}
        </h2>
        <Link to="/integrations/wa-widget/create">
          <Button>{t("widget:create-new", "Create New Widget")}</Button>
        </Link>
      </div>
      <DataTable
        columns={columns}
        data={data}
        isLoading={isLoading}
        isSuccess={isSuccess}
      />
    </div>
  );
}
