import { useCallback } from "react";
import { useQuery } from "react-query";
import axios from "axios";
import { WorkflowRead } from "@hilos/types/private-schema";
import { API_ROUTES, buildAPIRoute } from "../router/router";

function useWorkflow(id: string | null) {
  const fetchWorkflow = useCallback(async () => {
    if (!id) return undefined;

    const { data } = await axios.get<WorkflowRead>(
      buildAPIRoute(API_ROUTES.WORKFLOW_DETAIL, { ":id": id })
    );
    return data;
  }, [id]);

  const { data } = useQuery(["workflow_detail", id], fetchWorkflow);

  return data;
}

export default useWorkflow;
