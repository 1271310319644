import { useTranslation } from "react-i18next";
import { FlowExecutionStepReadDetail } from "@hilos/types/private-schema";
import useWorkflowState from "src/hooks/useWorkFlowState";
import useWorkflow from "src/hooks/useWorkflow";

interface FlowExecutionStepWorkflowTaskDetailProps {
  executionStep: FlowExecutionStepReadDetail;
}

export default function FlowExecutionStepWorkflowTaskDetail({
  executionStep,
}: FlowExecutionStepWorkflowTaskDetailProps) {
  const { t } = useTranslation();
  const workflow = useWorkflow(executionStep.step.workflow);
  const state = useWorkflowState(executionStep.step.state_to_move_to);

  console.log(executionStep.step.workflow, executionStep.step.state_to_move_to);
  if (!workflow || !state) {
    return null;
  }

  return (
    <div>
      <h3 className="text-tiny font-medium uppercase tracking-wider text-gray-500">
        {t(
          "flow-executions:execution-steps-detail.workflow-task",
          "Workflow task"
        )}
      </h3>

      {executionStep.execution_result.created && (
        <p className="text-gray-600">
          {t(
            "flow-executions:execution-steps-detail.workflow-task-created",
            "Created a new {{custom_name_for_task}} in {{workflow_name}} in state {{state_name}}.",
            {
              custom_name_for_task: workflow.custom_name_for_task,
              workflow_name: workflow.name,
              state_name: state.name,
            }
          )}
        </p>
      )}
      {executionStep.execution_result.moved && (
        <p className="text-gray-600">
          {t(
            "flow-executions:execution-steps-detail.workflow-task-moved",
            "Moved {{custom_name_for_task}} in {{workflow_name}} to {{state_name}}.",
            {
              custom_name_for_task: workflow.custom_name_for_task,
              workflow_name: workflow.name,
              state_name: state.name,
            }
          )}
        </p>
      )}
      {!executionStep.execution_result.created &&
        !executionStep.execution_result.moved && (
          <p className="text-gray-600">
            {t(
              "flow-executions:execution-steps-detail.workflow-task-neither",
              "Found an existing {{custom_name_for_task}} in {{workflow_name}} in {{state_name}}, no action taken.",
              {
                custom_name_for_task: workflow.custom_name_for_task,
                workflow_name: workflow.name,
                state_name: state.name,
              }
            )}
          </p>
        )}
    </div>
  );
}
