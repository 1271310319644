import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, Outlet, useMatch, useSearchParams } from "react-router-dom";
import { ClockIcon } from "@heroicons/react/outline";
import Loading from "src/components/Loading";
import AccountStatusNotice from "src/components/Notice/AccountStatusNotice";
import PermissionsChecker from "src/components/PermissionsCheck";
import { getInboxParamsFromView } from "src/helpers/inbox";
import { useChannelAvailability } from "src/hooks/useChannel";
import useHilosStore from "src/hooks/useHilosStore";
import useInboxContactViews, {
  InboxContactViewData,
} from "src/hooks/useInboxContactViews";
import useInboxContacts from "src/hooks/useInboxContacts";
import usePageVisibility from "src/hooks/usePageVisibility";
import useScheduledMessages from "src/hooks/useScheduledMessages";
import OnboardingModuleModal from "../onboarding_module/OnboardingModuleModal";
import BrowserNotificationStatus from "./BrowserNotificationStatus";
import ConversationList from "./ConversationList";
import ConversationListHeader from "./ConversationListHeader";
import ConversationListInboxParams from "./ConversationListInboxParams";
import ConversationListView from "./ConversationListView";
import NewConversationModal from "./NewConversationModal";
import PinnedViews from "./PinnedViews";

function Inbox() {
  const { t } = useTranslation();
  const isFocused = useMatch("/inbox");
  const isFocusedConversations = useMatch("/inbox/conversation");
  const [showViews, setShowViews] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [showNewConversation, setShowNewConversation] = useState(false);
  const { session, inboxParams, setInboxParams, updateSession } =
    useHilosStore();
  const [search, setSearch] = useState(() => inboxParams.search || "");
  const { count: countScheduledMessages } = useScheduledMessages();
  const { isVisible: isPageVisible } = usePageVisibility();
  const [pinnedViews, setPinnedViews] = useState<InboxContactViewData[]>([]);
  const {
    inboxContactViews,
    refetch: refetchViews,
    onGetView,
  } = useInboxContactViews({
    showViews,
  });

  const {
    data: currentAvailableChannels,
    isLoading: isLoadingAvailableChannels,
  } = useChannelAvailability();

  const {
    data,
    count,
    loading,
    updating,
    fetching,
    loadingCounter,
    hasMoreData,
    orderByField,
    inboxContactId,
    allowQueryCountWithLimit,
    setIsUpdatingFilters,
    handleFetchMore,
    handleInboxParams,
    handleChangeFilters,
    handleInboxContactCursor,
    handleDisableQueryCountWithLimit,
  } = useInboxContacts({
    session,
    inboxParams,
    setInboxParams,
    isPageVisible,
    currentAvailableChannels,
    isLoadingAvailableChannels,
  });

  const [searchParams] = useSearchParams();
  const [showHelpModal, setShowHelpModal] = useState<boolean>(false);

  const handleCloseNewConversation = useCallback(() => {
    setShowNewConversation(false);
  }, []);

  const handleShowNewConversation = useCallback(() => {
    setShowNewConversation(true);
  }, []);

  const handleSwitchShowFilters = useCallback(() => {
    if (!showFilters) {
      setShowViews(false);
    }
    setShowFilters(!showFilters);
  }, [showFilters]);

  const handleCloseFilters = useCallback(() => {
    setShowFilters(false);
  }, []);

  const handleCloseHelpModal = useCallback(() => {
    if (!session?.visited_modules.includes("inbox")) {
      updateSession({
        visited_modules: [...(session?.visited_modules || []), "inbox"],
      });
    }
    setShowHelpModal(false);
  }, [session?.visited_modules, updateSession]);

  const pinnedViewsMap = useMemo(() => {
    if (!session?.inbox_contact_views_pinned || !inboxContactViews)
      return new Map();
    const newPinnedViewsMap = new Map(
      inboxContactViews
        .filter((view) => session.inbox_contact_views_pinned?.includes(view.id))
        .map((view) => [view.id, view])
    );
    return newPinnedViewsMap;
  }, [session?.inbox_contact_views_pinned, inboxContactViews]);

  useEffect(() => {
    setPinnedViews(Array.from(pinnedViewsMap.values()));
  }, [pinnedViewsMap]);

  const handleSelectView = useCallback(
    async (id: string) => {
      if (inboxParams.view && inboxParams.view.id === id) {
        handleChangeFilters({
          filters: [],
          ordering: "-last_message_on",
          view: null,
        });
      } else {
        const view = await onGetView(id);
        if (view) {
          try {
            const fullView = {
              ...view,
              conversations_count:
                inboxContactViews.find((v) => v.id === id)
                  ?.conversations_count || 0,
            };
            handleChangeFilters({
              ...getInboxParamsFromView(view),
              view: fullView,
            });
            setShowViews(false);
          } catch (error) {
            console.error("Failed to get view:", error);
          }
        }
      }
    },
    [
      inboxParams.view,
      handleChangeFilters,
      onGetView,
      setShowViews,
      inboxContactViews,
    ]
  );

  useEffect(() => {
    if (showViews) {
      setShowFilters(false);
    }
  }, [showViews]);

  useEffect(() => {
    refetchViews();
  }, [data, refetchViews]);

  useEffect(() => {
    const showFilters = searchParams.get("show_filters");
    if (showFilters) {
      setShowFilters(true);
    }
  }, [searchParams]);

  useEffect(() => {
    setShowHelpModal(
      session ? !session.visited_modules.includes("inbox") : false
    );
  }, [session]);

  if (isLoadingAvailableChannels || loading) {
    return <Loading />;
  }

  return (
    <div
      className="h-full min-h-0 border-b border-r border-gray-200"
      data-tour="inbox-window"
    >
      <div className="relative flex h-full flex-col">
        <div className="shrink-0">
          <AccountStatusNotice />
        </div>
        <PermissionsChecker
          permission="view_inboxcontact"
          action={t("settings:permissions.view-inboxcontact", "use the Inbox")}
        >
          <div className="flex h-full flex-1 overflow-y-auto">
            <aside
              className={`flex ${
                isFocused || isFocusedConversations
                  ? "flex-1 shrink-0 overflow-hidden md:flex-none"
                  : "hidden md:block"
              }`}
            >
              <div className="relative flex h-full w-full flex-col overflow-y-auto border-gray-200 bg-gray-100 md:w-72 md:border-r xl:w-80">
                <div className="shrink-0" data-tour="convo-list">
                  <ConversationListHeader
                    search={search}
                    inboxParams={inboxParams}
                    isPageVisible={isPageVisible}
                    totalConversations={count}
                    totalConversationsLoading={loadingCounter || updating}
                    allowQueryCountWithLimit={allowQueryCountWithLimit}
                    setSearch={setSearch}
                    setIsUpdatingFilters={setIsUpdatingFilters}
                    onInboxParams={handleInboxParams}
                    onSwitchShowFilters={handleSwitchShowFilters}
                    onShowNewConversation={handleShowNewConversation}
                    onChangeFilters={handleChangeFilters}
                    onDisableQueryCountWithLimit={
                      handleDisableQueryCountWithLimit
                    }
                    showViews={showViews}
                    currentView={inboxParams.view}
                    onToggleViews={() => setShowViews(!showViews)}
                  />
                  <PinnedViews
                    currentView={inboxParams.view}
                    views={pinnedViews}
                    currentViewId={inboxParams.view?.id ?? null}
                    onSelectView={handleSelectView}
                  />
                  {showViews && (
                    <ConversationListView
                      inboxParams={inboxParams}
                      showViews={showViews}
                      setShowViews={setShowViews}
                      currentAvailableChannels={currentAvailableChannels}
                      allowQueryCountWithLimit={allowQueryCountWithLimit}
                      onCloseFilters={handleCloseFilters}
                      onChangeFilters={handleChangeFilters}
                    />
                  )}
                  <ConversationListInboxParams
                    view={inboxParams.view}
                    filters={inboxParams.filters}
                    ordering={inboxParams.ordering}
                    showFilters={showFilters}
                    onCloseFilters={handleCloseFilters}
                    onChangeFilters={handleChangeFilters}
                  />
                </div>
                <ConversationList
                  data={data}
                  search={search}
                  updating={updating}
                  fetching={fetching}
                  hasMoreData={hasMoreData}
                  inboxParams={inboxParams}
                  orderByField={orderByField}
                  currentInboxContactId={inboxContactId}
                  totalConversations={count}
                  currentAvailableChannels={currentAvailableChannels}
                  onFetchMore={handleFetchMore}
                  onInboxContactCursor={handleInboxContactCursor}
                  onShowNewConversation={handleShowNewConversation}
                />
                <div>
                  {countScheduledMessages > 0 && (
                    <div className="bg-blue-100 px-4 py-2 text-xs text-blue-800">
                      <Link
                        to="/inbox/scheduled-messages"
                        className=" ml-1 flex font-medium"
                      >
                        <ClockIcon className="mr-2 h-4 w-4" />
                        {t(
                          "inbox:scheduled-message.see-all",
                          "See all scheduled messages"
                        )}{" "}
                        <span className="ml-1 font-bold">
                          ({countScheduledMessages})
                        </span>
                      </Link>
                    </div>
                  )}
                </div>
                <div className="border-t-1 grow-0 border-gray-300">
                  <BrowserNotificationStatus />
                </div>
                <NewConversationModal
                  show={showNewConversation}
                  onClose={handleCloseNewConversation}
                />
              </div>
            </aside>
            <Outlet />
          </div>
        </PermissionsChecker>
        <OnboardingModuleModal
          routeName="inbox"
          show={showHelpModal}
          handleClose={handleCloseHelpModal}
        />
      </div>
    </div>
  );
}

export default Inbox;
