import { Trans, useTranslation } from "react-i18next";
import {
  ChartPieIcon,
  ClipboardListIcon,
  CogIcon,
} from "@heroicons/react/outline";
import PermissionsChecker from "src/components/PermissionsCheck";
import Tabs from "src/components/Tabs";
import WhatsAppIcon from "src/components/icons/WhatsAppIcon";
import HeaderNavigation from "../../components/HeaderNavigation";
import useHilosStore from "../../hooks/useHilosStore";
import { buildRoute } from "../../router/router";
import AccountSubscriptionBillingEmailNotice from "./AccountSubscriptionBillingEmailNotice";
import WalletOverview from "./billing/WalletOverview";
import WalletPaymentHistory from "./billing/WalletPaymentHistory";
import WalletSettings from "./billing/WalletSettings";

export default function Wallet() {
  const { t } = useTranslation();
  const { session } = useHilosStore();

  if (!session) {
    return null;
  }

  return (
    <div className="h-screen overflow-y-auto bg-gray-100">
      <div className="py-4 px-4 sm:border-b sm:border-gray-200 sm:px-6 lg:px-8">
        <HeaderNavigation
          navPages={[
            {
              name: t("home"),
              url: buildRoute("dashboard"),
            },
            {
              name: t("settings"),
              url: buildRoute("config-account"),
            },
            {
              name: t("settings:wallet.label", "WhatsApp Credit"),
              url: buildRoute("config-account-wallet"),
            },
          ]}
        />
        <div className="lg:flex lg:items-center lg:justify-between">
          <div className="min-w-0 flex-1">
            <Trans i18nKey="settings:wallet-header">
              <h2 className="mt-2 flex items-center space-x-2 text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl">
                <WhatsAppIcon className="mr-2 h-7 w-7" />
                WhatsApp Credit
              </h2>
              <p className="mt-1 text-sm text-gray-600">
                All WhatsApp fees will be debited from your credit balance. If
                your balance reaches $0, you won't be able to send WhatsApp
                templates until you recharge.
              </p>
            </Trans>
          </div>
        </div>
      </div>

      <AccountSubscriptionBillingEmailNotice />
      <PermissionsChecker
        permission="change_stripesubscription"
        action={t(
          "settings:permissions.change_stripesubscription",
          "update your wallet configuration"
        )}
      >
        <Tabs
          tabs={[
            {
              label: t("overview", "Balance"),
              icon: ChartPieIcon,
            },
            {
              label: t("settings", "Settings"),
              icon: CogIcon,
            },
            {
              label: t("payment-history", "Payment History"),
              icon: ClipboardListIcon,
            },
          ]}
          className="px-4 sm:px-6"
        >
          <WalletOverview />
          <WalletSettings />
          <WalletPaymentHistory />
        </Tabs>
      </PermissionsChecker>
    </div>
  );
}
