import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { ArrowSmRightIcon, MailIcon } from "@heroicons/react/outline";
import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";
import { Formik } from "formik";
import * as yup from "yup";
import { StripeCheckoutSession } from "@hilos/types/billing";
import RadioButtonsField from "src/components/Form/RadioButtonsField";
import TextInputField from "src/components/Form/TextInputField";
import StateButton from "src/components/StateButton";
import APIErrors from "src/components/base/APIErrors";
import useCleanTimeout from "src/hooks/useCleanTimeout";
import useHilosStore from "src/hooks/useHilosStore";
import { ERROR_MESSAGES } from "src/constants/errors";
import { API_ROUTES } from "src/router/router";
import { axiosErr } from "src/types/axios";

export default function WalletCharge() {
  const { t } = useTranslation();
  const [success, setSuccess] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [backendError, setBackendError] = useState("");
  const [backendValidationErrors, setBackendValidationErrors] = useState({});
  const timeout = useCleanTimeout();
  const { session } = useHilosStore();

  const initialValues = {
    amount: 10,
    billing_email: session?.account.billing_email || "",
  };

  const schema = yup.object().shape({
    amount: yup.number().min(10, t(ERROR_MESSAGES.MIN_NUMBER_VALUE)),
    billing_email: yup
      .string()
      .email(t(ERROR_MESSAGES.INVALID_EMAIL))
      .required(t(ERROR_MESSAGES.REQUIRED)),
  });

  const FIELDS = {
    billing_email: {
      key: "billing_email",
      label: t(
        "settings:billing.checkout.billing_email.label",
        "Billing Email"
      ),
      help: t(
        "settings:billing.checkout.billing_email.help",
        "The email you'd like to receive all your invoices at."
      ),
    },
    amount: {
      key: "amount",
      label: t(
        "settings:billing.checkout.amount.label",
        "Amount to add (in USD)"
      ),
    },
  };

  const onSubmit = async (formData, setSubmitting) => {
    setBackendValidationErrors({});
    setBackendError("");
    setSubmitting(true);

    //@ts-ignore
    const stripeKey: string =
      process.env.NODE_ENV === "production"
        ? process.env.REACT_APP_PROD_STRIPE_PUBLIC_KEY
        : process.env.REACT_APP_DEV_STRIPE_PUBLIC_KEY;
    const stripe = await loadStripe(stripeKey);
    if (!stripe) {
      return;
    }

    try {
      const response = await axios.post<StripeCheckoutSession>(
        API_ROUTES.WALLET_CHECKOUT_SESSION,
        formData
      );
      setSubmitting(false);
      setSuccess(true);

      if (!response.data.sessionId) {
        setBackendError(
          t(
            "settings:billing.error-stripe",
            "Couldn't start a Stripe secure session, please try again later."
          )
        );
        return;
      }
      setSuccess(true);
      stripe
        .redirectToCheckout({
          sessionId: response.data.sessionId,
        })
        .then((res) => {
          console.log(res);
        });
    } catch (err) {
      const errorAxios: axiosErr = err as axiosErr;
      setSuccess(false);
      if (errorAxios?.response?.status === 400) {
        console.log("error", errorAxios);
        setBackendValidationErrors(errorAxios.response.data);
      } else {
        setBackendError(
          t(
            "settings:billing.error-stripe",
            "Couldn't start a Stripe secure session, please try again later."
          )
        );
      }
      timeout.current = setTimeout(() => {
        setSubmitted(false);
      }, 1000 * 2);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      <div>
        <Formik
          validationSchema={schema}
          onSubmit={(values, { setSubmitting }) =>
            onSubmit(values, setSubmitting)
          }
          initialValues={initialValues}
        >
          {(formik) => (
            <form
              noValidate
              onSubmit={formik.handleSubmit}
              className="space-y-4"
            >
              <APIErrors
                APIError={backendError}
                APIValidationErrors={backendValidationErrors}
                fieldTranslations={{}}
              />

              <RadioButtonsField
                options={[
                  {
                    label: "$10",
                    value: 10,
                  },
                  {
                    label: "$20",
                    value: 20,
                  },
                  {
                    label: "$50",
                    value: 50,
                  },
                  {
                    label: "$100",
                    value: 100,
                  },
                  {
                    label: "$200",
                    value: 200,
                  },
                  {
                    label: "$500",
                    value: 500,
                  },
                ]}
                label={t(FIELDS.amount.label)}
                name={FIELDS.amount.key}
              />

              {!session?.account.billing_email && (
                <TextInputField
                  label={t(FIELDS.billing_email.label)}
                  help={t(FIELDS.billing_email.help)}
                  name={FIELDS.billing_email.key}
                  type="email"
                  icon={MailIcon}
                />
              )}

              <StateButton
                isSubmitting={formik.isSubmitting}
                submitted={submitted}
                success={success}
                disabled={formik.isSubmitting}
                submittingText={t("processing")}
                successText={t("redirecting", "Redirecting...")}
                btnClasses="w-full inline-flex items-center justify-center rounded-md border border-transparent bg-gradient-to-r from-hilos to-red-400 px-5 py-3 text-base font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-hilos focus:ring-offset-2"
                btnClassesSuccess="w-full inline-flex items-center justify-center rounded-md border border-transparent bg-green-100 px-5 py-3 text-base font-medium text-green-800 shadow-sm hover:bg-green-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                btnClassesError="w-full inline-flex items-center justify-center rounded-md border border-transparent bg-yellow-100 px-5 py-3 text-base font-medium text-yellow-800 shadow-sm hover:bg-yellow-100 focus:outline-none focus:ring-2 focus:ring-yellow-400 focus:ring-offset-2"
                initialText={
                  <>
                    {t(
                      "settings:billing.pay-securely",
                      "Pay securely with Stripe"
                    )}
                    <ArrowSmRightIcon
                      className="ml-1 h-5 w-5"
                      aria-hidden="true"
                    />
                  </>
                }
              />
            </form>
          )}
        </Formik>
      </div>
      <p className="whitespace mt-1 text-sm text-gray-500">
        <Trans i18nKey="settings:terms-and-conditions">
          <a
            href="https://drive.google.com/file/d/1FwjBwzTQEFdOgcY7w-MbwXl_bdvCtBU-/view"
            rel="noreferrer"
            target="_blank"
            className="font-medium text-gray-500 underline"
          >
            Learn about our terms & conditions
          </a>{" "}
          and our{" "}
          <a
            href="https://drive.google.com/file/d/1qg1SsYcMw265LRGE7UsE24zZl-kxFksm/view"
            target="_blank"
            rel="noreferrer"
            className="font-medium text-gray-500 underline"
          >
            privacy policy
          </a>
          .
        </Trans>
      </p>
    </>
  );
}
