import { Link } from "react-router-dom";
import { CheckCircleIcon, ChevronRightIcon } from "@heroicons/react/outline";
import { format } from "date-fns";
import { UserNotification } from "@hilos/types/private-schema";
import { markNotificationAsRead } from "src/hooks/useUserNotification";
import { classNames } from "src/Helpers";

interface UserNotificationItemProps {
  notification: UserNotification;
  onClose: () => void;
}
export default function UserNotificationItem({
  notification,
  onClose,
}: UserNotificationItemProps) {
  return (
    <div className="flex items-center justify-between gap-x-4 w-full">
      <div className="grow w-full flex items-center">
        <button
          type="button"
          className={classNames(
            notification.seen
              ? "text-gray-500 hover:text-white hover:bg-gray-500"
              : "text-hilos hover:text-white hover:bg-hilos",
            "rounded-full mr-2"
          )}
          onClick={() =>
            markNotificationAsRead(notification.id, !notification.seen)
          }
        >
          <CheckCircleIcon className="h-4 w-4" />
        </button>

        {notification.action_type === "GO_TO_URL" && notification.action_url && (
          <Link
            to={notification.action_url}
            className="w-full"
            onClick={onClose}
          >
            <div className="w-full">
              <div className="flex items-center justify-between">
                <p
                  className={classNames(
                    notification.seen
                      ? "font-medium text-gray-600"
                      : "font-semibold text-hilos",
                    "text-sm font-semibold leading-6"
                  )}
                >
                  {notification.title}
                </p>
                <p className="text-xs leading-5 text-gray-500">
                  {format(
                    new Date(notification.timestamp as string),
                    "HH:mm 'on' MMM d"
                  )}
                </p>
              </div>
              <p className="flex text-xs leading-5 text-gray-500 text-ellipsis overflow-hidden">
                {notification.content}
              </p>
            </div>
          </Link>
        )}
        {notification.action_type === "EXTERNAL_URL" &&
          notification.action_url && (
            <a
              href={notification.action_url}
              target="_blank"
              rel="noreferrer"
              className="w-full"
              onClick={onClose}
            >
              <div className="w-full">
                <div className="flex items-center justify-between">
                  <p
                    className={classNames(
                      notification.seen
                        ? "font-medium text-gray-600"
                        : "font-semibold text-hilos",
                      "text-sm font-semibold leading-6"
                    )}
                  >
                    {notification.title}
                  </p>
                  <p className="text-xs leading-5 text-gray-500">
                    {format(
                      new Date(notification.timestamp as string),
                      "HH:mm 'on' MMM d"
                    )}
                  </p>
                </div>
                <p className="flex text-xs leading-5 text-gray-500 text-ellipsis overflow-hidden">
                  {notification.content}
                </p>
              </div>
            </a>
          )}
        {!["EXTERNAL_URL", "GO_TO_URL"].includes(notification.action_type) && (
          <div className="w-full">
            <div className="flex items-center justify-between">
              <p
                className={classNames(
                  notification.seen
                    ? "font-medium text-gray-600"
                    : "font-semibold text-hilos",
                  "text-sm font-semibold leading-6"
                )}
              >
                {notification.title}
              </p>
              <p className="text-xs leading-5 text-gray-500">
                {format(
                  new Date(notification.timestamp as string),
                  "HH:mm d/MMM/yy"
                )}
              </p>
            </div>
            <p className="flex text-xs leading-5 text-gray-500 text-ellipsis overflow-hidden">
              {notification.content}
            </p>
          </div>
        )}
      </div>
      <div className="grow-0">
        <ChevronRightIcon
          className="h-5 w-5 flex-none text-gray-400"
          aria-hidden="true"
        />
      </div>
    </div>
  );
}
