import { useCallback, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import {
  ExclamationCircleIcon,
  ExternalLinkIcon,
} from "@heroicons/react/outline";
import * as Sentry from "@sentry/browser";
import axios from "axios";
import { EventData } from "@hilos/types/conversation";
import { ConversationEvent } from "@hilos/types/private-schema";
import InboxNote from "src/components/InboxNote";
import Loading from "src/components/Loading";
import i18n from "src/i18n";
import { API_ROUTES, buildAPIRoute, buildRoute } from "src/router/router";
import { FlowExecutionStatus } from "src/types/flows/FlowExecution";
import { userToString } from "../../../Helpers";
import { CONVERSATION_STATUS } from "../InboxMeta";
import EventWrap, { EventWrapProps } from "./EventWrap";

export const EVENT_TYPES = {
  CONVERSATION_CREATED: "CONVERSATION_CREATED",
  SENT_BLAST: "SENT_BLAST",
  ERROR_BROADCAST: "ERROR_BROADCAST",
  JOINED_FLOW: "JOINED_FLOW",
  JOINED_INBOX: "JOINED_INBOX",
  RESUMED_FLOW: "RESUMED_FLOW",
  // TODO: Deprecated!
  PAUSED_FLOW: "PAUSED_FLOW",
  CANCELED_FLOW: "CANCELED_FLOW",
  LEFT_FLOW: "LEFT_FLOW",
  ERROR_FLOW: "ERROR_FLOW",
  STATUS_CHANGE: "STATUS_CHANGE",
  TEAM_MEMBER_ADDED: "TEAM_MEMBER_ADDED",
  TEAM_MEMBER_REMOVED: "TEAM_MEMBER_REMOVED",
  ERROR_TEAM_ASSIGNMENT: "ERROR_TEAM_ASSIGNMENT",
  ASSIGNED_TO_TEAM: "ASSIGNED_TO_TEAM",
  NOTE_ADDED: "NOTE_ADDED",
  TAG_ADDED: "TAG_ADDED",
  TAG_REMOVED: "TAG_REMOVED",
  ARCHIVED: "ARCHIVED",
  UNARCHIVED: "UNARCHIVED",
  TEAM_MEMBER_REASSIGNED: "TEAM_MEMBER_REASSIGNED",
  PHONE_NUMBER_UPDATED: "PHONE_NUMBER_UPDATED",
  WORKFLOW_TASK_CREATED: "WORKFLOW_TASK_CREATED",
  WORKFLOW_TASK_STATUS_UPDATE: "WORKFLOW_TASK_STATUS_UPDATE",
};

type retryEventFn = (event: ConversationEvent) => Promise<void>;

interface ConversationEventProps {
  event: ConversationEvent;
  timestamp?: string | null;
}

/** Button component used to retry events that failed to execute (Flows or Broadcasts)
 * @param `{@link ConversationEventProps}` - Event data that failed
 * @see {@link retryEventFn}
 * @returns `JSX.Element` Button component
 */
function RetryEventButton({ event }: ConversationEventProps) {
  const { t } = useTranslation();
  const [retried, setRetried] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);

  const RetryableEvents: Record<string, retryEventFn> = {
    [EVENT_TYPES.ERROR_FLOW]: useCallback(async (event) => {
      return await axios.patch(
        buildAPIRoute(API_ROUTES.FLOW_EXECUTION_CONTACT_STATUS, {
          ":id": event.event_data.flow_execution_contact,
        }),
        { status: FlowExecutionStatus.READY }
      );
    }, []),
    [EVENT_TYPES.ERROR_BROADCAST]: useCallback(async (event) => {
      return await axios.patch(
        buildAPIRoute(API_ROUTES.MESSAGE_BLAST_RECIPIENT_STATUS, {
          ":id": event.event_data.blast?.recipient,
        }),
        { status: "PENDING" }
      );
    }, []),
  };

  const retryEvent = useCallback(
    async (retryEventFn: (event: ConversationEvent) => Promise<void>) => {
      setSubmitting(true);
      try {
        await retryEventFn(event);

        setRetried(true);
        setSubmitting(false);
      } catch (err) {
        Sentry.captureException(err);
      } finally {
        setSubmitting(false);
      }
    },
    [event]
  );
  return (
    <>
      {!retried &&
        (isSubmitting ? (
          <Loading showText={false} className="h-5 w-5 mx-2" />
        ) : (
          <button
            type="button"
            onClick={() => retryEvent(RetryableEvents[event.event_type])}
            className="font-medium text-gray-600"
          >
            {t("retry", "Retry")}
          </button>
        ))}
    </>
  );
}
/** Function to get the object for <EventWrap /> component
 * @param `{@link EVENT_TYPES}` - Event type
 * @param `{@link EventData}` - Event data
 * @param `{@link ConversationEvent}` - Event data
 * @returns {@link EventWrapProps} - Object to be passed to <EventWrap />
 */
export function getEventLabel(event: ConversationEvent): EventWrapProps | void {
  const { event_data: data, event_type: type } = event;

  switch (type) {
    /*
      CONVERSATION EVENTS
    */
    case EVENT_TYPES.JOINED_INBOX:
      return {
        event: "inbox:event.contact-started-chat",
        timestamp: event.timestamp,
      };
    case EVENT_TYPES.CONVERSATION_CREATED:
      return {
        event: "inbox:event.chat-created-by",
        toptions: {
          user:
            (data.user &&
              `${i18n.t("by", " by")} ${userToString(data.user)}`) ||
            "",
        },
        timestamp: event.timestamp,
      };
    case EVENT_TYPES.TAG_ADDED:
      if (!data.tag) {
        break;
      }
      return {
        event: "inbox:event.chat-added-tag",
        toptions: {
          user:
            (data.user &&
              `${i18n.t("by", " by")} ${userToString(data.user)}`) ||
            "",
          tag: data.tag.name,
        },
        timestamp: event.timestamp,
      };
    case EVENT_TYPES.TAG_REMOVED:
      if (!data.tag) {
        break;
      }
      return {
        event: "inbox:event.chat-removed-tag",
        toptions: {
          user:
            (data.user &&
              `${i18n.t("by", " by")} ${userToString(data.user)}`) ||
            "",
          tag: data.tag.name,
        },
        timestamp: event.timestamp,
      };
    case EVENT_TYPES.ARCHIVED:
      return {
        event: "inbox:event.chat-archived",
        toptions: {
          user:
            (data.user &&
              `${i18n.t("by", " by")} ${userToString(data.user)}`) ||
            "",
        },
        timestamp: event.timestamp,
      };
    case EVENT_TYPES.UNARCHIVED:
      return {
        event: "inbox:event.chat-unarchived",
        toptions: {
          user:
            (data.user &&
              `${i18n.t("by", " by")} ${userToString(data.user)}`) ||
            "",
        },
        timestamp: event.timestamp,
      };
    case EVENT_TYPES.STATUS_CHANGE:
      const getReason = () => {
        switch (data.reason) {
          case "BROADCAST":
            return i18n.t(
              "inbox:event.broadcast-change-status",
              "Status changed automatically (Broadcast sent)"
            );

          case "INACTIVE":
            return i18n.t(
              "inbox:event.inactive-change-status",
              "Status changed automatically (Inactive conversation)"
            );

          case "BULK":
            return i18n.t(
              "inbox:event.bulk-change-status",
              "Status changed in bulk"
            );
          default:
            return "";
        }
      };
      if (!data.status) {
        return {
          event: "inbox:event.chat-status-unknown",
          timestamp: event.timestamp,
          user: data.by,
          extra: getReason(),
        };
      }
      if (!CONVERSATION_STATUS[data.status]) {
        // Some legacy statuses like `FLOW_PAUSED` cause errors in the frontend
        // We should just ignore them
        return;
      }
      return {
        event: "inbox:event.chat-status-changed",
        toptions: { status: i18n.t(CONVERSATION_STATUS[data.status].label) },
        timestamp: event.timestamp,
        extra: getReason(),
        user: data.by,
      };
    case EVENT_TYPES.ASSIGNED_TO_TEAM:
      return {
        event: "inbox:event.chat-assigned-team",
        toptions: {
          team: data.team?.name || i18n.t("a-team"),
          user:
            (data.by && `${i18n.t("by", " by")} ${userToString(data.by)}`) ||
            "" ||
            "",
        },
        timestamp: event.timestamp,
      };
    case EVENT_TYPES.ERROR_TEAM_ASSIGNMENT:
      return {
        event: "inbox:event.error-assigning-team",
        toptions: {
          team: data.team?.name || i18n.t("a-team", "a team"),
          reason: data.reason,
        },
        timestamp: event.timestamp,
      };
    case EVENT_TYPES.TEAM_MEMBER_ADDED:
      return {
        event: "inbox:event.member-added",
        toptions: {
          member: userToString(data.user) || "",
          user:
            (data.by && `${i18n.t("by", " by")} ${userToString(data.by)}`) ||
            "",
        },
        timestamp: event.timestamp,
      };
    case EVENT_TYPES.TEAM_MEMBER_REASSIGNED:
      return {
        event: "inbox:event.team-reassignment",
        timestamp: event.timestamp,
      };
    case EVENT_TYPES.TEAM_MEMBER_REMOVED:
      return {
        event: "inbox:event.member-removed",
        toptions: {
          member: userToString(data.user) || i18n.t("a-user"),
          user:
            (data.by && `${i18n.t("by", " by")} ${userToString(data.by)}`) ||
            "" ||
            "",
        },
        timestamp: event.timestamp,
      };
    case EVENT_TYPES.NOTE_ADDED:
      if (!data.note) {
        break;
      }
      return {
        className: "text-right justify-end flex flex-col",
        event: <InboxNote note={data.note.notes} />,
        timestamp: event.timestamp,
        user: data.user,
      };

    /*
      BROADCAST EVENTS
    */
    case EVENT_TYPES.SENT_BLAST:
      if (!data.blast) {
        break;
      }
      const blastName = data.blast.name;

      return {
        event: (
          <Trans i18nKey="inbox:event.broadcast-sent" values={blastName}>
            Was sent broadcast{" "}
            <a
              href={
                "https://app.hilos.io" +
                buildRoute("broadcast-detail", {
                  id: data.blast.id,
                })
              }
              target="_blank"
              rel="noreferrer"
            >
              {/* @ts-ignore */}
              {{ blastName }}
            </a>
          </Trans>
        ),
        timestamp: event.timestamp,
      };
    case EVENT_TYPES.ERROR_BROADCAST:
      if (!data.blast) {
        break;
      }
      if (data.reason && data.reason === "Conversation not CLOSED.") {
        const blastName = data.blast.name;
        return {
          event: (
            <div className="rounded-md bg-red-50 p-3">
              <div className="flex justify-center">
                <div className="flex-shrink-0 grid place-items-center">
                  <ExclamationCircleIcon
                    className="h-5 w-5 text-red-400"
                    aria-hidden="true"
                  />
                </div>
                <div className="ml-3 flex justify-center items-center">
                  <div>
                    <Trans i18nKey="inbox:error.broadcast-conversation-not-closed">
                      Broadcast{" "}
                      <a
                        href={
                          "https://app.hilos.io" +
                          buildRoute("broadcast-detail", {
                            id: data.blast.id,
                          })
                        }
                        target="_blank"
                        rel="noreferrer"
                      >
                        {/* @ts-ignore */}
                        {{ blastName }}
                      </a>{" "}
                      not sent, conversation is not CLOSED.
                    </Trans>
                  </div>
                  <div className="flex-shrink-0 ml-1">
                    <RetryEventButton event={event} />
                  </div>
                </div>
              </div>
            </div>
          ),
        };
      }
      break;
    /*
      FLOW EVENTS
    */
    case EVENT_TYPES.JOINED_FLOW:
      if (!data.flow) {
        break;
      }
      if (data.flow.is_chatbot) {
        return {
          event: <FlowEvent data={data} label="inbox:event.joined-chatbot" />,
          timestamp: event.timestamp,
        };
      } else {
        return {
          event: <FlowEvent data={data} label="inbox:event.joined-flow" />,
          timestamp: event.timestamp,
        };
      }
    case EVENT_TYPES.PAUSED_FLOW:
      if (!data.flow) {
        break;
      }
      return {
        event: <FlowEvent data={data} label="inbox:event.paused-flow" />,
        timestamp: event.timestamp,
      };
    case EVENT_TYPES.LEFT_FLOW:
      if (!data.flow) {
        break;
      }
      if (data.flow.is_chatbot) {
        return {
          event: <FlowEvent data={data} label="inbox:event.left-chatbot" />,
          timestamp: event.timestamp,
        };
      } else {
        return {
          event: <FlowEvent data={data} label="inbox:event.left-flow" />,
          timestamp: event.timestamp,
        };
      }
    case EVENT_TYPES.RESUMED_FLOW:
      if (!data.flow) {
        break;
      }
      return {
        event: <FlowEvent data={data} label="inbox:event.resumed-flow" />,
        timestamp: event.timestamp,
      };
    case EVENT_TYPES.CANCELED_FLOW:
      if (!data.flow) {
        break;
      }
      return {
        event: (
          <FlowEvent
            data={data}
            label="inbox:event.canceled-flow"
            reason={data.reason}
          />
        ),
        timestamp: event.timestamp,
      };
    case EVENT_TYPES.ERROR_FLOW:
      if (!data.flow) {
        break;
      }
      if (data.reason && data.reason === "Conversation not CLOSED.") {
        const flowName = data.flow?.name;
        if (data.flow.execution_type === "INBOUND") {
          return {
            event: (
              <div className="rounded-md bg-red-50 p-4">
                <div className="flex">
                  <div className="flex-shrink-0 grid place-items-center">
                    <ExclamationCircleIcon
                      className="h-5 w-5 text-red-400"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="ml-3">
                    {data.flow.is_chatbot ? (
                      <Trans i18nKey="inbox:error.chatbot-conversation-not-closed">
                        Chatbot{" "}
                        <a
                          href={
                            "https://app.hilos.io" +
                            buildRoute("flow-detail", {
                              id: data.flow.id,
                            })
                          }
                          target="_blank"
                          rel="noreferrer"
                        >
                          {/* @ts-ignore */}
                          {{ flowName }}
                        </a>{" "}
                        not started, conversation is not CLOSED.
                      </Trans>
                    ) : (
                      <Trans i18nKey="inbox:error.flow-conversation-not-closed">
                        Flow{" "}
                        <a
                          href={
                            "https://app.hilos.io" +
                            buildRoute("flow-detail", {
                              id: data.flow.id,
                            })
                          }
                          target="_blank"
                          rel="noreferrer"
                        >
                          {/* @ts-ignore */}
                          {{ flowName }}
                        </a>{" "}
                        not started, conversation is not CLOSED.
                      </Trans>
                    )}
                  </div>
                </div>
              </div>
            ),
          };
        }
        return {
          event: (
            <div className="rounded-md bg-red-50 p-4">
              <div className="flex">
                <div className="flex-shrink-0 grid place-items-center">
                  <ExclamationCircleIcon
                    className="h-5 w-5 text-red-400"
                    aria-hidden="true"
                  />
                </div>
                <div className="ml-3 flex justify-center items-center">
                  <div>
                    <Trans i18nKey="inbox:error.flow-conversation-not-closed">
                      Flow{" "}
                      <a
                        href={
                          "https://app.hilos.io" +
                          buildRoute("flow-detail", {
                            id: data.flow.id,
                          })
                        }
                        target="_blank"
                        rel="noreferrer"
                      >
                        {/* @ts-ignore */}
                        {{ flowName }}
                      </a>{" "}
                      not executed, conversation is not CLOSED.
                    </Trans>
                  </div>
                  <div className="flex-shrink-0 ml-1">
                    <RetryEventButton event={event} />
                  </div>
                </div>
              </div>
            </div>
          ),
        };
      }
      break;
    /***  Contact Events */
    case EVENT_TYPES.PHONE_NUMBER_UPDATED:
      if (!data.contact) {
        break;
      }
      return {
        event: "inbox:event.contact-phone-number-updated",
        toptions: {
          old_phone: data.contact.old_phone,
          new_phone: data.contact.new_phone,
        },
      };
    /***  Workflow Task Events */
    case EVENT_TYPES.WORKFLOW_TASK_CREATED:
      if (!data.workflow?.id) {
        break;
      }
      return {
        event: "inbox:event.workflow-task-created",
        toptions: {
          workflow_id: data.workflow.id,
          workflow_name: data.workflow.name,
          task_name: data.workflow.task_name,
          state: data.state,
          task_id: data.task_id,
        },
      };
    case EVENT_TYPES.WORKFLOW_TASK_STATUS_UPDATE:
      if (!data.workflow?.id) {
        break;
      }
      return {
        event: "inbox:event.workflow-task-moved",
        toptions: {
          workflow_id: data.workflow.id,
          workflow_name: data.workflow.name,
          task_name: data.workflow.task_name,
          from_state: data.from_state,
          to_state: data.to_state,
          task_id: data.task_id,
        },
      };
    default:
      break;
  }
}

function FlowEvent({
  data,
  label,
}: {
  data: EventData;
  label: string;
  reason?: string;
}) {
  const { t } = useTranslation();
  let url: string | undefined = undefined;
  if (data.flow) {
    url = buildRoute("flow-detail", {
      id: data.flow.id,
    });
    if (data.flow.is_csat) {
      url = buildRoute("config-account-inbox");
    }
  }

  return (
    <div>
      {`${t(label)} `}
      {data.flow && url && (
        <a href={url} target="_blank" rel="noreferrer" className="mr-1">
          {data.flow.name}
        </a>
      )}
      {data.execution_contact && (
        <span className="mr-1">
          (
          <Link
            target="_blank"
            to={buildRoute("flow-execution-contact-detail", {
              id: data.execution_contact,
            })}
          >
            {t("link-to-execution-contact", "See execution")}
          </Link>
          )
        </span>
      )}
      {data.reason && <> - {data.reason}</>}
      {data.by && <> - {userToString(data.by)}</>}
    </div>
  );
}

function ConversationEventFn({ event }: ConversationEventProps) {
  if (!event.event_type || !event.event_data) {
    return null;
  }
  const label = getEventLabel(event);
  if (label) {
    return (
      <>
        <EventWrap {...label} />
      </>
    );
  }

  return null;
}

export default ConversationEventFn;
