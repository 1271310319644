import { useMemo } from "react";
import * as Tooltip from "@radix-ui/react-tooltip";
import { TagData } from "@hilos/types/conversation";
import { hasItems } from "src/helpers/utils";
import { useConversationTags } from "src/hooks/useTags";

interface ConversationTagListProps {
  tags?: { tag: TagData }[] | null;
}

interface ConversationTagListByIdProps {
  tags: number[];
}

function ConversationTagList({ tags }: ConversationTagListProps) {
  if (!tags || !tags.length) {
    return null;
  }

  const firstTag = tags[0].tag;
  const remainingTags = tags.slice(1);
  const hasMoreTags = remainingTags.length > 0;

  return (
    <div className="flex flex-1 items-center justify-end gap-1 max-w-1/2">
      <div className="max-w-20 overflow-ellipsis bg-pink-100 whitespace-nowrap rounded-md px-2 py-0.5 text-xs text-pink-700">
        <span className="block truncate text-xs">{firstTag.name}</span>
      </div>

      {hasMoreTags && (
        <Tooltip.Provider delayDuration={300}>
          <Tooltip.Root>
            <Tooltip.Trigger asChild>
              <div className="shrink-0 bg-pink-100 rounded-md px-2 py-0.5 text-xs text-pink-700 cursor-pointer">
                +{remainingTags.length}
              </div>
            </Tooltip.Trigger>
            <Tooltip.Portal>
              <Tooltip.Content
                className="max-w-64 rounded-md bg-gray-900 p-3 text-sm text-white animate-fadeIn"
                sideOffset={5}
              >
                <div className="flex flex-wrap justify-center gap-1">
                  {tags.map(({ tag }) => (
                    <span
                      className="bg-pink-100 rounded-md px-2 py-0.5 text-xs text-pink-700"
                      key={tag.id}
                    >
                      {tag.name}
                    </span>
                  ))}
                </div>
                <Tooltip.Arrow className="fill-gray-900" />
              </Tooltip.Content>
            </Tooltip.Portal>
          </Tooltip.Root>
        </Tooltip.Provider>
      )}
    </div>
  );
}

export function ConversationTagListById({
  tags,
}: ConversationTagListByIdProps) {
  const data = useConversationTags();

  const currentTagsFiltered = useMemo(() => {
    const nextTagsFiltered: { tag: TagData }[] = [];

    if (data && hasItems(tags) && hasItems(data.tags)) {
      for (const tag of data.tags) {
        if (tags.includes(tag.id)) {
          nextTagsFiltered.push({ tag });
        }
      }
    }

    return nextTagsFiltered;
  }, [data, tags]);

  return <ConversationTagList tags={currentTagsFiltered} />;
}

export default ConversationTagList;
