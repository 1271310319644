import { useCallback } from "react";
import { useQuery } from "react-query";
import axios from "axios";
import { ConversationTag } from "@hilos/types/private-schema";
import { API_ROUTES, buildAPIRoute } from "../router/router";

function useConversationTag(id: string) {
  const fetchConversationTag = useCallback(async () => {
    const { data } = await axios.get<ConversationTag>(
      buildAPIRoute(API_ROUTES.CONVERSATION_TAG_DETAIL, {
        ":id": id,
      }),
      {
        headers: {
          "x-module": "conversation-tag",
        },
      }
    );
    return data;
  }, [id]);

  const { data } = useQuery(
    ["conversation_tag_detail", id],
    fetchConversationTag
  );

  return data;
}

export default useConversationTag;
