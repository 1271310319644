import {
  MouseEvent,
  TouchEvent as ReactTouchEvent,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { InboxContactViewData } from "src/hooks/useInboxContactViews";

const DRAG_THRESHOLD = 5;
const SCROLL_SPEED = 1;

interface ViewButtonProps {
  view: InboxContactViewData;
  currentViewId: string | null;
  onClick: (id: string) => void;
  isTemporary?: boolean;
}

const ViewButton = memo(
  ({ view, currentViewId, isTemporary = false, onClick }: ViewButtonProps) => {
    const { t } = useTranslation();

    return (
      <button
        onClick={() => onClick(view.id)}
        className={`inline-flex text-nowrap items-center space-x-2 rounded-full px-3 py-1 text-sm flex-shrink-0 ${
          currentViewId === view.id
            ? isTemporary
              ? "bg-indigo-100 text-indigo-700"
              : "bg-indigo-600 text-white"
            : "bg-gray-100 text-gray-700 hover:bg-gray-200"
        }`}
      >
        <span>{t(view.name, view.name)}</span>
        <span className="px-1.5 py-0.5 bg-opacity-10 bg-black rounded-full">
          {view.conversations_count ?? 0}
        </span>
      </button>
    );
  }
);

ViewButton.displayName = "ViewButton";

interface PinnedViewsProps {
  views: InboxContactViewData[];
  currentView: InboxContactViewData | null;
  currentViewId: string | null;
  onSelectView: (id: string) => void;
}

export default function PinnedViews({
  views,
  currentView,
  currentViewId,
  onSelectView,
}: PinnedViewsProps) {
  const scrollRef = useRef<HTMLDivElement>(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const [dragDistance, setDragDistance] = useState(0);

  const displayedViews = useMemo(() => {
    if (!currentView || views.some((v) => v.id === currentView.id)) {
      return views;
    }
    return [currentView, ...views];
  }, [views, currentView]);

  const handleDragStart = useCallback((clientX: number) => {
    setIsDragging(true);
    setDragDistance(0);
    if (scrollRef.current) {
      setStartX(clientX - scrollRef.current.offsetLeft);
      setScrollLeft(scrollRef.current.scrollLeft);
    }
  }, []);

  const handleMouseDown = useCallback(
    (e: MouseEvent) => {
      handleDragStart(e.pageX);
    },
    [handleDragStart]
  );

  const handleTouchStart = useCallback(
    (e: ReactTouchEvent) => {
      const touch = e.touches[0];
      handleDragStart(touch.pageX);
    },
    [handleDragStart]
  );

  const handleDragMove = useCallback(
    (clientX: number) => {
      if (!isDragging || !scrollRef.current) return;

      const x = clientX;
      const walk = (x - (scrollRef.current.offsetLeft + startX)) * SCROLL_SPEED;
      scrollRef.current.scrollLeft = scrollLeft - walk;
      setDragDistance(Math.abs(x - (scrollRef.current.offsetLeft + startX)));
    },
    [isDragging, startX, scrollLeft]
  );

  const handleMouseMove = useCallback(
    (e: MouseEvent | globalThis.MouseEvent) => {
      e.preventDefault();
      const x = "pageX" in e ? e.pageX : (e as globalThis.MouseEvent).clientX;
      handleDragMove(x);
    },
    [handleDragMove]
  );

  const handleTouchMove = useCallback(
    (e: ReactTouchEvent | globalThis.TouchEvent) => {
      e.preventDefault();
      const touch =
        "touches" in e ? e.touches[0] : (e as globalThis.TouchEvent).touches[0];
      handleDragMove(touch.pageX);
    },
    [handleDragMove]
  );

  const handleDragEnd = useCallback(() => {
    setIsDragging(false);
  }, []);

  const handleClick = useCallback(
    (id: string) => {
      if (dragDistance < DRAG_THRESHOLD) {
        onSelectView(id);
      }
    },
    [dragDistance, onSelectView]
  );

  useEffect(() => {
    if (!isDragging) return;

    const handleGlobalMouseMove = (e: globalThis.MouseEvent) =>
      handleMouseMove(e);
    const handleGlobalTouchMove = (e: globalThis.TouchEvent) => {
      e.preventDefault();
      handleTouchMove(e);
    };

    window.addEventListener("mousemove", handleGlobalMouseMove);
    window.addEventListener("mouseup", handleDragEnd);
    window.addEventListener(
      "touchmove",
      handleGlobalTouchMove as EventListener,
      {
        passive: false,
      }
    );
    window.addEventListener("touchend", handleDragEnd);

    return () => {
      window.removeEventListener("mousemove", handleGlobalMouseMove);
      window.removeEventListener("mouseup", handleDragEnd);
      window.removeEventListener(
        "touchmove",
        handleGlobalTouchMove as EventListener
      );
      window.removeEventListener("touchend", handleDragEnd);
    };
  }, [isDragging, handleMouseMove, handleTouchMove, handleDragEnd]);

  useEffect(() => {
    if (!scrollRef.current) return;

    const observer = new ResizeObserver(() => {
      if (scrollRef.current) {
        scrollRef.current.scrollLeft = 0;
      }
    });

    observer.observe(scrollRef.current);
    return () => observer.disconnect();
  }, []);

  if (!displayedViews.length) return null;

  return (
    <div
      ref={scrollRef}
      className="flex overflow-x-scroll gap-2 px-4 py-2 bg-white border-b border-gray-200 cursor-grab active:cursor-grabbing scrollbar-none"
      onMouseDown={handleMouseDown}
      onTouchStart={handleTouchStart}
    >
      {displayedViews.map((view) => (
        <ViewButton
          key={view.id}
          view={view}
          currentViewId={currentViewId}
          onClick={handleClick}
          isTemporary={
            currentView?.id === view.id && !views.some((v) => v.id === view.id)
          }
        />
      ))}
    </div>
  );
}
