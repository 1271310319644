import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { ChatAlt2Icon } from "@heroicons/react/outline";
import axios from "axios";
import HeaderNavigation from "src/components/HeaderNavigation";
import Loading from "src/components/Loading";
import {
  API_ROUTES,
  BASE_API_URL,
  BASE_PUBLIC_API_URL,
  PUBLIC_API_ROUTES,
  buildAPIRoute,
  buildRoute,
} from "src/router/router";
import WidgetForm from "./WidgetForm";

interface Widget {
  id: string;
  created_on: string;
  name: string;
  phone: string;
  bg_color: string;
  icon_color: string;
  default_text: string;
  button_1_text: string;
  button_2_text: string;
  button_3_text: string;
  position: "BOTTOM_RIGHT" | "BOTTOM_LEFT";
  padding: number;
  version: string;
  account: number;
  channel: number;
}

export default function WidgetUpdate() {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const [success, setSuccess] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [widget, setWidget] = useState<Widget | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchWidget = async () => {
      try {
        setError("");
        const response = await axios.get<Widget>(
          buildAPIRoute(PUBLIC_API_ROUTES.WHATSAPP_BUTTON_DETAIL, {
            ":id": id,
          }),
          {
            baseURL: BASE_PUBLIC_API_URL,
            headers: {
              "x-module": "widget",
            },
          }
        );
        setWidget(response.data);
      } catch (error) {
        console.error("Error loading widget:", error);
        setError(t("widget:error-loading", "Error loading widget data"));
      } finally {
        setIsLoading(false);
      }
    };

    fetchWidget();
  }, [id, t, navigate]);

  const formSubmit = async (
    formData,
    setSubmitting,
    setBackendValidationErrors,
    setBackendError
  ) => {
    setBackendValidationErrors({});
    setBackendError("");
    try {
      const response = await axios.put<Widget>(
        buildAPIRoute(API_ROUTES.WHATSAPP_BUTTON_DETAIL, {
          ":id": id,
        }),
        formData,
        {
          baseURL: BASE_API_URL,
          headers: {
            "x-module": "widget",
          },
        }
      );
      setWidget(response.data);
      setSuccess(true);
    } catch (error) {
      setSuccess(false);
      if (axios.isAxiosError(error) && error.response?.status === 400) {
        setBackendValidationErrors(error.response.data);
      } else {
        setBackendError(t("widget:error-saving", "Error saving widget"));
      }
    } finally {
      setSubmitting(false);
      setSubmitted(true);
      setTimeout(() => {
        setSubmitted(false);
      }, 2000);
    }
  };

  if (isLoading) {
    return (
      <div className="flex flex-col justify-center items-center h-64 space-y-4">
        <Loading />
        <p className="text-sm text-gray-500">
          {t("widget:loading-widget", "Loading widget data...")}
        </p>
      </div>
    );
  }

  const initialValues = widget || {
    name: "",
    channel: 0,
    default_text: "",
    button_1_text: "",
    button_2_text: "",
    button_3_text: "",
    bg_color: "#72D66F",
    icon_color: "#FFFFFF",
    padding: 20,
    position: "BOTTOM_RIGHT",
  };

  return (
    <>
      <div className="py-4 px-4 sm:border-b sm:border-gray-200 sm:px-6 lg:px-8">
        <HeaderNavigation
          navPages={[
            {
              name: t("home"),
              url: buildRoute("dashboard"),
            },
            {
              name: t("widget:list-title", "WhatsApp Widgets"),
              url: buildRoute("wa-widget"),
            },
            {
              name: widget?.name || t("widget:edit-title", "Edit Widget"),
              url: "#",
            },
          ]}
        />
        <div className="lg:flex lg:items-center lg:justify-between">
          <div className="min-w-0 flex-1">
            <h2 className="mt-2 flex items-center text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl">
              <ChatAlt2Icon className="mr-4 h-7 w-7" aria-hidden="true" />
              {t("widget:edit-title", "Edit Widget")}
            </h2>
            <p className="mt-1 text-sm text-gray-600">
              {t(
                "widget:form-description",
                "Customize your widget's appearance and behavior."
              )}
            </p>
          </div>
        </div>
      </div>

      {error && (
        <div className="mx-auto max-w-4xl px-8 mt-4">
          <p className="text-red-500">{error}</p>
        </div>
      )}
      <WidgetForm
        initialValues={initialValues}
        formSubmit={formSubmit}
        success={success}
        submitted={submitted}
        action="update"
      />
    </>
  );
}
