import { useCallback } from "react";
import { useQuery } from "react-query";
import axios from "axios";
import { TeamData } from "@hilos/types/team";
import { API_ROUTES, buildAPIRoute } from "../router/router";

function useTeam(id: number | undefined) {
  const fetchTeam = useCallback(async () => {
    if (!id) {
      return null;
    }
    const { data } = await axios.get<TeamData>(
      buildAPIRoute(API_ROUTES.TEAM_DETAIL, {
        ":id": id,
      }),
      {
        headers: {
          "x-module": "team",
        },
      }
    );
    return data;
  }, [id]);

  const { data } = useQuery(["team_detail", id], fetchTeam);

  return data;
}

export function useTeams() {
  const fetchTeams = useCallback(async () => {
    const { data } = await axios.get<TeamData[]>(
      buildAPIRoute(API_ROUTES.TEAM_LIST_CREATE),
      {
        headers: {
          "x-module": "team",
        },
      }
    );
    return data;
  }, []);

  const { data } = useQuery(["team_list"], fetchTeams);

  return data || [];
}

export default useTeam;
