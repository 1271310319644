import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { BellIcon } from "@heroicons/react/outline";
import { toast } from "sonner";
import { getDataFromJSON } from "../helpers/utils";
import { API_ROUTES } from "../router/router";
import useHilosStore from "./useHilosStore";
import useHilosWebSockets from "./useHilosWebSockets";

interface UseAccountSyncParams {
  connect: boolean;
  setUnreadNotificationCount: (cnt: number) => void;
}

function useUserNotificationSync({
  connect,
  setUnreadNotificationCount,
}: UseAccountSyncParams) {
  const navigate = useNavigate();
  const navigateRef = useRef(navigate);
  const { reloadSession } = useHilosStore();

  const { lastJsonMessage } = useHilosWebSockets({
    path: API_ROUTES.WS_USER,
    connect,
  });

  useEffect(() => {
    if (lastJsonMessage) {
      const data = getDataFromJSON(lastJsonMessage && lastJsonMessage.data);

      if (data) {
        if (lastJsonMessage.type === "user_notification") {
          if (data && data.unread_notification_count) {
            setUnreadNotificationCount(data.unread_notification_count);
          }
          if (data && data.title) {
            toast(
              <button
                className="flex items-center"
                onClick={() =>
                  data.action_url
                    ? navigateRef.current(data.action_url)
                    : navigateRef.current("/")
                }
              >
                <div>
                  <BellIcon className="w-4 h-4 text-hilos mr-2" />
                </div>
                <div className="text-left">
                  <p className="text-sm font-bold text-gray-900">
                    {data.title}
                  </p>
                  <p className="text-xs text-gray-400">{data.content}</p>
                </div>
              </button>,
              {
                duration: 1000000,
              }
            );
          }
        } else if (lastJsonMessage.type === "user_notification_unread_count") {
          if (data) {
            setUnreadNotificationCount(data.count || 0);
          }
        } else if (lastJsonMessage.type === "user_account_update") {
          reloadSession();
        }
      }
    }
  }, [lastJsonMessage, setUnreadNotificationCount, reloadSession]);

  useEffect(() => {
    navigateRef.current = navigate;
  }, [navigate]);
}

export default useUserNotificationSync;
