import { useCallback } from "react";
import { isDesktop } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { Menu } from "@headlessui/react";
import { ChevronDownIcon, XCircleIcon } from "@heroicons/react/outline";
import { ConversationStatusEnum } from "@hilos/types/private-schema";
import { useFlowExecutionContactCancelCurrent } from "src/hooks/useFlowExecutionContactDetail";
import useHilosStore from "src/hooks/useHilosStore";
import { UpdateConversationStatusFn } from "src/hooks/useInboxContactDetails";
import usePopper, { Portal } from "src/hooks/usePopper";
import { CONVERSATION_STATUS, getConversationStatus } from "../InboxMeta";
import { getStatusIcon } from "./ConversationStatusBadge";

interface ConversationHeaderStatusProps {
  inboxContactId: string;
  onUpdateConversationStatus: UpdateConversationStatusFn;
  status: ConversationStatusEnum;
  isSubmittingStatus: boolean;
}

export default function ConversationHeaderStatus({
  inboxContactId,
  onUpdateConversationStatus,
  status,
  isSubmittingStatus,
}: ConversationHeaderStatusProps) {
  const { session } = useHilosStore();
  const { t } = useTranslation();
  const cancelCurrent = useFlowExecutionContactCancelCurrent(inboxContactId);

  const handleChangeStatus = useCallback(
    (status: ConversationStatusEnum) => {
      return () => {
        onUpdateConversationStatus(status);
      };
    },
    [onUpdateConversationStatus]
  );
  const [trigger, container] = usePopper({
    placement: "bottom-end",
    strategy: "fixed",
    modifiers: [{ name: "offset", options: { offset: [0, 10] } }],
  });

  if (isSubmittingStatus) {
    return (
      <div className="flex flex-row bg-gray-200  text-gray-600 items-center gap-1.5 border border-gray-200 rounded-md px-2.5 py-1 text-xs font-medium uppercase">
        <span className="inline-flex items-center text-xs font-medium uppercase">
          {t("updating")}
        </span>
      </div>
    );
  }

  if (status === "FLOW") {
    return (
      <div className="flex-row text-red-800 inline-flex items-center gap-1.5 border border-gray-200 rounded-md px-2.5 py-1 text-xs font-medium uppercase">
        <span className="inline-flex text-lg items-center gap-1.5 font-medium uppercase">
          <span
            className={`${getStatusIcon(status)?.icon} ${
              getStatusIcon(status)?.color
            }`}
          />
        </span>
        <div className="flex justify-center">
          <button
            type="button"
            onClick={cancelCurrent}
            className="font-medium ml-2"
          >
            <XCircleIcon className="h-5 w-5" />
          </button>
        </div>
      </div>
    );
  }

  if (status === "RESOLVED") {
    return (
      <div className="flex-row  text-blue-800 inline-flex items-center gap-1.5 border border-gray-200 rounded-md px-2.5 py-1 text-xs font-medium uppercase">
        <span className="inline-flex items-center gap-1.5 text-lg font-medium uppercase">
          <span
            className={`${getStatusIcon(status)?.icon} ${
              getStatusIcon(status)?.color
            }`}
          />
        </span>
        <div className="flex justify-center">
          <button
            type="button"
            onClick={handleChangeStatus("CLOSED")}
            className="font-medium ml-2"
          >
            <XCircleIcon className="h-5 w-5" />
          </button>
        </div>
      </div>
    );
  }

  return (
    <>
      {CONVERSATION_STATUS[status].next_steps.length < 1 ? (
        <span className="flex align-middle gap-1.5 border border-gray-200 rounded-md px-2.5 py-1 text-xs font-medium uppercase">
          <span
            className={`${getStatusIcon(status)?.icon} ${
              getStatusIcon(status)?.color
            } text-md`}
          />
          {isDesktop && <span>{t(CONVERSATION_STATUS[status].label)}</span>}
        </span>
      ) : (
        <Menu
          as="div"
          data-tour="convo-status-button"
          className="relative inline-block text-left"
        >
          <div>
            <Menu.Button
              className="flex align-middle items-center justify-center gap-1.5 border border-gray-200 rounded-md px-2.5 py-1 text-xs font-medium uppercase"
              ref={trigger}
            >
              <span
                className={`${getStatusIcon(status)?.icon} ${
                  getStatusIcon(status)?.color
                } text-lg`}
              />
              {isDesktop && (
                <span className="-mb-0.5">
                  {t(CONVERSATION_STATUS[status].label)}
                </span>
              )}
              <ChevronDownIcon
                className="-mr-1 ml-1 h-5 w-5"
                aria-hidden="true"
              />
            </Menu.Button>
          </div>

          <Portal data-tour="convo-status-menu">
            <Menu.Items
              className="right-0 z-10 w-52 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
              ref={container}
            >
              <div className="py-0">
                {getConversationStatus(
                  status,
                  session?.account
                    ?.measure_customer_satisfaction_per_conversation
                ).next_steps.map((nextStatus) => (
                  <Menu.Item key={nextStatus}>
                    {({ active }) => (
                      <button
                        type="button"
                        className={`group flex w-full items-center gap-2 px-4 py-2 text-left text-sm hover:rounded-md ${
                          active ? "bg-gray-100 text-gray-900" : "text-gray-700"
                        }`}
                        onClick={handleChangeStatus(
                          nextStatus as ConversationStatusEnum
                        )}
                      >
                        {t("move-to", "Move to")}{" "}
                        <span className="inline-flex items-center gap-1.5">
                          <span
                            className={`
                              ${
                                getStatusIcon(
                                  nextStatus as ConversationStatusEnum
                                )?.icon
                              } ${getStatusIcon(nextStatus)?.color}
                            `}
                          />
                          {t(CONVERSATION_STATUS[nextStatus].label)}
                        </span>
                      </button>
                    )}
                  </Menu.Item>
                ))}
              </div>
            </Menu.Items>
          </Portal>
        </Menu>
      )}
    </>
  );
}
