import { PropsWithChildren } from "react";

interface TwoColumnFormContainerProps extends PropsWithChildren {
  title: string;
  description: string;
}

export default function TwoColumnFormContainer({
  title,
  description,
  children,
}: TwoColumnFormContainerProps) {
  return (
    <div className="md:grid md:grid-cols-3 md:gap-6 my-3">
      <div className="md:col-span-1">
        <div className="px-4 sm:px-0">
          <h2 className="text-lg font-medium leading-6 text-gray-900">
            {title}
          </h2>
          <p className="mt-1 text-sm text-gray-600">{description}</p>
        </div>
      </div>

      <div className="mt-5 md:col-span-2 md:mt-0">
        <div className="shadow sm:overflow-hidden sm:rounded-md">
          <div className="space-y-4 bg-white px-4 py-5 sm:p-6">{children}</div>
        </div>
      </div>
    </div>
  );
}
