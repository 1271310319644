import { useCallback } from "react";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { ConversationContent } from "@hilos/types/private-schema";
import Loading from "src/components/Loading";
import useWorkflowTaskDetails from "src/hooks/useWorkflowTaskDetails";
import { buildRoute } from "src/router/router";
import ConversationContentSearch from "../inbox/Conversation/ConversationTab/ConversationContentSearch";

interface WorkflowBoardTaskSearchContext {
  focusedConversationContent: ConversationContent | null;
  setFocusedConversationContent: (data: ConversationContent | null) => void;
}

export default function WorkflowBoardTaskSearch() {
  const navigate = useNavigate();
  const { focusedConversationContent, setFocusedConversationContent } =
    useOutletContext<WorkflowBoardTaskSearchContext>();

  const { taskId, stateId, workflowId } = useParams() as {
    taskId: string;
    stateId: string;
    workflowId: string;
  };

  const { task, isLoading } = useWorkflowTaskDetails({
    taskId,
    workflowId,
  });

  const handleCloseSearch = useCallback(() => {
    navigate(buildRoute("workflow-task", { workflowId, taskId, stateId }));
    setFocusedConversationContent(null);
  }, [workflowId, taskId, stateId, navigate, setFocusedConversationContent]);

  if (isLoading) {
    return <Loading />;
  }

  if (!task) {
    return null;
  }

  return (
    <ConversationContentSearch
      inboxContactId={task.inbox_contact.id}
      focusedConversationContent={focusedConversationContent}
      setFocusedConversationContent={setFocusedConversationContent}
      onClose={handleCloseSearch}
    />
  );
}
