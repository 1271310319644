import { useCallback } from "react";
import { useQuery } from "react-query";
import axios from "axios";
import { PaginatedWorkflowReadSimpleList } from "@hilos/types/private-schema";
import { API_ROUTES, buildAPIRoute } from "../router/router";

function useWorkflows() {
  const fetchWorkflows = useCallback(async () => {
    const { data } = await axios.get<PaginatedWorkflowReadSimpleList>(
      buildAPIRoute(API_ROUTES.WORKFLOW_LIST_CREATE),
      {
        params: {
          page_size: 1000,
        },
      }
    );
    return data;
  }, []);

  const { data: workflows = { results: [] } } = useQuery(
    ["workflow_list"],
    fetchWorkflows
  );

  return workflows;
}

export default useWorkflows;
