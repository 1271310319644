import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ExclamationCircleIcon } from "@heroicons/react/outline";
import RadioButtonsField from "src/components/Form/RadioButtonsField";
import SelectorField from "src/components/Form/SelectorField";
import SwitchField from "src/components/Form/SwitchField";
import TextInputField from "src/components/Form/TextInputField";
import TwoColumnFormContainer from "src/components/TwoColumnFormContainer";
import { useStripePaymentMethods } from "src/hooks/useStripe";
import AccountFormUpdate from "../AccountFormUpdate";
import * as meta from "../AccountMeta";
import { PaymentMethodDescription } from "./PaymentMethodDescription";

const formatOptionLabel = ({ label, value, t, ...props }) => (
  <div className="flex items-center justify-between py-1">
    <PaymentMethodDescription paymentMethod={props} />
  </div>
);
export default function WalletSettings() {
  const { t } = useTranslation();

  const { payment_methods } = useStripePaymentMethods();
  const paymentMethodOptions = useMemo(
    () =>
      payment_methods.map((p) => ({
        label: p.payment_method_type,
        value: p.id,
        t,
        ...p,
      })),
    [payment_methods, t]
  );

  if (payment_methods.length < 1) {
    return (
      <div className="rounded-md bg-yellow-50 p-4">
        <div className="flex">
          <div className="shrink-0">
            <ExclamationCircleIcon
              aria-hidden="true"
              className="size-5 text-yellow-400"
            />
          </div>
          <div className="ml-3">
            <h3 className="text-sm font-medium text-yellow-800">
              {t(
                "settings:wallet-no-payment-method",
                "It seems like you haven't yet added a payment method for your account."
              )}
            </h3>
            <div className="mt-2 text-sm text-yellow-700">
              <p>
                {t(
                  "settings:wallet-no-payment-method-instructions",
                  "Please add credit to your wallet or start your Hilos subscription first!"
                )}
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
      <div className="mx-auto mt-6 max-w-3xl">
        <AccountFormUpdate>
          {() => (
            <TwoColumnFormContainer
              title={t("settings:wallet-settings", "Wallet Configuration")}
              description={t(
                "settings:wallet-settings-description",
                "Select if you'd like us to autorecharge your wallet, it's frequency and amount."
              )}
            >
              <div className="space-y-6">
                <SwitchField
                  name={meta.FIELDS.autorecharge_wallet.key}
                  label={t(meta.FIELDS.autorecharge_wallet.label)}
                  help={t(meta.FIELDS.autorecharge_wallet.help)}
                />

                <RadioButtonsField
                  options={[
                    {
                      label: "$10",
                      value: 10,
                    },
                    {
                      label: "$20",
                      value: 20,
                    },
                    {
                      label: "$50",
                      value: 50,
                    },
                    {
                      label: "$100",
                      value: 100,
                    },
                    {
                      label: "$200",
                      value: 200,
                    },
                    {
                      label: "$500",
                      value: 500,
                    },
                  ]}
                  name={meta.FIELDS.autorecharge_amount.key}
                  label={t(meta.FIELDS.autorecharge_amount.label)}
                />

                <TextInputField
                  name={meta.FIELDS.autorecharge_limit.key}
                  label={t(meta.FIELDS.autorecharge_limit.label)}
                  help={t(meta.FIELDS.autorecharge_limit.help)}
                  type="number"
                  action={
                    <div className="relative inline-flex items-center space-x-2 rounded-r-md border border-l-0 border-gray-300 bg-gray-50 px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-100 focus:outline-none focus:ring-0">
                      USD
                    </div>
                  }
                />

                <SelectorField
                  label={t(meta.FIELDS.wallet_payment_method.label)}
                  name={meta.FIELDS.wallet_payment_method.key}
                  options={paymentMethodOptions}
                  formatOptionLabel={formatOptionLabel}
                />
              </div>
            </TwoColumnFormContainer>
          )}
        </AccountFormUpdate>
      </div>
    </div>
  );
}
