import { PropsWithChildren, useCallback, useState } from "react";
import { Navigate } from "react-router-dom";
import NotificationSlideOver from "src/containers/NotificationSlideOver";
import { AppSidebar } from "src/containers/menu/AppSidebar";
import UserNotificationList from "src/containers/user/UserNotificationList";
import Loading from "src/components/Loading";
import MobileMenu from "../containers/MobileMenu";
import NetworkWarning from "../containers/inbox/NetworkWarning";
import useHilosStore from "../hooks/useHilosStore";
import { buildRoute } from "./router";

interface PrivateRouteProps {
  routeName?: string;
}

function PrivateRoute({
  children,
  routeName,
}: PropsWithChildren<PrivateRouteProps>) {
  const { session } = useHilosStore();
  const [showNotifications, setShowNotifications] = useState(false);

  const handleSwitchShowNotifications = useCallback(
    () =>
      setShowNotifications((prevShowNotifications) => !prevShowNotifications),
    []
  );

  if (!session) {
    return (
      <div className="h-full w-full flex justify-center items-center">
        <Loading />
      </div>
    );
  }

  // Show the component only when the user is logged in
  // Otherwise, redirect the user to /signin page
  if (
    !session.account &&
    !["welcome", "account-selection"].includes(routeName as string)
  ) {
    return <Navigate to={buildRoute("welcome")} />;
  }

  if (routeName !== "welcome") {
    return (
      <div className="flex h-screen w-screen">
        <AppSidebar onSwitchShowNotifications={handleSwitchShowNotifications} />
        <div className="flex flex-col flex-1 h-full w-full overflow-hidden">
          <MobileMenu
            onSwitchShowNotifications={handleSwitchShowNotifications}
          />
          <NetworkWarning />
          <NotificationSlideOver
            show={showNotifications}
            onClose={handleSwitchShowNotifications}
          >
            <UserNotificationList onClose={handleSwitchShowNotifications} />
          </NotificationSlideOver>
          <main className="flex-1 min-h-0 h-full w-full overflow-y-auto">
            {children}
          </main>
        </div>
      </div>
    );
  }

  return <>{children}</>;
}

export default PrivateRoute;
