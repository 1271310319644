import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";
import { TagIcon } from "@heroicons/react/outline";
import * as Sentry from "@sentry/browser";
import axios from "axios";
import ConversationTagForm, {
  ConversationTagFormSubmit,
} from "src/containers/tags/ConversationTagForm";
import HeaderNavigation from "src/components/HeaderNavigation";
import LoaderSpinner from "src/components/LoaderSpinner";
import useCleanTimeout from "src/hooks/useCleanTimeout";
import useConversationTag from "src/hooks/useConversationTag";
import { queryClient } from "src/HilosProvider";
import { API_ROUTES, buildAPIRoute, buildRoute } from "src/router/router";
import { axiosErr } from "src/types/axios";

export default function ConversationTagUpdate() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();
  const [success, setSuccess] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const timeout = useCleanTimeout();

  const conversationTagId = params.id as string;
  const conversationTag = useConversationTag(conversationTagId);

  const formSubmit: ConversationTagFormSubmit = async (
    formData,
    setSubmitting,
    setBackendValidationErrors,
    setBackendError
  ) => {
    setBackendValidationErrors({});
    setBackendError("");
    try {
      await axios.put(
        buildAPIRoute(API_ROUTES.CONVERSATION_TAG_DETAIL, {
          ":id": conversationTagId,
        }),
        formData,
        {
          headers: {
            "x-module": "conversation-tag",
          },
        }
      );
      queryClient.invalidateQueries([
        "conversation_tag_detail",
        conversationTagId,
      ]);
      setSubmitting(false);
      setSuccess(true);
      timeout.current = setTimeout(() => {
        navigate(buildRoute("config-inbox-conversation-tag-list"));
      }, 1000 * 2);
    } catch (err) {
      const errorAxios: axiosErr = err as axiosErr;
      setSuccess(false);
      if (errorAxios?.response?.status === 400) {
        Sentry.captureException(err);
        setBackendValidationErrors(errorAxios.response.data);
      } else {
        setBackendError(
          t(
            "settings:conversation-tags.conversation-tag-update.error",
            "An error occurred while updating your conversation tag. Please try again."
          )
        );
      }
      timeout.current = setTimeout(() => {
        setSubmitted(false);
      }, 1000 * 2);
    } finally {
      setSubmitting(false);
      setSubmitted(true);
    }
  };

  return (
    <div className="h-screen overflow-y-auto bg-gray-50">
      <div className="py-4 px-4 sm:border-b sm:border-gray-200 sm:px-6 lg:px-8">
        <HeaderNavigation
          navPages={[
            {
              name: t("home"),
              url: buildRoute("dashboard"),
            },
            {
              name: t("settings:account-config.title"),
              url: buildRoute("config-account"),
            },
            {
              name: t("conversation-tags", "Conversation tags"),
              url: buildRoute("config-inbox-conversation-tag-list"),
            },
            {
              name: t(
                "settings:conversation-tags.conversation-tag-update.title",
                "Update Conversation Tag"
              ),
              url: buildRoute("config-inbox-conversation-tag-update", {
                id: conversationTag?.id ?? "",
              }),
            },
          ]}
        />

        <div className="lg:flex lg:items-center lg:justify-between">
          <div className="min-w-0 flex-1">
            <h2 className="mt-2 flex items-center text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl">
              <TagIcon className="mr-2 h-7 w-7" aria-hidden="true" />
              {t(
                "settings:conversation-tags.conversation-tag-update.title",
                "Update Conversation Tag"
              )}
            </h2>
          </div>
        </div>
      </div>
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="mx-auto mt-6 max-w-3xl">
          {!conversationTag ? (
            <div className="flex justify-center">
              <LoaderSpinner />
            </div>
          ) : (
            <ConversationTagForm
              conversationTag={conversationTag}
              formSubmit={formSubmit}
              success={success}
              submitted={submitted}
              action="update"
            />
          )}
        </div>
      </div>
    </div>
  );
}
