import { useCallback, useEffect } from "react";
import { captureException } from "@sentry/react";
import QRCode from "qrcode";
import { AccountStatus } from "src/types/account";
import useHilosStore from "../../hooks/useHilosStore";
import ActiveDashboard from "./active/ActiveDashboard";
import NewDashboard from "./new/NewDashboard";
import SandboxDashboard from "./sandbox/SandboxDashboard";

export default function Dashboard() {
  const { session, reloadSession } = useHilosStore();
  const QRSandboxCode = localStorage.getItem("qr_sandbox_code");
  const urlWhatsAppCode = `https://api.whatsapp.com/send?phone=${process.env.REACT_APP_SANDBOX_NUMBER}&text=${session?.phone_connect_code}`;

  useEffect(() => {
    const interval = setInterval(() => {
      reloadSession();
    }, 1000 * 3);
    return () => clearInterval(interval);
  }, [reloadSession]);

  const generateQRCode = useCallback(() => {
    QRCode.toDataURL(
      urlWhatsAppCode,
      {
        width: 170,
        margin: 2,
        color: {
          dark: "#486581",
          light: "#ffffff",
        },
      },
      (err, url) => {
        if (err) return captureException(err);

        localStorage.setItem("qr_sandbox_code", url);
      }
    );
  }, [urlWhatsAppCode]);

  useEffect(() => {
    if (session?.account.status === AccountStatus.NEW || !QRSandboxCode) {
      generateQRCode();
    }
  }, [QRSandboxCode, generateQRCode, session?.account.status]);

  const DashboardContent = useCallback(
    (session, status) => {
      switch (status) {
        case AccountStatus.IN_SANDBOX:
          return (
            <SandboxDashboard
              session={session}
              QRCode={QRSandboxCode}
              urlWACode={urlWhatsAppCode}
            />
          );
        case AccountStatus.NEW:
          return (
            <NewDashboard
              session={session}
              QRCode={QRSandboxCode}
              urlWACode={urlWhatsAppCode}
            />
          );

        default:
          return (
            <ActiveDashboard
              session={session}
              urlWhatsAppCode={urlWhatsAppCode}
              QRSandboxCode={QRSandboxCode}
            />
          );
      }
    },
    [QRSandboxCode, urlWhatsAppCode]
  );

  return (
    <>
      {!session ? (
        <div className="card">
          <div className="card-body text-center">
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        </div>
      ) : (
        DashboardContent(session, session.account.status)
      )}
    </>
  );
}
