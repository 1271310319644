interface UnorderedListProps {
  text?: string | null;
}

export default function UnorderedList({ text }: UnorderedListProps) {
  if (!text) {
    return null;
  }
  // Check if the text contains any line jumps and if so, just return the same text
  if (text.includes("\n")) {
    return <p>{text}</p>;
  }

  const sentences = text
    .split(/(?<=\.\s)/)
    .filter((sentence) => sentence.trim() !== "");

  return (
    <ul className="list-disc">
      {sentences.map((sentence, index) => (
        <li key={index}>{sentence.trim()}</li>
      ))}
    </ul>
  );
}
