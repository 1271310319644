import { useTranslation } from "react-i18next";
import { Link, useMatch } from "react-router-dom";
import { ChevronLeft, MoreVertical, Search } from "lucide-react";
import { Button } from "src/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "src/components/ui/dropdown-menu";
import useHilosStore from "src/hooks/useHilosStore";
import useWorkflowTaskDetails from "src/hooks/useWorkflowTaskDetails";
import { contactToString, formatPhone } from "src/Helpers";
import { buildRoute } from "src/router/router";
import SelectStatus from "./SelectStatus";

interface WorkflowBoardTaskProps {
  taskId: string;
  workflowId: string;
}

export default function WorkflowBoardTaskHeader({
  taskId,
  workflowId,
}: WorkflowBoardTaskProps) {
  const [t] = useTranslation();
  const isConversationSearchVisible = useMatch(
    "/workflow/:workflowId/board/:stateId/task/:taskId/search"
  );
  const { task, handleUpdateWorkflowTask } = useWorkflowTaskDetails({
    taskId,
    workflowId,
  });

  const { updateSession } = useHilosStore();
  const showConversationEvents = useHilosStore(
    ({ session }) => session?.show_conversation_events ?? true
  );

  if (!task) {
    return null;
  }

  return (
    <div className="flex items-center p-2 bg-white border-b border-gray-200">
      <Button variant="ghost" size="icon" className="px-1 py-1">
        <Link to={buildRoute("workflow-board", { id: workflowId })}>
          <ChevronLeft className="h-4 w-4" />
        </Link>
      </Button>
      <Button variant="ghost" className="pl-1" asChild>
        <Link to="details">
          <div className="grid flex-1 text-left text-md leading-tight">
            <span className="truncate font-semibold">
              {contactToString(task.inbox_contact.contact)}
            </span>
            <span className="truncate text-xs">
              {formatPhone(task.inbox_contact.contact.phone, true, true)}
            </span>
          </div>
        </Link>
      </Button>
      <div className="ml-auto flex items-center gap-2">
        <SelectStatus
          currentStateId={task.state}
          onUpdateWorkflowTask={handleUpdateWorkflowTask}
        />
        {!isConversationSearchVisible && (
          <Button variant="ghost" size="icon" asChild>
            <Link to="search">
              <Search className="h-4 w-4" />
              <span className="sr-only">Search</span>
            </Link>
          </Button>
        )}
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="ghost" size="icon">
              <MoreVertical className="h-4 w-4" />
              <span className="sr-only">More</span>
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            <DropdownMenuItem>
              {t("workflows:task.conversation.mute", "Mute")}
            </DropdownMenuItem>
            <DropdownMenuItem>
              {t("workflows:task.conversation.archive", "Archive")}
            </DropdownMenuItem>
            <DropdownMenuItem>
              {t("workflows:task.conversation.block", "Block")}
            </DropdownMenuItem>
            <DropdownMenuItem>
              {t(
                "workflows:task.conversation.mark-as-unread",
                "Mark as unread"
              )}
            </DropdownMenuItem>
            <DropdownMenuItem
              onClick={() =>
                updateSession({
                  show_conversation_events: !showConversationEvents,
                })
              }
            >
              {showConversationEvents
                ? t("workflows:task.conversation.hide-events", "Hide Events")
                : t("workflows:task.conversation.show-events", "Show Events")}
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    </div>
  );
}
