import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  FlowExecutionStepReadDetail,
  FlowExecutionStepReadDetailStatusEnum,
} from "@hilos/types/private-schema";
import useDateFnsFormatLocale from "src/hooks/useDateFnsFormatLocale";
import useHilosStore from "src/hooks/useHilosStore";
import FlowExecutionStepStatusBadge from "../flow_execution/FlowExecutionStepStatusBadge";
import FlowExecutionContactStepListMessageList from "./FlowExecutionContactStepListMessageList";
import FlowExecutionStepActionDetail from "./flow_execution_step/FlowExecutionStepActionDetail";
import FlowExecutionStepConditionalDetail from "./flow_execution_step/FlowExecutionStepConditionalDetail";
import FlowExecutionStepConditionalMultipleDetail from "./flow_execution_step/FlowExecutionStepConditionalMultipleDetail";
import FlowExecutionStepContactUpdateDetail from "./flow_execution_step/FlowExecutionStepContactUpdateDetail";
import FlowExecutionStepConversationDetail from "./flow_execution_step/FlowExecutionStepConversationDetail";
import FlowExecutionStepDelayDetail from "./flow_execution_step/FlowExecutionStepDelayDetail";
import FlowExecutionStepMenuDetail from "./flow_execution_step/FlowExecutionStepMenuDetail";
import FlowExecutionStepMessageDetail from "./flow_execution_step/FlowExecutionStepMessageDetail";
import FlowExecutionStepQuestionDetail from "./flow_execution_step/FlowExecutionStepQuestionDetail";
import FlowExecutionStepTagDetail from "./flow_execution_step/FlowExecutionStepTagDetail";
import FlowExecutionStepTeamDetail from "./flow_execution_step/FlowExecutionStepTeamDetail";
import FlowExecutionStepTemplateDetail from "./flow_execution_step/FlowExecutionStepTemplateDetail";
import FlowExecutionStepTriggerFlowDetail from "./flow_execution_step/FlowExecutionStepTriggerFlowDetail";
import FlowExecutionStepWorkflowTaskDetail from "./flow_execution_step/FlowExecutionStepWorkflowTaskDetail";

interface FlowExecutionStepDetailProps {
  executionStep: FlowExecutionStepReadDetail;
}

const StepDetailElementByType = {
  ACTION: FlowExecutionStepActionDetail,
  CONDITIONAL: FlowExecutionStepConditionalDetail,
  CONDITIONAL_MULTIPLE: FlowExecutionStepConditionalMultipleDetail,
  CONTACT_UPDATE: FlowExecutionStepContactUpdateDetail,
  CONVERSATION: FlowExecutionStepConversationDetail,
  DELAY: FlowExecutionStepDelayDetail,
  MENU: FlowExecutionStepMenuDetail,
  MESSAGE: FlowExecutionStepMessageDetail,
  QUESTION: FlowExecutionStepQuestionDetail,
  TAG: FlowExecutionStepTagDetail,
  TEAM: FlowExecutionStepTeamDetail,
  TEMPLATE: FlowExecutionStepTemplateDetail,
  TEMPLATE_MENU: FlowExecutionStepTemplateDetail,
  TRIGGER_FLOW: FlowExecutionStepTriggerFlowDetail,
  HUBSPOT_CONTACT_UPSERT: FlowExecutionStepActionDetail,
  HUBSPOT_CONTACT_GET: FlowExecutionStepActionDetail,
  WORKFLOW_TASK: FlowExecutionStepWorkflowTaskDetail,
};

export default function FlowExecutionStepDetail({
  executionStep,
}: FlowExecutionStepDetailProps) {
  const { t } = useTranslation();
  const { session } = useHilosStore();
  const { formatWithLocale } = useDateFnsFormatLocale();

  const StepTypeElement = useMemo(() => {
    if (StepDetailElementByType[executionStep.step.step_type]) {
      return StepDetailElementByType[executionStep.step.step_type];
    }
    // Check if the step_type contains the WRAPPED word if so return the ACTION element
    if (executionStep.step.step_type.includes("WRAPPED")) {
      return StepDetailElementByType["ACTION"];
    }
    return null;
  }, [executionStep.step.step_type]);

  if (!StepTypeElement) {
    return null;
  }

  return (
    <div className="text-sm text-gray-500 space-y-4">
      <div>
        <h3 className="text-tiny font-medium uppercase tracking-wider text-gray-500">
          {t(
            "flow-executions:step-detail.execution-details",
            "Execution details"
          )}{" "}
          <FlowExecutionStepStatusBadge
            status={
              executionStep.status as FlowExecutionStepReadDetailStatusEnum
            }
          />
        </h3>
        <div>
          <p>
            {t(
              "flow-executions:step-detail.started-execution-on",
              "Started execution on"
            )}{" "}
            <span className="text-gray-900 font-medium">
              {formatWithLocale(
                new Date(executionStep.created_on as string),
                "EEEE d MMMM - HH:mm"
              )}
            </span>{" "}
            ({session?.account.default_timezone})
          </p>
          {executionStep.expire_on && (
            <p>
              {executionStep.step.step_type === "DELAY"
                ? t(
                    "flow-executions:step-detail.step-complete-on",
                    "Step will complete on"
                  )
                : t(
                    "flow-executions:step-detail.step-expire-on",
                    "Step will expire on"
                  )}{" "}
              <span className="text-gray-900 font-medium">
                {formatWithLocale(
                  new Date(executionStep.expire_on as string),
                  "EEEE d MMMM - HH:mm"
                )}
              </span>{" "}
              ({session?.account.default_timezone})
            </p>
          )}
        </div>
      </div>

      {executionStep.status_reason && (
        <div>
          <h3 className="text-tiny font-medium uppercase tracking-wider text-gray-500">
            {t("flow-executions:step-detail.error-detail", "Error detail")}
          </h3>
          <p className="text-red-400">{executionStep.status_reason}</p>
        </div>
      )}

      <StepTypeElement executionStep={executionStep} />

      {executionStep.messages.length > 0 && (
        <div>
          <h3 className="text-tiny font-medium uppercase tracking-wider text-gray-500">
            {t("flow-executions:step-detail.messages", "Messages")}
          </h3>
          <div className="rounded-lg border border-gray-200 hover:rounded-lg hover:border hover:border-gray-200">
            <FlowExecutionContactStepListMessageList
              flowExecutionStep={executionStep}
            />
          </div>
        </div>
      )}
    </div>
  );
}
