import { useCallback } from "react";
import { UseMutateAsyncFunction, useQuery } from "react-query";
import axios from "axios";
import {
  ContactDetailRead,
  ConversationReadSimple,
  InboxContactEdit,
  PatchedContactEdit,
} from "@hilos/types/private-schema";
import { queryClient } from "../HilosProvider";
import { API_ROUTES, buildAPIRoute } from "../router/router";

interface FetchConversationDetailsParams {
  signal?: AbortSignal;
}

type ConversationDataStatus = Pick<ConversationReadSimple, "status">;

export type UpdateConversationStatusFn = UseMutateAsyncFunction<
  ConversationDataStatus | null,
  unknown,
  ConversationDataStatus,
  unknown
>;

export type UpdateInboxContactFn = UseMutateAsyncFunction<
  InboxContactEdit | null,
  unknown,
  Partial<InboxContactEdit>,
  unknown
>;

export type UpdateConversationFn = (
  contact: Partial<ConversationReadSimple>
) => void;

export type UpdateContactFn = (contact: Partial<PatchedContactEdit>) => void;

function useContactDetails(contactId: string | null) {
  const fetchContactDetails = useCallback(
    async ({ signal }: FetchConversationDetailsParams) => {
      if (contactId) {
        const { data } = await axios.get<ContactDetailRead>(
          API_ROUTES.CONTACT_DETAIL.replace(":id", contactId),
          { signal }
        );
        return data;
      }
      return null;
    },
    [contactId]
  );

  const { data, isError, isLoading, isFetching } = useQuery(
    ["contact_details", contactId],
    fetchContactDetails,
    {
      retry: false,
    }
  );

  const handleUpdateContact = useCallback(
    async (nextContactData: Partial<PatchedContactEdit>) => {
      if (data && data.id) {
        const result = await axios.patch<PatchedContactEdit>(
          buildAPIRoute(API_ROUTES.CONTACT_DETAIL, { ":id": data.id }),
          {
            id: data.id,
            phone: data.phone,
            ...nextContactData,
          }
        );

        if (result && result.data) {
          queryClient.setQueryData(["contact_details", contactId], {
            ...data,
            ...result.data,
          });
        }
      }
    },
    [data, contactId]
  );

  const handleAddNote = useCallback(
    async (note: string) => {
      if (data && data.id) {
        const result = await axios.post<PatchedContactEdit>(
          buildAPIRoute(API_ROUTES.CONTACT_NOTE_CREATE, { ":id": data.id }),
          { notes: note }
        );

        if (result && result.data) {
          queryClient.invalidateQueries(["contact_details", contactId]);
        }
      }
    },
    [data, contactId]
  );

  const handleRemoveNote = useCallback(
    async (id: number) => {
      await axios.delete<PatchedContactEdit>(
        buildAPIRoute(API_ROUTES.CONTACT_NOTE_DETAIL, {
          ":contact_id": contactId,
          ":id": id,
        })
      );

      queryClient.invalidateQueries(["contact_details", contactId]);
    },
    [contactId]
  );

  return {
    contact: data,
    isError,
    isLoading,
    isFetching,
    // isSubmitting,
    // isSubmittingStatus,
    handleAddNote,
    handleRemoveNote,
    handleUpdateContact,
  };
}

export default useContactDetails;
