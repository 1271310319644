import { useCallback } from "react";
import { useQuery } from "react-query";
import axios from "axios";
import { WorkflowStateRead } from "@hilos/types/private-schema";
import { API_ROUTES, buildAPIRoute } from "../router/router";

function useWorkflowState(id: string | null) {
  const fetchWorkflowState = useCallback(async () => {
    if (!id) return undefined;

    const { data } = await axios.get<WorkflowStateRead>(
      buildAPIRoute(API_ROUTES.WORKFLOW_STATE_DETAIL, { ":id": id })
    );
    return data;
  }, [id]);

  const { data } = useQuery(["workflow_state_detail", id], fetchWorkflowState);

  return data;
}

export default useWorkflowState;
