import parsePhoneNumber from "libphonenumber-js";
import { COUNTRIES_WITH_FLAGS } from "./constants/Country";

export function formatPrice(price) {
  if (price && typeof price === "number") {
    return price.toLocaleString("en", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: price < 0.1 ? 3 : 2,
    });
  } else {
    try {
      const parsedFloat = parseFloat(price);
      return parsedFloat.toLocaleString("en", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: parsedFloat < 0.1 ? 3 : 2,
      });
    } catch (error) {
      return "";
    }
  }
}

export const copyTextToClipboard = (textToCopy) => {
  if (navigator.clipboard && navigator.clipboard.writeText) {
    navigator.clipboard.writeText(textToCopy).catch((err) => {
      console.error("Failed to copy: ", err);
    });
  } else {
    console.log("Using fallback copy method");
    const textArea = document.createElement("textarea");
    textArea.value = textToCopy;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    try {
      document.execCommand("copy");
    } catch (err) {
      console.error("Fallback: Unable to copy", err);
    }
    document.body.removeChild(textArea);
  }
};

export const formatPhone = (phone, showFullNumber = true, withFlag = false) => {
  try {
    const phoneNumber = parsePhoneNumber(phone);
    if (!phoneNumber) {
      return phone;
    }
    const formattedPhone = showFullNumber
      ? phoneNumber.formatInternational()
      : phoneNumber.formatNational();
    let flag = undefined;
    if (withFlag) {
      const country = COUNTRIES_WITH_FLAGS.find(
        (c) => c.value === phoneNumber.country
      );
      if (country) {
        flag = <span className={country.icon}></span>;
      }
    }
    const formattedPhoneWithFlag =
      flag === undefined ? (
        formattedPhone
      ) : (
        <div className="inline-flex items-center gap-2">
          {flag} {formattedPhone}
        </div>
      );
    return formattedPhoneWithFlag;
  } catch {
    return phone;
  }
};
export function ensurePositiveInteger(formik, value, fieldName) {
  let cleanedVal = value.replace(".", "");
  if (cleanedVal < 0) {
    cleanedVal = 0;
  }
  formik.setFieldValue(fieldName, cleanedVal);
}

export function formatStatus(status) {
  return status.replace("_", " ");
}

export function wrapWords(text, numWords = 3) {
  if (!text) {
    return "";
  }
  const splitText = text.split(" ");
  return splitText.splice(0, numWords).join(" ") + "...";
}

export const truncateWords = (str, max = 10) => {
  if (!str) {
    return "";
  }

  const array = str.trim().split(" ");
  const ellipsis = array.length > max ? "..." : "";

  return array.slice(0, max).join(" ") + ellipsis;
};

// Transform nulls into undefines to play well
// with Formik's validation logic
export function transformNullsToUndefined(obj) {
  Object.keys(obj).forEach((key) => {
    if (obj[key] && typeof obj[key] === "object")
      transformNullsToUndefined(obj[key]);
    else if (obj[key] === null) obj[key] = undefined;
  });
}

export function userToString(user) {
  if (!user) return "";
  if (user.first_name && user.last_name) {
    return [user.first_name, user.last_name].join(" ");
  }
  if (user.first_name) {
    return user.first_name;
  }
  return user.email ? user.email.split("@")[0] : "Unknown";
}

export function getProfileAvatarImage(user) {
  if (!user || !user.profile_image) {
    return null;
  }

  return user.profile_image.thumbnail || user.profile_image;
}

export function getUserAvatarSource(user) {
  if (user && user.profile_image) {
    if (typeof user.profile_image === "string") {
      return user.profile_image;
    }
    // @ts-ignore
    return user.profile_image.thumbnail;
  }
  return null;
}

export function userAvatar(user, classes = "h-10 w-10") {
  if (!user) {
    return null;
  }
  return (
    <>
      {user.profile_image ? (
        <img
          className={`rounded-full ${classes}`}
          src={user.profile_image.thumbnail || user.profile_image}
          alt=""
        />
      ) : (
        <span
          className={`inline-flex items-center justify-center rounded-full bg-gray-500 ${classes}`}
        >
          <span className="font-medium leading-none text-white">
            {userToString(user).slice(0, 2).toUpperCase()}
          </span>
        </span>
      )}
    </>
  );
}

export function contactToString(contact) {
  if (contact.first_name && contact.last_name) {
    return [contact.first_name, contact.last_name].join(" ");
  }
  if (contact.first_name) {
    return contact.first_name;
  }
  return formatPhone(contact.phone);
}

export function guessCurrentTimezone() {
  try {
    const intlInfo = new Intl.DateTimeFormat().resolvedOptions();
    return intlInfo.timeZone;
  } catch {
    return "UTC";
  }
}

export function guessCurrentCountry() {
  try {
    const intlInfo = new Intl.DateTimeFormat().resolvedOptions();
    const locale = new Intl.Locale(intlInfo.locale);
    return locale.region && locale.region.length === 2 ? locale.region : "MX";
  } catch {
    return "MX";
  }
}

export function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export const getPercentage = (value, total) => {
  const valueLocal = value ?? 0;
  const totalLocal = total ?? 0;
  return Math.floor((valueLocal / totalLocal) * 100) || 0;
};

export function capitalize(value) {
  if (!value) {
    return value;
  }
  return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
}
