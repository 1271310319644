import { useTranslation } from "react-i18next";
import { SearchIcon, XIcon } from "@heroicons/react/outline";
import { ConversationContent } from "@hilos/types/private-schema";
import useConversationContentSearch from "../../../../hooks/useConversationContentSearch";
import ConversationContentSearchResults from "./ConversationContentSearchResults";

interface ConversationContentSearchParams {
  inboxContactId: string;
  focusedConversationContent: ConversationContent | null;
  setFocusedConversationContent: (data: ConversationContent) => void;
  onClose: () => void;
}

function ConversationContentSearch({
  inboxContactId,
  focusedConversationContent,
  setFocusedConversationContent,
  onClose,
}: ConversationContentSearchParams) {
  const { t } = useTranslation();
  const { pages, isUpdating, hasNextPage, handleNextPage, handleChangeSearch } =
    useConversationContentSearch(inboxContactId);

  return (
    <div className="flex shrink-0 w-full h-full lg:w-72 flex-col bg-white overflow-hidden">
      <div className="flex flex-row h-16 w-full items-center space-x-2 bg-gray-100 px-4">
        <button
          type="button"
          className="rounded-md text-gray-400 hover:bg-gray-200 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          onClick={onClose}
        >
          <span className="sr-only">Close</span>
          <XIcon className="h-5 w-5" aria-hidden="true" />
        </button>
        <div className="flex min-w-0 max-w-full items-baseline space-x-2">
          <h2 className="text-md font-medium text-gray-900">
            {t("inbox:search-in-messages", "Search in messages")}
          </h2>
        </div>
      </div>
      <div className="flex flex-row h-12 w-full bg-gray-100 isolate items-center">
        <div className="pointer-events-none absolute pl-3">
          <SearchIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
        </div>
        <input
          className="block w-full border-0 border-transparent bg-gray-100 py-2 pl-10 pr-4 focus:ring-0 sm:text-sm"
          type="search"
          placeholder={t("search", "Search")}
          onChange={handleChangeSearch}
        />
      </div>
      <ConversationContentSearchResults
        pages={pages}
        isUpdating={isUpdating}
        hasNextPage={hasNextPage}
        focusedConversationContent={focusedConversationContent}
        setFocusedConversationContent={setFocusedConversationContent}
        handleNextPage={handleNextPage}
      />
    </div>
  );
}

export default ConversationContentSearch;
