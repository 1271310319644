import { useCallback } from "react";
import { useQuery } from "react-query";
import axios from "axios";
import { ChannelAvailabilityData } from "@hilos/types/channel";
import { Channel } from "@hilos/types/private-schema";
import { API_ROUTES, buildAPIRoute } from "src/router/router";

export function useChannel(id: string | number) {
  const fetchChannel = useCallback(async () => {
    const { data } = await axios.get<Channel>(
      buildAPIRoute(API_ROUTES.CHANNEL_DETAIL, {
        ":id": id,
      }),
      {
        headers: {
          "x-module": "channel",
        },
      }
    );
    return data;
  }, [id]);

  const { data: channel, refetch } = useQuery(
    ["channel_detail", id],
    fetchChannel
  );

  const updateChannel = useCallback(
    async (data) => {
      const response = await axios.patch<Channel>(
        buildAPIRoute(API_ROUTES.CHANNEL_DETAIL, {
          ":id": id,
        }),
        data,
        {
          headers: {
            "x-module": "channel",
          },
        }
      );
      refetch();
      return response;
    },
    [id, refetch]
  );

  return {
    channel,
    updateChannel,
  };
}

export function useChannels() {
  const fetchChannel = useCallback(async () => {
    const { data } = await axios.get<[Channel]>(
      buildAPIRoute(API_ROUTES.CHANNEL_LIST_CREATE),
      {
        headers: {
          "x-module": "channel",
        },
      }
    );
    return data;
  }, []);

  const { data } = useQuery(["channels"], fetchChannel);

  return data || [];
}

export function useChannelAvailability(
  contactId: string | undefined = undefined
) {
  const fetchChannel = useCallback(async () => {
    const requestData = {};
    if (contactId) {
      requestData["contact"] = contactId;
    }

    const { data } = await axios.get<ChannelAvailabilityData[]>(
      buildAPIRoute(API_ROUTES.CHANNEL_AVAILABILITY),
      {
        params: requestData,
        headers: {
          "x-module": "channel",
        },
      }
    );
    return data;
  }, [contactId]);

  const { data, isLoading, refetch } = useQuery(
    ["available_channels", contactId],
    fetchChannel
  );

  return { data: data || [], isLoading, refetch };
}
