import { useTranslation } from "react-i18next";
import TwoColumnFormContainer from "src/components/TwoColumnFormContainer";
import WhatsAppIcon from "src/components/icons/WhatsAppIcon";
import useHilosStore from "src/hooks/useHilosStore";
import { formatPrice } from "src/Helpers";
import WalletCharge from "./WalletCharge";

export default function WalletOverview() {
  const { t } = useTranslation();
  const { session } = useHilosStore();

  return (
    <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
      <div className="mx-auto mt-6 max-w-3xl">
        <TwoColumnFormContainer
          title={t("settings:wallet-overview", "Wallet Overview")}
          description={t(
            "settings:wallet-description",
            "This wallet is used to pay for WhatsApp fees..."
          )}
        >
          <div>
            <h3 className="text-tiny font-medium uppercase tracking-wider text-gray-500 flex items-center">
              <WhatsAppIcon className="mr-1" />
              {t("dashboard:wallet-title", "Your WhatsApp credit balance")}
            </h3>
            <div className="flex justify-between items-center mt-4">
              <h3 className="text-3xl font-medium text-gray-900 flex-1">
                {formatPrice(session?.account.wallet_amount)}{" "}
                <span className="text-gray-500 text-sm">USD</span>
              </h3>
            </div>
          </div>
        </TwoColumnFormContainer>

        <TwoColumnFormContainer
          title={t("settings:wallet-recharge-title", "Add WhatsApp Credit")}
          description={t(
            "settings:wallet-recharge-description",
            "Add one-time credit here..."
          )}
        >
          <WalletCharge />
        </TwoColumnFormContainer>
      </div>
    </div>
  );
}
