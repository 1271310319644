import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { ChevronLeftIcon, SearchIcon } from "@heroicons/react/outline";
import { InboxContactRead } from "@hilos/types/private-schema";
import { contactToString, formatPhone } from "../../../Helpers";
import {
  UpdateConversationStatusFn,
  UpdateInboxContactFn,
} from "../../../hooks/useInboxContactDetails";
import ContactNameWithStatus from "../ContactNameWithStatus";
import { CONVERSATION_STATUS } from "../InboxMeta";
import ConversationHeaderOptions from "./ConversationHeaderOptions";
import ConversationHeaderStatus from "./ConversationHeaderStatus";

interface ConversationHeaderProps {
  inboxContact: InboxContactRead;
  showConversationSearch: boolean;
  setShowConversation: (value: boolean) => void;
  handleUpdateInboxContact: UpdateInboxContactFn;
  onUpdateConversationStatus: UpdateConversationStatusFn;
  isSubmittingStatus: boolean;
}

function ConversationHeader({
  inboxContact,
  showConversationSearch,
  setShowConversation,
  handleUpdateInboxContact,
  onUpdateConversationStatus,
  isSubmittingStatus,
}: ConversationHeaderProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const handleNavigateToInbox = useCallback(() => {
    navigate("/inbox/conversation");
  }, [navigate]);

  const handleNavigateToConversation = useCallback(() => {
    const locationPathName = location.pathname.replace(/\/$/, "");
    const hasDetails = locationPathName.includes("/details");

    if (showConversationSearch) {
      setShowConversation(false);
    }

    if (hasDetails) {
      navigate(locationPathName.replace("/details", ""));
    } else {
      navigate(`${locationPathName}/details`);
    }
  }, [navigate, location, showConversationSearch, setShowConversation]);

  const handleShowConversationSearch = useCallback(() => {
    setShowConversation(true);
  }, [setShowConversation]);

  return (
    <div className="relative gap-2 flex items-center justify-between border-b border-gray-200 bg-white px-2 py-2">
      <div className="flex min-w-0 items-center space-x-4">
        <button
          type="button"
          className="flex-shrink-0 md:hidden"
          onClick={handleNavigateToInbox}
        >
          <ChevronLeftIcon className="h-8 w-8 text-gray-400" />
        </button>
        <button
          className="flex min-w-0 flex-col leading-tight flex-1"
          type="button"
          data-tour="contact-nav"
          onClick={handleNavigateToConversation}
        >
          <div className="mt-1 flex min-w-0 items-center">
            <div className="max-w-40 md:max-w-64 lg:max-w-full shrink truncate ">
              <ContactNameWithStatus
                channel={inboxContact.channel}
                name={contactToString(inboxContact.contact)}
                lastInboundMessageOn={inboxContact.last_inbound_message_on}
                className="font-medium"
              />
            </div>
            {inboxContact.is_archived && (
              <span className="ml-1 flex-shrink-0 text-xs text-gray-400">
                {t("inbox:labels.archived", "Archived")}
              </span>
            )}
          </div>
          {inboxContact.contact.first_name || inboxContact.contact.last_name ? (
            <span className="pl-1.5 text-sm text-gray-600 truncate">
              {formatPhone(inboxContact.contact.phone, true, true)}
            </span>
          ) : (
            <div className="text-sm italic text-gray-500 truncate">
              {t("no-contact-info", "No contact info")}
            </div>
          )}
        </button>
      </div>
      <div className="flex flex-shrink-0 items-center space-x-0.5">
        <div className="flex items-center space-x-0.5">
          {inboxContact.last_conversation &&
            CONVERSATION_STATUS[inboxContact.last_conversation.status] && (
              <ConversationHeaderStatus
                inboxContactId={inboxContact.id}
                onUpdateConversationStatus={onUpdateConversationStatus}
                status={inboxContact.last_conversation.status}
                isSubmittingStatus={isSubmittingStatus}
              />
            )}
        </div>

        {!showConversationSearch && (
          <button
            type="button"
            className="flex h-8 w-8 items-center justify-center rounded-full text-gray-500 transition duration-150 ease-in-out hover:bg-gray-300 focus:outline-none"
            onClick={handleShowConversationSearch}
          >
            <SearchIcon className="h-5 w-5" />
          </button>
        )}
        <ConversationHeaderOptions
          isUnread={inboxContact.is_unread}
          isArchived={inboxContact.is_archived}
          isSilenced={inboxContact.is_silenced}
          isBlocked={inboxContact.is_blocked}
          handleUpdateInboxContact={handleUpdateInboxContact}
        />
      </div>
    </div>
  );
}

export default ConversationHeader;
