import { useCallback, useMemo } from "react";
import {
  FlowStepEdit,
  FlowVersionDetailRead,
} from "@hilos/types/private-schema";
import { STEP_TYPES } from "../FlowStepMeta";
import FlowStepActionDetail from "./flow_step/FlowStepActionDetail";
import FlowStepConditionalDetail from "./flow_step/FlowStepConditionalDetail";
import FlowStepConditionalMultipleDetail from "./flow_step/FlowStepConditionalMultipleDetail";
import FlowStepContactUpdateDetail from "./flow_step/FlowStepContactUpdateDetail";
import FlowStepConversationDetail from "./flow_step/FlowStepConversationDetail";
import FlowStepDelayDetail from "./flow_step/FlowStepDelayDetail";
import FlowStepHubspotContactGetDetail from "./flow_step/FlowStepHubspotContactGetDetail";
import FlowStepHubspotContactUpsertDetail from "./flow_step/FlowStepHubspotContactUpsertDetail";
import FlowStepMenuDetail from "./flow_step/FlowStepMenuDetail";
import FlowStepMessageDetail from "./flow_step/FlowStepMessageDetail";
import FlowStepQuestionDetail from "./flow_step/FlowStepQuestionDetail";
import FlowStepTagDetail from "./flow_step/FlowStepTagDetail";
import FlowStepTeamDetail from "./flow_step/FlowStepTeamDetail";
import FlowStepTemplateDetail from "./flow_step/FlowStepTemplateDetail";
import FlowStepTemplateMenuDetail from "./flow_step/FlowStepTemplateMenuDetail";
import FlowStepTriggerFlowDetail from "./flow_step/FlowStepTriggerFlowDetail";
import FlowStepWorkflowTaskDetail from "./flow_step/FlowStepWorkflowTaskDetail";

interface FlowStepDetailProps {
  step: FlowStepEdit;
  flowVersion: FlowVersionDetailRead;
}

const StepDetailElementByType = {
  ACTION: FlowStepActionDetail,
  CONDITIONAL: FlowStepConditionalDetail,
  CONDITIONAL_MULTIPLE: FlowStepConditionalMultipleDetail,
  CONTACT_UPDATE: FlowStepContactUpdateDetail,
  CONVERSATION: FlowStepConversationDetail,
  DELAY: FlowStepDelayDetail,
  MENU: FlowStepMenuDetail,
  MESSAGE: FlowStepMessageDetail,
  QUESTION: FlowStepQuestionDetail,
  TAG: FlowStepTagDetail,
  TEAM: FlowStepTeamDetail,
  TEMPLATE: FlowStepTemplateDetail,
  TEMPLATE_MENU: FlowStepTemplateMenuDetail,
  TRIGGER_FLOW: FlowStepTriggerFlowDetail,
  HUBSPOT_CONTACT_UPSERT: FlowStepHubspotContactUpsertDetail,
  HUBSPOT_CONTACT_GET: FlowStepHubspotContactGetDetail,
  WORKFLOW_TASK: FlowStepWorkflowTaskDetail,
};

export default function FlowStepDetail({
  step,
  flowVersion,
}: FlowStepDetailProps) {
  const StepTypeElement = useMemo(() => {
    if (StepDetailElementByType[step.step_type]) {
      return StepDetailElementByType[step.step_type];
    }
    // Check if the step_type contains the WRAPPED word if so return the ACTION element
    if (step.step_type.includes("WRAPPED")) {
      return StepDetailElementByType["ACTION"];
    }
    return null;
  }, [step.step_type]);

  const getStepById = useCallback(
    (id: string) => {
      return flowVersion.steps?.find((step) => step.id === id);
    },
    [flowVersion.steps]
  );

  const stepInfo = useMemo(() => {
    return STEP_TYPES.find((st) => st.value === step.step_type);
  }, [step.step_type]);

  if (!StepTypeElement) {
    return null;
  }

  return (
    <div className="text-sm text-gray-500">
      <StepTypeElement
        step={step}
        flowVersion={flowVersion}
        stepInfo={stepInfo}
        getStepById={getStepById}
      />
    </div>
  );
}
