import { memo } from "react";
import { EyeIcon } from "@heroicons/react/outline";
import { InboxContactViewData } from "src/hooks/useInboxContactViews";

interface ViewsToggleProps {
  currentView: InboxContactViewData | null;
  onToggle: () => void;
}

const ViewsToggle = memo(function ViewsToggle({
  currentView,
  onToggle,
}: ViewsToggleProps) {
  return (
    <button
      onClick={onToggle}
      className={`rounded-md p-1.5 transition-colors ${
        currentView
          ? "bg-indigo-600 text-white"
          : "text-gray-400 hover:bg-gray-100"
      }`}
      aria-label="Toggle views"
    >
      <EyeIcon className="h-5 w-5" />
    </button>
  );
});

export default ViewsToggle;
