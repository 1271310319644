import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";
import axios, { AxiosError } from "axios";
import { TemplateComponent, WhatsAppTemplate } from "@hilos/types/wa/templates";
import PermissionsChecker from "src/components/PermissionsCheck";
import useHilosStore from "src/hooks/useHilosStore";
import HeaderNavigation from "../../components/HeaderNavigation";
import { API_ROUTES, buildAPIRoute, buildRoute } from "../../router/router";
import WhatsAppTemplateForm from "./WhatsAppTemplateForm";
import * as meta from "./WhatsAppTemplateMeta";

export default function WhatsAppTemplateCreate() {
  const { session } = useHilosStore();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [success, setSuccess] = useState(false);
  const [initialTemplate, setInitialTemplate] = useState(
    meta.getInitialValues(session?.account)
  );
  const [submitted, setSubmitted] = useState(false);
  const params = useParams();
  const formSubmit = async (
    formData: WhatsAppTemplate,
    setSubmitting: (b: boolean) => void,
    setBackendValidationErrors: (o: object) => void,
    setBackendError: (s: string) => void
  ) => {
    setBackendValidationErrors({});
    setBackendError("");
    try {
      Object.keys(formData).forEach((k) => !formData[k] && delete formData[k]);
      await axios.put(
        buildAPIRoute(API_ROUTES.WHATSAPP_TEMPLATE_AUTOSAVE, {
          ":id": params.id,
        }),
        formData,
        {
          headers: {
            "x-module": "template",
          },
        }
      );
      await axios.post(
        buildAPIRoute(API_ROUTES.WHATSAPP_TEMPLATE_SUBMIT, {
          ":id": params.id,
        }),
        formData,
        {
          headers: {
            "x-module": "template",
          },
        }
      );
      setSubmitting(false);
      setSuccess(true);
      setTimeout(() => {
        navigate(buildRoute("whatsapp-template-list"));
      }, 1000 * 2);
    } catch (err) {
      setSuccess(false);
      type AxiosValidationError = AxiosError<{ status: number; data: unknown }>;
      const axiosErr: AxiosValidationError = err as AxiosValidationError;
      setSuccess(false);
      if (axiosErr?.response?.status === 400) {
        console.log(axiosErr?.response?.status);
        setBackendValidationErrors(axiosErr.response.data);
      } else if (axiosErr?.response?.status === 503) {
        setBackendError(
          t("templates:error-360", "Error with 360 Dialog Services.")
        );
      } else {
        console.log(axiosErr?.response?.status);
        setBackendError(
          t(
            "templates:error-general",
            "An error occurred while creating your template. Please try again."
          )
        );
      }
      setTimeout(() => {
        setSubmitted(false);
      }, 1000 * 2);
    } finally {
      setSubmitting(false);
      setSubmitted(true);
    }
  };

  const getInitialValuesFromTemplate = (data: WhatsAppTemplate) => {
    const template = meta.getInitialValues(session?.account);
    if (data.category) {
      template.category = meta.CATEGORIES[data.category].available
        ? data.category
        : "";
    }
    template.name = data.name;
    template.channel = data.channel.id;
    template.language = data.language ? data.language : template.language;
    if (data.components) {
      data.components?.forEach((component) => {
        if (component.type) {
          const initialComponentValueIndex = template.components.findIndex(
            (initialComponent) => initialComponent.type === component.type
          );
          if (initialComponentValueIndex >= 0) {
            template.components[initialComponentValueIndex] =
              assignComponentValue(
                template.components[initialComponentValueIndex],
                component,
                data
              );
          }
        }
      });
    }
    setInitialTemplate(template);
  };

  const assignComponentValue = (
    initialComponent: any,
    component: TemplateComponent,
    template: WhatsAppTemplate
  ) => {
    if (component.type === "FOOTER") {
      if (template.category === "AUTHENTICATION") {
        if (initialComponent.code_expiration_minutes) {
          initialComponent.add_code_expiration_minutes = true;
        }
      }
    }
    if (component.type === "BUTTONS") {
      initialComponent.buttons = component.buttons;
      return initialComponent;
    } else {
      initialComponent = component;
      return initialComponent;
    }
  };

  const getTemplate = useCallback(async () => {
    try {
      const { data } = await axios.get(
        buildAPIRoute(API_ROUTES.WHATSAPP_TEMPLATE, {
          ":id": params.id,
        }),
        {
          headers: {
            "x-module": "template",
          },
        }
      );
      if (data) {
        getInitialValuesFromTemplate(data);
      }
    } catch (err) {
      console.log(err);
    }
  }, [params.id]);

  useEffect(() => {
    if (params.id) {
      getTemplate();
    }
  }, [getTemplate, params.id]);

  return (
    <div className="h-screen overflow-y-auto bg-gray-50">
      <div className="py-4 px-4 sm:border-b sm:border-gray-200 sm:px-6 lg:px-8">
        <HeaderNavigation
          navPages={[
            {
              name: t("templates", "Templates"),
              url: buildRoute("whatsapp-template-list"),
            },
            {
              name: t("templates:new-template", "New template"),
              url: buildRoute("whatsapp-template-list"),
            },
          ]}
        />
      </div>

      <div className="mx-auto  px-4 sm:px-6 lg:px-8">
        <div className="mx-auto mt-6  max-w-5xl">
          <PermissionsChecker
            permission="add_whatsapptemplate"
            action={t(
              "settings:permissions.add_whatsapptemplate",
              "create templates"
            )}
          >
            <WhatsAppTemplateForm
              WhatsAppTemplate={initialTemplate}
              formSubmit={formSubmit}
              success={success}
              submitted={submitted}
            />
          </PermissionsChecker>
        </div>
      </div>
    </div>
  );
}
