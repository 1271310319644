import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import * as Tooltip from "@radix-ui/react-tooltip";
import { differenceInHours, differenceInMinutes } from "date-fns";
import { ChannelData } from "@hilos/types/channel";
import { SimpleChannel } from "@hilos/types/private-schema";
import useHilosStore from "src/hooks/useHilosStore";
import ChannelBadgeCompact from "./ChannelBadgeCompact";
import ConversationStatusIndicator from "./ConversationStatusIndicator";

interface ContactNameWithStatusProps {
  name: string;
  channel: ChannelData | SimpleChannel | string;
  lastInboundMessageOn?: string | null;
  className?: string;
}

function ContactNameWithStatus({
  name,
  channel,
  lastInboundMessageOn,
  className,
}: ContactNameWithStatusProps) {
  const { session } = useHilosStore();
  const { t } = useTranslation();
  const remainingTime = useMemo(() => {
    if (!lastInboundMessageOn) return null;

    const lastMessage = new Date(lastInboundMessageOn);
    const closeDate = new Date(lastMessage.getTime() + 24 * 60 * 60 * 1000);
    const now = new Date();

    if (closeDate <= now) return null;

    const hours = differenceInHours(closeDate, now);
    const minutes = differenceInMinutes(closeDate, now) % 60;

    return { hours, minutes };
  }, [lastInboundMessageOn]);

  const [inboxSettings, hasUsedMultipleChannels] = useMemo(() => {
    if (!session) {
      return [{}, false];
    }
    return [
      session.account.inbox_settings,
      session.account.has_used_multiple_channels,
    ];
  }, [session]);

  return (
    <Tooltip.Provider delayDuration={1000}>
      <Tooltip.Root>
        <Tooltip.Trigger asChild>
          <div className={`flex min-w-0 max-w-full ${className}`}>
            {hasUsedMultipleChannels && inboxSettings.show_channel && (
              <div className="shrink-0 mr-1.5">
                {typeof channel === "string" ? (
                  <ChannelBadgeCompact channelId={channel} />
                ) : (
                  <ChannelBadgeCompact channel={channel} />
                )}
              </div>
            )}
            <span className="grow flex-1 truncate">{name}</span>
            <div className="shrink-0 self-center justify-self-center">
              <ConversationStatusIndicator
                lastInboundMessageOn={lastInboundMessageOn}
              />
            </div>
          </div>
        </Tooltip.Trigger>
        {remainingTime && (
          <Tooltip.Portal>
            <Tooltip.Content
              className="rounded-md bg-gray-900 px-4 py-2 text-xs text-white shadow-md animate-fadeIn"
              sideOffset={5}
            >
              {t("inbox:time-remaining", "{{hours}}h {{minutes}}m remaining", {
                hours: remainingTime.hours,
                minutes: remainingTime.minutes,
              })}
              <Tooltip.Arrow className="fill-gray-900" />
            </Tooltip.Content>
          </Tooltip.Portal>
        )}
      </Tooltip.Root>
    </Tooltip.Provider>
  );
}

export default ContactNameWithStatus;
